import React from "react";
import styled from "styled-components";
import Lyrics from "./Lyrics";

const YouTubeContainer = styled.div`
  align-self: center;
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
`;

const YouTubeVideo = styled.iframe`
  display: var(--displyYouTube);
  float: ${(props) => (props.float ? props.float : "inherit")};
  margin: ${(props) => (props.margin ? props.margin : "0.5rem")};
  width: 360px;
  height: 240px;
`;

const TitleText = styled.div`
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
`;

const YouTube = (props) => {
  return (
    <YouTubeContainer>
      <Title {...props} />
      <YouTubeVideo
        frameBorder="0"
        allow="encrypted-media"
        allowFullScreen={true}
        {...props}
      />
    </YouTubeContainer>
  );
};

const Title = (props) => {
  if (props.showLyrics) {
    return <Lyrics {...props} />;
  }

  if (props.showTitle) {
    return <TitleText>{props.title}</TitleText>;
  }

  return null;
};

export default YouTube;
