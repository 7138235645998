import React from "react";
import { Container } from "../../GlobalStyle";
import { Hr, Photo, Processor } from "../../Components";
import {
  Break,
  ConfigureMenu,
  Text,
  PhotoLeft,
  PhotoLeftSmall,
  PhotoRight,
  PhotoRightSmall,
  Sisters,
  Sister,
  SisterPhoto,
} from "./_Include";

function MyStory2018(state) {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          After my initial discovery of them and having bought their first album
          XXI Century Blood and their earlier EP Escape The Mind I was listening
          to almost nothing else. I occasionally listened to another album only
          to immediately switch back to XXI Century Blood.
        </Text>
        <Text>
          In March 2018 they launched their Patreon site. Patreon is a site
          designed so that creative people can get financial support from their
          fans in return for rewards. It also provides a forum for the creators
          to build relationships with their fans.
        </Text>
        <Text>
          The biggest dilemma I had was trying to decide which tier to join as
          the list of rewards were extremely attractive and earlier in the year
          we'd had redundancies where I worked and so I wasn't sure how safe my
          job was.
        </Text>
        <PhotoLeftSmall
          {...state}
          src="/img/mystory/2018/youtube.jpg"
          alt="The Warning subscribing to my YouTube channel"
        />
        <Text>
          In the end, I decided on the Artist tier as I was able to request
          hand-written lyrics of my favourite song by which-ever band member I
          wanted, an acoustic version of my favourite song, a backing track and
          a tutorial on top of many other rewards.
        </Text>
        <Text>
          One of the rewards is that they will follow you on Twitter but at the
          time I didn't have a Twitter account I asked if they would follow me
          on YouTube instead and when they did I was so thrilled when the
          notification popped up I got a screen-shot of it.
        </Text>
        <PhotoRightSmall
          {...state}
          src="/img/mystory/2018/lyrics - when im alone.jpg"
          alt="When I'm Alone lyrics"
        />
        <Text>
          One of my favourite rewards was my very first hand-written lyrics. I
          asked for When I'm Alone written by Dany as it's my favourite song
          from XXI Century Blood. I have it mounted in pride of place above my
          fireplace.
        </Text>
        <Text>
          I'll come back to some of the other rewards later, but it's worth
          saying that after a few months I upgraded, then a few months later
          upgraded again. I can't say enough good things about my experience on
          their Patreon site, while the site itself has regular technical
          issues, it's not just that my interactions with The Warning themselves
          have been incredible, but the other patrons are amazing people as
          well.
        </Text>
        <Text>
          One of my biggest problems being a fan of The Warning was that I
          really wanted to see them play live. In January 2018 they played the
          Dakota Bar in Monterrey and I saw the videos and just loved them. But
          I would get really anxious just thinking about flying to Mexico. I
          don't enjoy long journeys in any form of transport, and hadn't flown
          for over 20 years and I didn't speak a word of Spanish, but I had a
          feeling every time I saw their live videos that made me feel sick that
          I wasn't there.
        </Text>
        <Text>
          During 2018 they'd been working on their new album and in June they
          announced that they were going to debut it at Lunario in Mexico City,
          November 25th 2018, and their patrons got exclusive access to the
          presale.
        </Text>
        <Text>
          I thought this was it, this was the one I had to go see, but my
          anxiety was kicking in thinking about, but I saw that Thomas (who is a
          really nice guy and now a good friend) announced he'd bought his
          ticket and so as I knew I wouldn't be the only English speaker there I
          did it and bought a ticket.
        </Text>
      </Container>
      <Container>
        <Hr title="Kickstarter" />
        <Text>
          As I said before during 2018 they'd been working on their second album
          and after writing the songs and having worked hard on the
          pre-production in their basement studio in August they set up a
          Kickstarter to help pay for it. This was pure excitement for me as
          when I saw all the items you could pledge for I was like a kid in a
          sweet shop.
        </Text>
        <PhotoLeft
          {...state}
          src="/img/mystory/2018/ovation.jpg"
          alt="Ovation"
          width="320"
          height="550"
        />
        <Text>
          There were the usual items like signed copies of the album, exclusive
          digital downloads and various items of merchandise of which there were
          large numbers available. Then there were also some incredible one-off
          items including one of Ale's bass guitars, Pau's snare and 4 of Dany's
          guitars.
        </Text>
        <Text>
          One of the items I had my eye on straight away was a set of VIP
          tickets for the Lunario show. Although I already had my regular ticket
          and my flights and hotel were booked, these gave you access to see the
          soundcheck, guaranteed front row and have tacos with the band after
          the show. This was going to be the first time seeing them live and to
          get such access to them was incredible, so I ended up being backer #4
          as there were only two sets of VIP tickets available and I knew I
          wanted one.
        </Text>
        <Text>
          I then had a very sleepless night as I'd been looking at some of the
          bigger items, in particular the Ovation guitar. This was the guitar
          Dany used to write When I'm Alone and the thought of owning it was
          just too much for me. I'd gone to bed that night and just didn't sleep
          thinking about it. I'd then decided that if it was still available in
          the morning I'd get it, but at 4 am I couldn't take it anymore and
          grabbed my laptop, it was still available and I got it.
        </Text>
        <Text>
          One of my plans is to learn to play When I'm Alone and hopefully
          record an acoustic cover. Thinking about that reminded me that one of
          the rewards available on Patreon is for them to record an acoustic
          version of one of their songs for you. I asked if Dany could record
          When I'm Alone on the Ovation so I could hear how it's supposed to
          sound when played properly, they agreed and the result is one of the
          greatest versions of the greatest songs of all time.
        </Text>
        <Text>
          On September 27th I got a very pleasant surprise when I got an email
          from Kickstarter with an update, the first single from Queen Of The
          Murder Scene, Dust To Dust was made available for download. After
          having been listening to Escape The Mind and XXI Century Blood for 10
          months to hear a new song was incredible. Although I knew as a backer
          on Kickstarter I'd get to download the album in advance of it's
          release, I thought they were releasing the album in the traditional
          way, but what they ended up doing was a stroke of genius and the
          brainchild of Luis, their father and co-manager.
        </Text>
        <Text>
          Dust To Dust is the introduction to the album and an incredible song,
          three weeks later they released Chapter One, the next three songs
          which begin the story of this concept album. Then every few weeks they
          would release another chapter until they released the fourth and final
          chapter with the full album on the day of their show at Lunario.
        </Text>
        <Text>
          Releasing in chapters like this was an incredible move as it fueled
          speculation as to the story that was being told and what might happen
          next. Patreon was abuzz with comments after each release, with
          everyone expressing their delight at each set of new songs. To date
          I've not read one negative comment about any of the new songs; lots of
          comparisons to various bands throughout the years and people
          speculating on the influences of each song. As it happened in a vlog
          on Patreon they revealed some of their influences and how they were
          both quite different from everyone's guesses but also very different
          from their new music. Their inspiration through them generated
          something quite unique.
        </Text>
      </Container>
      <Container>
        <Hr title="Lunario" />
        <Text>
          On 24th November 2018, I flew 5,500 miles from the UK to Mexico City
          to attend what turned out to be the greatest concert of my life. I
          arrived the night before the show and had arranged to meet up with
          some other fans at a local restaurant. Although I'd never met any of
          them before, when I got there it was like meeting up with old friends
          and I was particularly pleased that Luis (The Warning's father and
          co-manager) had also joined us. We were there for several hours and I
          had some really nice conversations, in particular, I got to talk to
          Luis about various things from the new album to some of the early days
          of The Warning.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/01lunario2018/2018112505001.jpg"
          largeSrc="/img/01lunario2018/2018112505002.jpg"
          alt="Dany"
        />
        <Text>
          On the day of the show those of us with VIP tickets met up at the
          venue at midday to attend the soundcheck, it would be the first
          soundcheck I'd been to since leaving university where I for a time had
          worked at the students union. Only this time it was that extra bit
          special, seeing The Warning in person for the first time was a total
          thrill and watching them perform the soundcheck was an incredible
          experience.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/01lunario2018/2018112504661.jpg"
          largeSrc="/img/01lunario2018/2018112504662.jpg"
          alt="Pau"
        />
        <Text>
          The night before Luis had told me that they'd had some issues with
          Chapter Three being released earlier than they'd planned and that
          Chapter Four had already been released in some countries when the plan
          was that it would be released at about the time they went on stage. On
          the morning of the show, I'd seen that Chapter Four was available but
          I'd resisted listening to it as I thought it would be nice to hear it
          for the first time live. As it happens the first song on Chapter Four,
          P.S.Y.C.H.O.T.I.C. was one they played as part of the soundcheck so I
          was quite honoured to be one of the very first people to hear it being
          played live.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/01lunario2018/2018112504541.jpg"
          largeSrc="/img/01lunario2018/2018112504542.jpg"
          alt="Ale"
        />
        <Text>
          The soundcheck had started late due to technical reasons and as a
          result, they didn't get to spend as long as they'd like, but also they
          told us later they would have liked to stop and speak to us then but
          as soon as they were done they needed to get food so they'd have time
          to get back and get ready for the show. After the soundcheck was
          complete we also left the venue to get some food, after first raiding
          the merch stand (I wanted a hoodie and some t-shirts for myself and
          t-shirts for my nieces and nephew).
        </Text>
        <Text>
          After food we headed back to the venue, we stood at the back out of
          the way as we knew we had guaranteed front row spots for The Warning.
          We caught the end of the first opening act The Darbies, they seemed
          quite good but we only caught one song. Then came Jet Jaguar, they
          have an 80's metal feel to them and played a full set which was pretty
          good, although by this point my body was still trying to adjust to the
          6 hour time difference and I was feeling pretty tired so didn't get to
          appreciate it as much as I would have liked.
        </Text>
        <Sisters>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2018/lunario/2018112505241.jpg"
              largeSrc="/img/mystory/2018/lunario/2018112505242.jpg"
              alt="Ale"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2018/lunario/2018112505191.jpg"
              largeSrc="/img/mystory/2018/lunario/2018112505192.jpg"
              alt="Pau"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2018/lunario/2018112505321.jpg"
              largeSrc="/img/mystory/2018/lunario/2018112505322.jpg"
              alt="Dany"
            />
          </Sister>
        </Sisters>
        <Text>
          Eventually the main event, the adrenaline started pumping and I was up
          for anything. We moved down to the side of the stage as the plan was
          that after the first two songs once the press were out of the way we
          would take their place in front of the barrier where we could move
          about and have an unobstructed view. This worked out well as for the
          third song Crimson Queen I had a perfect view to take some photos of
          Dany playing my Ovation guitar.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/01lunario2018/2018112508301.jpg"
          largeSrc="/img/01lunario2018/2018112508302.jpg"
          alt="Dany presenting me my new guitar"
        />
        <Text>
          The entire show was just pure intense and totally amazing and a bit of
          a blur, I took hundreds of photo's and a few videos but the most
          amazing part was right at the very end. I'd imagined that I'd collect
          my new guitar at the meet and greet after the show, but as Dany told
          me later, she hadn't planned it this way but when the show was over
          she saw me standing right there in front of her so she grabbed the
          Ovation from its stand and handed it to me. I had my camera up taking
          a photo of Pau at the time and ended up getting a rather good shot.
        </Text>
        <Text>
          After the show, we had the meet and greet but in their supreme
          generosity, The Warning had decided that everyone who'd bought
          something from their official merch stand would get to attend the meet
          and greet and as a result there must have been about 200 people in the
          queue. As we were also going for tacos with them afterwards we decided
          to wait till the end, so while we had a long wait we didn't bother
          standing in the queue. The only problem was that due to the sheer
          number of people they were running out of time for when they had to
          exit the venue and the result was that by the time we got to them we
          just got a photo and they said they'd sign things later.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2018/lunario/2018112505441.jpg"
          largeSrc="/img/mystory/2018/lunario/2018112505442.jpg"
          alt="The Warning"
        />
        <Text>
          We left the venue and The Warning head straight off to the taco
          restaurant while some of us headed back to the hotel to drop off
          various items we'd picked up, in my case, it was my guitar, signed
          drum head and camera bag before heading off to join them. When we got
          to the restaurant I sat at one end of the table a bit down from Dany,
          Ale and Pau who were sitting together, but after a while, Dany moved
          to join us at our end which was so good as I got to talk to her about
          all sorts of things. A bit later Thomas who was sitting next to Pau
          offered to swap seats with me, so I then got to talk to Pau as well.
          Ale wasn't feeling so good due to exhaustion and was about to leave
          but Dany had just started signing some of the items we'd brought with
          us and Pau had joined her, so Ale stayed for a few more minutes to
          sign a few items which was so incredible of her. I'd remembered to
          grab my CD booklet and ticket and so got them signed which was so
          nice.
        </Text>
        <Text>
          I mentioned to Dany that I'd not had the Ovation signed, but it was OK
          and that she could sign it when they came to the UK, but I could tell
          that she'd forgotten about it and was upset on my behalf and while I
          was a little bit disappointed, in the scheme of things it was so minor
          compared to the incredible day I'd had. It wasn't much longer before
          the whole night came to an end and we headed back to our respective
          hotels. For me it was the end of a very long day, I'd not slept more
          than a couple of hours a night for the last few days and with the time
          difference between the UK and Mexico, my body thought it was 8 am, but
          I was still buzzing from the show and meeting The Warning that
          although I was absolutely exhausted I was still wide awake.
        </Text>
        <Text>
          The following morning after another couple of hours of sleep I was
          lying in bed and decided to listen to the album version of Chapter
          Four for the first time. Obviously, the first thought was WOW, but
          when I was listening to The End I just lost it. I don't know if it was
          partly due to not having had enough sleep, but definitely a
          contributing factor is the fact that The End is one hell of an amazing
          song. Pau's vocals are just incredible, she delivers such passion in a
          similar way as she does with Black Holes and that also has a similar
          effect on me that it just chokes me up and before long the tears were
          rolling. I think the breaking point for me was the realisation that
          this journey I'd made which was a huge personal undertaking for me had
          not only been the most amazing thing I'd ever done, but it too was
          coming to an end.
        </Text>
        <Sisters>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2018/lunario/2018112506231.jpg"
              largeSrc="/img/mystory/2018/lunario/2018112506232.jpg"
              alt="Ale"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2018/lunario/2018112507461.jpg"
              largeSrc="/img/mystory/2018/lunario/2018112507462.jpg"
              alt="Pau"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2018/lunario/2018112507271.jpg"
              largeSrc="/img/mystory/2018/lunario/2018112507272.jpg"
              alt="Dany"
            />
          </Sister>
        </Sisters>
        <Text>
          Also while I was lying in bed I'd been thinking about the Ovation, I
          knew The Warning was heading back home that morning but I had this
          crazy idea that on the way to the airport they'd stop by my hotel and
          I'd get a call from reception telling me I had visitors. As it happens
          I wasn't a million miles away. I'd agreed to meet Steve and Thomas for
          breakfast at 10 am, so just before 10 am I'd headed on down, but found
          Steve waiting by the entrance to the breakfast area. He told me Luis
          had been in contact and if we could get to their apartment in the next
          half hour Dany would sign my guitar. I practically ran back to my
          room, grabbed the guitar and also the drumsticks I'd got that I'd
          forgotten to take to the taco restaurant and headed back down to
          reception. On the way down I luckily bumped into Thomas in the lift, I
          explained what was happening and that Luis had said we should all go
          round.
        </Text>
        <PhotoLeft
          {...state}
          id="ovation"
          src="/img/mystory/2018/ovation.jpg"
          alt="Ovation"
          width="320"
          height="550"
        />
        <PhotoRight
          {...state}
          id="signature"
          src="/img/mystory/2018/signature.jpg"
          alt="Signature"
          width="336"
          height="285"
        />
        <Text>
          Fortunately, the apartment they were staying in was only a 5 minute
          walk from our hotel, we got there and as always the Villarreal family
          were incredibly gracious hosts. They welcomed us in and I spoke to
          Dany about what I wanted her to write on the guitar. I said that at
          this point I'd be happy just with her signature, but she wanted to do
          something more, and in the end, what she wrote was just perfect.
        </Text>
        <Text>
          I also remembered to ask Pau to sign my drumsticks which she was all
          too happy to do and I asked Ale how she was feeling and she said she
          was much better and she'd just been tired.
        </Text>
        <Text>
          Although when they got back home they took Ale to see a doctor who
          said she needed to take a week off due to exhaustion, which
          unfortunately meant they had to cancel 3 shows they had lined up for
          the following weekend. It really emphasises how much they love their
          fans that they push themselves so hard, sometimes too hard.
        </Text>
        <Text>
          Before we left we chatted a bit and took some amazing photos. I cannot
          thank Dany, Pau, Ale, Luis, Monica, Rudy and their entire team enough
          for making my first trip to Mexico and my first time seeing The
          Warning play live the most incredible experience of my life. I also
          know speaking to many fans for which it was also their first time
          seeing The Warning live that this was an experience they would never
          forget.
        </Text>
        <Break />
        <Photo
          {...state}
          id="signing"
          src="/img/mystory/2018/signing.jpg"
          alt="Guitar signing"
          showDescription="true"
        >
          Guitar signing photo &#169; 2018 "THE WARNING"
        </Photo>
      </Container>
    </Processor>
  );
}

export default MyStory2018;
