import React from "react";
import styled from "styled-components";

const Anchor = styled.div`
  display: block;
  position: relative;
  top: calc(-1rem - var(--headerHeight));
  visibility: hidden;
`;

const Container = styled.div`
  display: flex;

  div div {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 950px) {
    div div {
      text-align: left;
    }

    div iframe {
      margin-left: 2rem;
    }
  }

  @media screen and (max-width: 950px) {
    flex-wrap: wrap;
  }
`;

const VideoContainer = (props) => {
  return (
    <>
      <Anchor id={props.id} />
      <Container>{props.children}</Container>
    </>
  );
};

export default VideoContainer;
