import React from "react";
import { Container } from "../../GlobalStyle";
import { Hr, Processor } from "../../Components";
import {
  ConfigureMenu,
  Text,
  PhotoCenter,
  PhotoLeft,
  PhotoLeftSmall,
  PhotoRight,
  PhotoRightSmall,
} from "./_Include";

function MyStory2022(state) {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          2022 started in a mixed fashion, after my Christmas break I'd planned
          to maintain a level of isolation to stay safe for my upcoming trip to
          LA.
        </Text>
        <Text>
          But on the 6th of January Rudy messaged me to let me know that they
          were postponing the tour again. He was personally contacting all the
          international fans he knew who were going to let them know in advance
          of the public announcement. Once again they made an extra effort to do
          what they could for us fans, knowing that a lot of us had spent a lot
          of money on flights and accommodation. While they'd left it as long as
          they could to make the decision in the hope that it could still go
          ahead, they gave us as much notice as they could so that we could
          adjust our plans. To say I was devastated would be an understatement.
        </Text>
        <Text>
          A couple of weeks later they announced the new dates and it was very
          mixed, the original plan was to see the first four shows, San Diego,
          LA x2 and Berkeley, but with the rescheduled dates San Diego and
          Berkeley were at the start of the tour and the LA shows were at the
          end. All five of us Brits who were travelling together had VIP tickets
          to both LA and Berkeley but only regular tickets to San Diego, so we
          decided to stick with LA and add on Santa Ana.
        </Text>
        <Text>The next five months wait seemed to last a lifetime.</Text>
      </Container>
      <Container>
        <Hr title="Los Angeles" />
        <PhotoRight
          {...state}
          smallSrc="/img/mystory/2022/202205221252281.jpg"
          largeSrc="/img/mystory/2022/202205221252282.jpg"
          alt="Santa Monica Pier"
          width="240"
          height="360"
        />
        <Text>
          Eventually, it was time to fly back to Los Angeles for the Mayday
          tour.
        </Text>
        <Text>
          Unlike previous trips, this one did have an extra level of stress. To
          enter the US as a non-citizen, you have to have a negative test the
          day before you fly, so I'd isolated myself for the two weeks prior to
          ensure that I didn't catch COVID by some random event which would have
          prevented me from going.
        </Text>
        <Text>
          There were seven of us who were going together and sharing an Airbnb,
          five Brits and two Swedes, fortunately, all of us tested negative.
        </Text>
        <Text>
          We arrived on the Saturday afternoon which gave us time to acclimatise
          and spend the Sunday at Venice Beach and Santa Monica Pier before the
          first show on Monday.
        </Text>
        <Text>
          Show day and Henry had organised a meet-up at the Hard Rock Cafe from
          2 pm, but we decided to go there a bit early and eat there. For such a
          famous place I was pleasantly surprised at how reasonable the prices
          were and the service was second to none. We sat at the bar and as it
          was pretty quiet when we got there we talked to the bartender for
          while, explaining why we were there, and they had music videos playing
          so asked if they took requests, which they did, but sadly only videos
          that they'd got permission to show so they couldn't play anything by
          The Warning. Hopefully, they'll get them added to the list. Over time
          more and more people turned up and there were a lot of familiar faces
          and a lot of new ones.
        </Text>
      </Container>
      <Container>
        <Hr title="Troubadour - First Night" />
        <Text>
          After hanging out at the Hard Rock we eventually moved onto the venue,
          we got there in plenty of time although we were by no means the first.
          Eventually, they let us in for the soundcheck and when I saw the girls
          on stage it felt so good. They played a few of the new songs as part
          of the soundcheck and I took a few photos and it felt real again,
          after so long of not seeing them it was amazing to see them on stage
          once more.
        </Text>
        <Text>
          After the soundcheck, we had to leave the venue again but were at the
          front of the queue for the VIP meet &amp; greet. However, this is
          where things went a little wrong. In preparation for the original
          tour, I'd put together a list of all the venues and checked their
          camera policies, however, I'd manage to mix up the Troubadour with
          another venue as I had it in my head that, like the Whisky, they left
          it to the artist to decide if professional cameras were allowed
          without a photo pass, but the woman who was checking the VIP tickets
          said they weren't allowed in (I did double-check their website the
          next day and realised my mistake). I was given the option to store my
          camera in the box office, so I decided to do that but also asked Rudy
          about getting a photo pass for the second show. Rudy actually did sort
          it out for both nights, but at the time I thought it was just the
          second night and even if I had realised it was for this night, after
          the meet &amp; greet I had a nice spot in front of Ale and to go and
          get my camera at that point would have meant losing my spot, so
          decided to just enjoy the show and could take photos the second night.
          To jump ahead though, on the day of the second show, I double-checked
          with Rudy and he said that the photo pass was at the box office to be
          collected, but on the Troubadour website it says that the box office
          opens a few minutes before doors, but as with the VIP ticket you get
          in before doors open I thought that might cause an issue with not
          being able to get my photo pass before the VIP and would therefore
          have to choose between VIP and camera, so I decided to leave it at the
          Airbnb. It meant that I was limited to taking photos with my phone,
          which isn't ideal as my current phone camera mostly sucks in
          low-light, but I managed to get a few half-decent shots.
        </Text>
        <PhotoLeft
          {...state}
          smallSrc="/img/mystory/2022/202205231922331.jpg"
          largeSrc="/img/mystory/2022/202205231922332.jpg"
          alt="Troubadour first night Meet &amp; Greet"
        />
        <Text>
          The meet &amp; greet itself was a little different to previous times
          I'd seen The Warning. As a general rule, they want to meet as many
          fans as they can so for smaller venues they had no restrictions on who
          could attend the meet and greet. In larger venues like Lunario, they
          said if you bought something from their merch stand you could attend
          the meet &amp; greet, which was a little too successful as there were
          too many people and in the end, they had to rush in order to see
          everyone before the venue closed. So this time to control the numbers
          they sold a fixed number of VIP tickets which would grant you access
          to the meet &amp; greet and you got a drawstring backpack with their
          logo which contained a VIP laminate signed by them and a The Warning
          face mask. That first show I got them to sign my British The Warning
          cap which was a design we came up with for the UK show and Tim had had
          a few made up as tests and shared them with the rest of us, saving a
          few to give to the girls themselves.
        </Text>
        <Text>
          Onto the show itself and it was just amazing, they opened the show
          with Z then ANIMOSITY and it was supposed to be followed by Queen Of
          The Murder Scene, but Dany had issues getting the right sound on her
          pedal board, so after two failed starts they switched it to Ugh, which
          was great as I love that just as much as Queen Of The Murder Scene.
          They then played CHOKE and MONEY. I'd been singing along to all the
          songs, but during MONEY I sang the backing vocals that extra bit
          louder.
        </Text>
        <Text>
          Then as a surprise, Dany played Crimson Queen for the first and only
          time this tour, just her on her Fender Acoustasonic and it sounded so
          good, a real bonus.
        </Text>
        <Text>
          After that they played Dust To Dusk, Dull Knives, DISCIPLE,
          Narcisista, Enter Sandman, Survive, When I'm Alone, MARTIRIO and
          EVOLVE. The When I'm Alone version was particularly good as Dany
          starts playing it, just her on guitar and vocals, no bass, drums,
          backing track or even click track. It sounds so good like that, but
          then after the first verse and chorus Pau and Ale join in and its back
          to it's normal full-on rock song. An absolutely incredible version
          that I love so much.
        </Text>
        <Text>
          At the end of the show, I was honoured that Rudy handed me Pau's
          setlist. Much like one of Pau's drum sticks, these are much sort after
          items.
        </Text>
        <Text>
          The Warning has since the first time I've seen them play astounded me
          at how good they sound live, but over the last couple of years since I
          last saw them they have got better. Their playing is tighter, their
          on-stage presence is so compelling and their ability to capture the
          audience's attention is second to none. By the end of the night I was
          tired, having been on my feet for the best part of 8 hours and having
          not fully adjusted to LA time, but I felt so happy, and I was buzzing
          for another couple of hours so didn't get to sleep till the early
          hours.
        </Text>
      </Container>
      <Container>
        <Hr title="Troubadour - Second Night" />
        <PhotoRight
          {...state}
          smallSrc="/img/mystory/2022/202205241922201.jpg"
          largeSrc="/img/mystory/2022/202205241922201.jpg"
          alt="Troubadour first night Meet &amp; Greet"
        />
        <Text>
          The second day and The Warning had said there wouldn't be a
          soundcheck. It's totally understandable as when playing two nights at
          the same venue it's not required and they have sometimes had one just
          to enable us to enjoy the experience more than a requirement for them
          to get the sound right. I had also heard that there would be industry
          executives at this show so I thought they'd be using the time in
          meetings.
        </Text>
        <Text>
          The meet &amp; greet was still on and I got them to sign the set list
          from the first night. After the meet &amp; greet I tried to position
          myself to get a better position at the front of the stage, but there
          were two photographers already positioned at Dany's side of the stage
          where I ideally wanted to be so I stood on Ale's side until after the
          meet &amp; greet finished. However unlike the majority I waited until
          the girls were out of the way before trying to get to the front of the
          stage which meant I was too late. I can understand the urge to be at
          the front and it's as compelling for me as much as it is for everyone
          else but a number of people rushed there meaning the girls had to wait
          for them, when it should have been the other way around.
        </Text>
        <Text>
          I ended up in the same position as the first night, which by all means
          is not bad, but it would have been nice to be on Dany's side for a
          different perspective. I also was struggling a bit with an aching back
          and sore legs from the previous night and that days's queuing, but as
          soon as they got on stage the aches and pain just went away. The set
          list was much like the first night, only after another issue with her
          pedal board Dany managed to solve the problem and they played Queen Of
          The Murder Scene.
        </Text>
        <PhotoLeft
          {...state}
          smallSrc="/img/mystory/2022/202205232306271.jpg"
          largeSrc="/img/mystory/2022/202205232306272.jpg"
          alt="The European contingent with Devon"
        />
        <Text>
          The biggest surprise was that after Narcisista I saw Carlos grab Ale's
          mic from it's stand and walk off with it. At first I thought there
          must be a problem, maybe the battery needed changing, but Dany
          proceeded to introduce Enter Sandman, only something felt different,
          there was an extra level of excitement in the air and then Dany
          announced that they would be joined on stage by Alessia Cara for a
          unique performance.
        </Text>
        <Text>
          You could really feel the extra energy coming from the stage and they
          totally rocked that song even harder than the previous night and for
          the rest of the show you could feel they had an extra buzz to them.
        </Text>
        <Text>
          Once more the show ended and we congregated outside the venue, however
          Devon, who works for Lava and is part of The Warning's A&amp;R team as
          well as being an artist in her own right and having opened for The
          Warning at the Whisky in 2020, was there and we chatted for a few
          minutes and got a photo.
        </Text>
      </Container>
      <Container>
        <Hr title="Santa Ana - Constellation Room" />
        <Text>
          For the trip to Santa Ana, we had originally thought we'd just get an
          Uber, but when we checked the price we realised it would be very
          expensive. Partly because it's 50 miles from Los Angeles, but also
          because there were seven of us we'd need two Ubers, each way. So I
          offered to hire a car and drive us. I wanted to have a go driving in
          the US as the original plan for 2020 was to drive my share of several
          thousand miles and I'd yet to drive one mile.
        </Text>
        <Text>
          There was a Hertz conveniently walking distance from our Airbnb so we
          rented a Nissan Pathfinder which is a seven-seater and set off for
          Santa Ana just before lunchtime. It was definitely an experience, I'd
          only driven an automatic once before, a courtesy car that I'd maybe
          driven 5 miles, so to add to that the fact I was on the wrong side of
          the road, the car was large compared to what I normally drive I found
          I had to concentrate just to keep in the lane. Plus trying to stick to
          the speed limit meant that once on the freeway I was constantly being
          overtaken by cars on both sides (not something that's allowed in the
          UK) and I'd tried to learn all the differences in the traffic laws the
          night before, so keeping all that in mind meant it was a bit
          stressful.
        </Text>
        <Text>
          One of the key mistakes was that I'm used to having to depress the
          clutch to start the engine (using my left foot) whereas in an
          automatic you depress the brake, which I naturally did with my left
          foot, but then found myself left foot braking which was very jerky, so
          that was something I had to concentrate to overcome.
        </Text>
        <Text>
          We got to the venue in one piece and had planned to park there as they
          had advertised $10 parking, but the venue is on what is mostly a
          business park and they share the parking lot with another business. So
          while it was almost empty I didn't want to risk getting a ticket or
          even worse being towed if we parked in the wrong place and there was
          no one at the venue to ask. We'd got there about 6 hours before doors
          opened so had planned to look around a bit anyway, so found a burger
          place where we could park and grab a quick drink (we'd had a late
          breakfast and planned on eating a bit later so it would last the rest
          of the day).
        </Text>
        <Text>
          While we were there we saw La Wa Wa drive past and a few minutes later
          Rudy, Carlos, Lalo and Charles came walking into the burger place. I'd
          been trying to get a minute to speak to Rudy about the UK Show since
          we got there and so we said that we'd buy them lunch if Rudy would
          spare us a few minutes. To be honest we'd have happily bought them
          lunch regardless and I'm sure Rudy would have spoken to us anyway at
          this point.
        </Text>
        <Text>
          Rudy did say what I'd long suspected, that they were trying to
          coordinate the UK Show with something else, as it's a long way to come
          to the UK for one show (well two with the acoustic show for VIPs). He
          shared some details of some of the plans that they had been looking at
          but had fallen through, which I won't go into details but suffice to
          say they would have been awesome. But he did say that with any luck
          they'll be looking at coming here in March / April 2023 which based on
          their current schedule for this year is about as early as I had
          expected.
        </Text>
        <Text>
          We still had a few hours to kill so found a mall a few miles away and
          decided to go there and do some shopping. After looking around for a
          while we found a diner and decided to eat there before heading back to
          the venue for the soundcheck. However, while we were eating Rudy
          messaged me to ask if we were still nearby and could he get a lift to
          a nearby Home Depot. So we finished our food and set off back to the
          venue. I found Rudy and Luis and drove them to Home Depot where they
          picked up a few essentials and I got to speak to Luis for a bit. It
          had been a long time since I'd managed to have a conversation with him
          and to be honest of the many things I would have liked to speak to him
          about as usual my mind went blank and we just talked about how the
          tour was going and Dany's technical issues with her pedal board.
        </Text>
        <PhotoRight
          {...state}
          smallSrc="/img/mystory/2022/202205261745361.jpg"
          largeSrc="/img/mystory/2022/202205261745362.jpg"
          alt="The Brits at the Santa Ana Meet &amp; Greet"
        />
        <Text>
          Back to the venue and once again we went into the soundcheck followed
          by the meet &amp; greet. The venue is much bigger, in fact having two
          stages with The Warning being on the smaller of the two and another
          band were playing the larger one the same night. They had a convenient
          outside space which was used for the meet &amp; greet and then later
          on where the merch tables were set up. This worked a lot better than
          being in front of the stage, although it also meant that we had to
          leave the venue and re-queue outside (albeit in our own queue so we
          could get back in first) but meant we could drop off the various
          things we'd brought to get signed in the car and in this case because
          they also had metal detectors I also emptied all the loose change and
          everything else out of my pockets to speed up the process of getting
          back in.
        </Text>
        <Text>
          While we were waiting to be allowed back in we stood right in front of
          the metal detectors and got to watch when they opened the doors for
          the other band (an hour before us). They were clearly a much heavier
          band and a lot of the fans had come with significant piercings or
          studded jackets etc. which did provide some entertainment for us.
        </Text>
        <Text>
          Eventually, we were allowed in and I skipped the guy who would give
          you a stamp to allow you to buy beer at the bar (I was driving anyway,
          but nothing was going to slow me in my pursuit of getting to the front
          of the stage) and I managed to get just on Dany's side of centre, a
          pretty much perfect spot.
        </Text>
        <Text>
          The stage itself was really small and I realised that I was actually
          closer to Pau than I was when I was in their basement. During certain
          songs, I was able to watch her left foot technique as she moved
          between her hi-hat and bass pedal, something you don't normally get to
          see.
        </Text>
        <PhotoCenter
          {...state}
          smallSrc="/img/13santaana2022/202205262304121.jpg"
          largeSrc="/img/13santaana2022/202205262304122.jpg"
        />
        <Text>
          There's not much more to say about this show, it was another
          incredible experience and once more I left on a high. While often the
          sound isn't quite as good right at the front of the stage compared to
          a bit further back due to where you are relative to the speakers, to
          have them play mere inches away from you is so incredible, I just love
          it.
        </Text>
      </Container>
      <Container>
        <Hr title="Mexico City" />
        <Text>
          My second trip to Mexico City was another incredibly special trip. My
          first trip was my first time seeing The Warning play live, but it was
          also for the launch of their second album Queen Of The Murder Scene.
          My second trip was for the unofficial launch of their third album
          ERROR. I say unofficial as for Queen Of The Murder Scene it launched
          on the day of the show, but for ERROR it had already been out for
          three months, but this was the first time they would play the entire
          album live... well almost.
        </Text>
      </Container>
      <Container>
        <Hr title="Teatro Metropólitan - Friday Show" />
        <Text>
          I'd arrived the day before the first show and had met up with Kent and
          Henk at the airport where we shared an Uber to the hotel, although
          while Henk wasn't in the same hotel as us it was a few minutes walk
          away. Once at the hotel, we met up with Steve who had arrived the day
          before in the bar for a quick drink.
        </Text>
        <Text>
          On the day of the first show, Kent and I met up with Steve for
          breakfast and then went for a wander around Mexico City. The hotel we
          were in was not only a few minutes walk from the Teatro Metropólitan,
          but also not far from where there were a lot of shops and restaurants.
        </Text>
        <Text>
          We decided to check out the venue and met a few other fans out front
          before having a look around and then going to get something to eat.
          However at around 1:15 pm as we were waiting for food the we got a
          message saying that the soundcheck would be at 2 pm. As we'd been
          walking around for quite some time I wasn't entirely sure how long it
          would take to get back to the venue, and we also thought that the food
          would still take some time to arrive. After a few minutes of
          deliberation Kent and I decided to skip the food and head straight to
          the venue, we could eat later, but attending the soundcheck is one of
          the perks that we love and had after all flown all this way to see The
          Warning.
        </Text>
        <Text>
          As it happens we got to the venue at around 1:45pm and Steve and the
          others showed up, after having eaten, at bang on 2pm (the food had
          arrived a few minutes after we'd left), but no matter.
        </Text>
        <PhotoLeft
          {...state}
          smallSrc="/img/14metropolitan2022/2022082601011.jpg"
          largeSrc="/img/14metropolitan2022/2022082601012.jpg"
        />
        <Text>
          Back to the soundcheck and Rudy met us around the back of the venue,
          which fortunately as part of our wanderings we'd walked past so I knew
          how to get there, and we walked in to what is a really nice venue.
          It's a fully seated venue and Rudy directed us to sit in a row which
          was about half way back which means we were out of everyone's way but
          also had a really good view. I don't think there is a bad seat,
          although from that distance if you want to get some decent photos you
          really need a decent zoom. As it happens because we'd gone straight
          there I didn't have my camera with me so relied on my phone to take a
          few photos and videos. But having upgraded from my old OnePlus to an
          iPhone 12 Pro since my last trip I was quite pleased with the photo
          quality from that distance, although I knew they would be nothing
          compared to using my Canon.
        </Text>
        <Text>
          For the show itself I'd decided to also just take my phone and leave
          my camera at home. It would be a good test of how usable it is and
          also is a lot more convinient and gave me the opportunity to focus on
          the show itself and not worry about taking photos, although I couldn't
          help but take a few.
        </Text>
        <Text>
          As part of the tickets I'd got from their Kickstarter I got to go
          backstage and meet The Warning before the show. Fortunately compared
          to the Mayday tour meet and greet where the fans didn't have to wear
          masks but the girls did, they made sure we all had masks and the girls
          didn't bother, which means the photos worked out a lot better as I'd
          much prefer to see their faces than mine.
        </Text>
        <PhotoRight
          {...state}
          smallSrc="/img/mystory/2022/2022082601401.jpg"
          largeSrc="/img/mystory/2022/2022082601402.jpg"
        />
        <Text>
          During the last Patreon live chat before this show Dany had told me
          they'd give me my Kickstarter rewards while I was there, but while
          Dany had brought my most recent hand written lyrics (AMOUR written by
          Dany) they'd left the Kickstarter rewards at the hotel, although it
          wasn't a problem as I knew I'd be seeing them again.
        </Text>
        <Text>
          After we returned to our seats it wasn't long before the show started.
          They came out in their black outfits as we'd seen them backstage and
          really hit the ground running. Z, followed by ANIMOSITY, Queen Of The
          Murder Scene, Ugh, then a song I was really looking forward to hearing
          live AMOUR.
        </Text>
        <Text>
          For the rest of the first half of the show they were just on fire,
          song after song they played to perfection.
        </Text>
        <Text>
          Then came the first real surprise of the show, after they played
          DISCIPLE they walked off stage and BREATHE started playing from a
          recording.
        </Text>
        <Text>
          It looked like almost all 3100 people had their phones out with the
          lights on in the way people used to with lighters. It was an
          incredible sight to see and an incredibly moving moment.
        </Text>
        <Text>
          I had an opportunity to ask Pau if they she would ever play BREATHE
          live and she said no. It was added to the ERROR album to fix a
          technical issue with it's release, a song Pau had in her back pocket
          but hadn't planned on releasing and was only included on the condition
          that she never has to play it live.
        </Text>
        <Text>
          It's a shame as it's an incredible song, but you have to respect Pau's
          decision and it served it's purpose very well though, as it gave them
          time to make an outfit change, when they came back on they were all in
          white.
        </Text>
        <Text>
          The second half of the show was much like the first, absolutely
          incredible, for REVENANT they invited their friend Pliego Villarreal
          (no relation) on stage to play the acoustic guitar as they said the
          song wouldn't be right with just Dany playing live as there are so
          many layered guitars.
        </Text>
        <Text>
          They finished the show by playing Narcisista, MARTIRIO and EVOLVE and
          for both Narcisista and MARTIRIO the singing of the crowd was so
          powerful. For MARTIRIO in particular, the best way to hear that song
          is live in Mexico.
        </Text>
        <Text>
          At that point, that was the greatest show I'd ever been to. I know I
          say that a lot but I really mean it.
        </Text>
        <PhotoCenter
          {...state}
          smallSrc="/img/14metropolitan2022/2018112504801.jpg"
          largeSrc="/img/14metropolitan2022/2018112504802.jpg"
        />
      </Container>
      <Container>
        <Hr title="Saturday / Sunday" />
        <Text>
          I had no particular plans for the two days between shows but Rob was
          going to the meet and greet on the Saturday afternoon. I'd asked Rudy
          if that was included in our VIP tickets and it wasn't because we got
          to meet them backstage, but he said if we showed up at the end and
          there was time we'd be OK. I wasn't actually that bothered because I
          had got to meet them but as we didn't have anything better to do Kent
          and I walked over with Rob and kept him company while he was in the
          queue.
        </Text>
        <Text>
          We got there at the time it was due to start and the queue was already
          very long. We were in the queue for 2 - 3 hours before we got to the
          front and we got to go in and meet them. At this point I probably
          still wouldn't have bothered only they were also selling merch and I
          hadn't noticed the new poster at the show (the merch table was chatoic
          with so many people) but saw someone who had got one and it looked
          really good, so thought it would be a perfect opportunity to get one
          and get it signed and be able to get it back to the hotel safely
          without risking it getting crumpled.
        </Text>
        <Text>
          I was in and out of the meet and greet quite quickly although did get
          to share a few words with Luis while waiting for my turn, but it
          wasn't until I checked the photos later that I noticed how tired Pau
          looked and that made me feel bad that I'd taken up any of their time.
        </Text>
        <Text>
          This is an internal dilema I often find myself in. There's the part of
          me that thinks of them as friends, even family, that often thinks they
          work too hard and needs to put themselves first more than they do. But
          then there's the fan part of me that can't get enough of what they do
          and always wants more.
        </Text>
        <Text>
          On the Sunday Kent (who'd also got a poster) and I decided we'd have
          another wander around to see if we could find a cardboard tube to
          protect our posters when we have to put them in our suitcases. I'd
          also had an idea for a gift to get The Warning and also Rudy as a
          thank you for everything he does not just for me but all the fans. For
          Rudy it was a bottle of Bushmills Whiskey as I remember him posting
          about it some time ago so knew it was a brand he liked.
        </Text>
        <Text>
          Although it was a Sunday a lot of places were still open and we walked
          around for a few hours finding neither of the items we wanted.
          Eventually we decided to go back to the hotel to relax a bit after all
          the walking as it was also quite warm.
        </Text>
        <Text>
          While lieing on my bed I googled Bushmills Whiskey and found an actual
          proper liquer store that sold it, and it was only about a mile away,
          so thought I'd take another walk. As it happens after walking for a
          bit using google maps for directions I checked and it was now showing
          farther to go than it had when I started (and no I hadn't walked in
          the wrong direction) so I don't know what happened, but eventually
          after walking for about 45 minutes I found the place and got the
          bottle I wanted, a 10 year old Busmills. Although after all the
          walking I decided to get an Uber back to the hotel as I was very hot
          by that point.
        </Text>
        <Text>
          For The Warning... well I never got to give it to them, so I'll keep
          what it is to myself for now as I'll give it to them the next time I
          see them and want it to be a surprise, but I will say it took some
          time to put together.
        </Text>
      </Container>
      <Container>
        <Hr title="Teatro Metropólitan - Monday Show" />
        <Text>
          Monday comes along and after a very easy morning, we head off to the
          sound check early in the afternoon.
        </Text>
        <Text>
          We knew that this show had various guests on top of Pliego who had
          played on Friday and we got to watch Pablo (Ale's bass teacher)
          rehearse 23 with them, playing bass alongside Ale.
        </Text>
        <Text>
          After a few more songs they were lining up to rehearse Narcisista when
          Rudy said we had to leave. They were trying to keep it as a surprise
          but we already knew from the setlist that Beto Ramos (Pau's drum
          teacher) was joining them. As a drummer it meant only one thing, Pau
          would be singing at the front of the stage and not from behind her
          drumkit. On the way out I gave Rudy the bottle of Bushmills and he
          said I'd be able to get my Kickstarter rewards after the show so I
          held onto the other present thinking I'd be able to give it to the
          girls in person.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/15metropolitan2022/2022082946311.jpg"
          largeSrc="/img/15metropolitan2022/2022082946312.jpg"
        />
        <Text>
          Other than eating I can't remember what else we did for the rest of
          the day until the time came to head back to the venue for the second
          show.
        </Text>
        <Text>
          The show itself was much like the first only better, a few very minor
          issues during the first show had been ironed out and other than one
          issue where during the start of Dull Knives they had to stop while
          Dany handed her guitar to Carlos, her tech so sort out an issue,
          everything went pretty much perfectly.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/15metropolitan2022/2022082948721.jpg"
          largeSrc="/img/15metropolitan2022/2022082948722.jpg"
        />
        <Text>
          During Dany's next masterclass I got to ask her what happened and she
          said that when she'd changed guitars, her wireless transmitter was
          left turned on on her other guitar so the two signals were interfering
          with each other.
        </Text>
        <Text>
          The highlight though was without a doubt Narcisista, the lights went
          down and then we heard the familiar intro, but when Pau started
          singing the lights came up and Pau was stood on the stage and
          proceeded to give the performance of the show.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/15metropolitan2022/2022082952521.jpg"
          largeSrc="/img/15metropolitan2022/2022082952522.jpg"
        />
        <Text>
          The rest of the show was just like the first, only I think the crowd
          sang even louder during Martirio and the whole show felt more intense.
        </Text>
        <PhotoRight
          {...state}
          smallSrc="/img/15metropolitan2022/2022082955551.jpg"
          largeSrc="/img/15metropolitan2022/2022082955552.jpg"
        />
        <Text>
          When the show had finished and they'd taken their final bow Pau became
          overcome with emotion and started crying tears of happiness. I managed
          to capture the moment when Ale and Rudy were comforting her.
        </Text>
        <Text>
          After the show, I messaged Rudy, as he'd not come to take us backstage
          as he did the first show and with all their guests I thought they'd be
          busy but was hoping I'd at least be able to get my rewards, but
          unfortunately wasn't able to get hold of Rudy and eventually they were
          kicking everyone out of the venue.
        </Text>
        <Text>
          I was a little sad that I couldn't get my rewards, but more so that I
          couldn't give the girls the present I'd got them, but was also feeling
          quite emotional as I always am after a show.
        </Text>
        <Text>
          We went back to the hotel and had a few drinks in the bar, feeling in
          the mood I had a look at what whisky they had and saw they had a 21
          year old Glenfiddich. I knew it would be expensive as it's not cheap
          under normal circumstances and drinks in hotels are always overpriced
          but I thought what the hell... a 45ml shot cost more than the entire
          bottle I'd bought for Rudy. But it was the final celebration of what
          was an incredible trip.
        </Text>
      </Container>
    </Processor>
  );
}

export default MyStory2022;
