import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
    --foreColor: ${(props) => (props.darkMode ? "#fff" : "#000")};
    --backColor: ${(props) => (props.darkMode ? "#111" : "#fff")};
    --dialogColor: ${(props) => (props.darkMode ? "#111" : "#fff")};
    --transparent: ${(props) =>
      props.darkMode ? "rgba(16, 16, 16, 0.9)" : "rgba(255, 255, 255, 0.9)"};
    --highColor: ${(props) => (props.darkMode ? "#ffa500" : "#a00")};
    --errorColor: #f22;
    --displyYouTube: ${(props) => (props.showYouTube ? "block" : "none")};
    --headerHeight: 6rem;
    --menuHeight: 2rem;

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
}

@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot"),
    url("/fonts/icomoon.eot") format("embedded-opentype"),
    url("/fonts/icomoon.ttf") format("truetype"),
    url("/fonts/icomoon.woff") format("woff"),
    url("/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
    background-color: var(--backColor);
    color: var(--foreColor);
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  * {
    font-size: 12px;
  }
}
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 3rem 0 3rem;

  @media screen and (max-width: 950px) {
    padding: 0 0.5rem 0 0.5rem;
  }
`;

export const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 110%;
  height: 100vh;
  position: absolute;
  top: var(--headerHeight);
  left: ${({ direction, visible }) =>
    direction === "right" ? "inherit" : visible ? "-5%" : "-120%"};
  right: ${({ direction, visible }) =>
    direction === "left" ? "inherit" : visible ? "-5%" : "-120%"};
  transition: all 0.5s ease;
  background-color: var(--transparent);
`;

export default GlobalStyle;
