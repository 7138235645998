import { useEffect, useCallback } from "react";

const Dialog = (state) => {
  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        Cancel(state);
      }
    },
    [state]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const Cancel = (state) => {
    state.setShowDialog(null);
  };

  return null;
};

export default Dialog;
