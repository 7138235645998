import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../../../GlobalStyle";
import { Image } from "../../../Components";

const Albums = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Album = styled(Link)`
  align-self: center;
  flex-grow: 1;
  text-align: center;
  margin: 1rem;
  cursor: pointer;
  color: var(--foreColor);
  text-decoration: none;

  &:hover {
    color: var(--highColor);
  }
`;

const AlbumArt = styled(Image)`
  margin: auto;
  margin-bottom: 1rem;
  height: 200px;

  @media screen and (max-width: 950px) {
    height: 100px;
  }
`;

const AlbumTitle = styled.div``;

const Discography = (state) => {
  if (state.menu !== "TheWarning") {
    state.setMenu("TheWarning");
  }

  return (
    <>
      <Container>
        <Albums>
          <Album to="/the-warning/discography/escape-the-mind">
            <AlbumArt src="/img/tw/escape_the_mind.jpg" />
            <AlbumTitle>Escape The Mind (2015)</AlbumTitle>
          </Album>
          <Album to="/the-warning/discography/free-falling">
            <AlbumArt src="/img/tw/free_falling.jpg" />
            <AlbumTitle>Free Falling (2015)</AlbumTitle>
          </Album>
          <Album to="/the-warning/discography/xxicb">
            <AlbumArt src="/img/tw/xxicb.jpg" />
            <AlbumTitle>XXI Century Blood (2017)</AlbumTitle>
          </Album>
          <Album to="/the-warning/discography/qotms">
            <AlbumArt src="/img/tw/qotms1.jpg" />
            <AlbumTitle>Queen Of The Murder Scene (2018)</AlbumTitle>
          </Album>
          <Album to="/the-warning/discography/narcisista">
            <AlbumArt src="/img/tw/narcisista1.jpg" />
            <AlbumTitle>Narcisista (2019)</AlbumTitle>
          </Album>
          <Album to="/the-warning/discography/entersandman">
            <AlbumArt src="/img/tw/blacklist.jpg" />
            <AlbumTitle>Enter Sandman (2021)</AlbumTitle>
          </Album>
          <Album to="/the-warning/discography/error">
            <AlbumArt src="/img/tw/error.jpg" />
            <AlbumTitle>ERROR (2022)</AlbumTitle>
          </Album>
        </Albums>
      </Container>
    </>
  );
};

export default Discography;
