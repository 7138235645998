import styled from "styled-components";
import { Image, YouTube } from "../../../Components";

import { ConfigureMenu as ParentConfigureMenu } from "../_Include";
export const ConfigureMenu = ParentConfigureMenu;

export const Album = styled.div``;

export const AlbumArt = styled(Image)`
  height: 200px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  float: left;

  @media screen and (max-width: 950px) {
    height: 100px;
  }
`;

export const AlbumText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const Videos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Video = styled(YouTube)``;

export const VideoText = styled.div`
  span {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  blockquote {
    margin: 1rem;
  }

  pre {
    font-family: monospace, Arial;
    line-height: 2rem;
  }

  @media screen and (min-width: 950px) {
    margin-top: 4rem;

    span {
      margin-right: 4rem;
    }
  }
`;
