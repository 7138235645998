import React from "react";
import { Container } from "../../GlobalStyle";
import { Processor, YouTube } from "../../Components";
import { Break, ConfigureMenu, Text } from "./_Include";

function MyStory2017(state) {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          It was on December 6th 2017 while idly browsing through YouTube that I
          came across a video that would change my life.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/1boUYB9LFJY"
          title="Enter Sandman - METALLICA Cover - The Warning"
          float="left"
        />
        <Text>
          The video was a cover of Metallica's Enter Sandman by three young
          girls. I watched it and was so impressed I watched video after video
          after video of theirs until a few hours later I bought their first
          album.
        </Text>
        <Break />
        <YouTube
          src="https://www.youtube.com/embed/pj5ue3M-v6o"
          title="You've been warned! | The Warning | TEDxUniversityofNevada"
          float="right"
        />
        <Text>
          One of the videos that really stood out was their second TEDx,
          recorded at the University Of Nevada in 2017. They played Unmendable,
          When I'm Alone and Survive and talked about their experiences and
          their passion for music.
        </Text>
        <Text>
          As a drummer I was mesmerised by Pau's playing, the energy and
          intensity with which she plays is a joy to watch.
        </Text>
        <Text>
          Before they played Survive they spoke about how they are so grateful
          to their family and stress that by family they mean not only their
          parents, but their team and their supporters and Dany said something
          that I've seen quoted so many times:
          <blockquote>
            <pre>{`Music is passion
Music is hope
Music is life`}</pre>
          </blockquote>
          It remains one of my favourite videos and I never tire of watching it.
        </Text>
        <Text>
          The Warning is quite rare in that I can't think of another band where
          I love every single song they've released. After listening to their
          first full-length album XXI Century Blood for a few days I bought
          their earlier EP Escape The Mind.
        </Text>
        <Text>
          You can tell the improvement from the EP to the album, and it's not
          that it went from bad to good, or even ok to great, it went from great
          to incredible. With the release of their second album Queen Of The
          Murder Scene they took an even bigger leap forward. At the time of
          writing this it's my favourite album, although, as their third album
          ERROR has just been released that might change.
        </Text>
      </Container>
    </Processor>
  );
}

export default MyStory2017;
