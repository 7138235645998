import React from "react";

function Popup(props) {
  const Popups = {
    dany: {
      title: {
        height: 50,
        html: "Dany before the last show of the Power Trio Tour",
      },
      disclaimer: {
        height: 20,
        html: 'Copyright &#169; 2018 "THE WARNING"',
      },
      video: "/video/dany.mp4",
      alt: "Dany",
      width: 305,
      height: 540,
      duration: 4300,
    },
    pau: {
      title: {
        height: 30,
        html: "Pau preparing for Lunario",
      },
      disclaimer: {
        height: 20,
        html: 'Copyright &#169; 2018 "THE WARNING"',
      },
      video: "/video/pau.mp4",
      alt: "Pau",
      width: 700,
      height: 400,
      duration: 3100,
    },
    ale: {
      title: {
        height: 30,
        html: "Ale during the Lunario soundcheck",
      },
      video: "/video/ale.mp4",
      alt: "Ale",
      width: 540,
      height: 540,
      duration: 6200,
    },
  };

  let Capture = "";
  let Current = null;

  if (!props.name) {
    InitPopups();
  }

  function InitPopups() {
    window.addEventListener("keydown", function (e) {
      if (e.key === "Escape") {
        Hide();
      } else {
        Capture += e.key;
        var maxLength = 0;
        for (var p in Popups) {
          if (maxLength < p.length) {
            maxLength = p.length;
          }

          var c = Capture;
          if (c.length >= p.length && c.slice(c.length - p.length) === p) {
            Show(Popups[p]);
            Capture = "";
          }
        }

        if (Capture.length > maxLength) {
          Capture = Capture.slice(Capture.length - maxLength);
        }
      }
    });
  }

  function Show(props) {
    Hide();
    Current = props;

    var div = document.createElement("div");
    div.id = "PopupImg";
    div.className = "popupContainer";
    div.style.left = (window.innerWidth - props.width - 20) / 2 + "px";
    div.style.top = (window.innerHeight - props.height) / 2 + "px";
    div.style.width = props.width + 20 + "px";
    var height = props.height + 20;

    if (props.title) {
      var title = document.createElement("div");
      title.innerHTML = props.title.html;
      div.appendChild(title);
      height += props.title.height;
    }

    if (props.disclaimer) {
      var dis = document.createElement("div");
      dis.className = "disclaimer";
      dis.innerHTML = props.disclaimer.html;
      dis.style.position = "relative";
      dis.style.top = props.height + 20 + "px";
      div.appendChild(dis);
      height += props.disclaimer.height;
    }

    div.style.height = height + "px";

    var video = RenderVideo(props);
    video.width = props.width;
    video.height = props.height;
    div.appendChild(video);
    document.body.appendChild(div);
  }

  function RenderVideo(props) {
    var video = document.createElement("video");
    video.autoplay = "autoplay";
    video.src = props.video;

    if (props.duration) {
      video.addEventListener("canplay", () => {
        OnCanPlay();
      });
    }

    return video;
  }

  function OnCanPlay() {
    window.setTimeout(function () {
      Hide();
    }, Current.duration);
  }

  function Hide() {
    var o = document.getElementById("PopupImg");
    if (o !== null) {
      o.removeEventListener("canplay", OnCanPlay);
      document.body.removeChild(o);
    }
  }

  if (props.popup) {
    return (
      <span
        className="popup"
        onClick={() => {
          Show(Popups[props.popup]);
        }}
      >
        {props.children}
      </span>
    );
  }

  return null;
}

export default Popup;
