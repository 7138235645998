import React from "react";
import styled from "styled-components";
import { GiClick } from "react-icons/gi";

const IconImage = styled(GiClick)`
  position: absolute;
  margin: 0.3rem;
  color: var(--highColor);
  opacity: 0;
  animation: pulse1 5s infinite;

  @keyframes pulse1 {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    70% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
`;

const IconText = styled(GiClick)`
  position: absolute;
  margin: 0.5rem;
  color: var(--highColor);
  opacity: 0;
  animation: pulse1 4s infinite;

  @keyframes pulse1 {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    70% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
`;

const ClickIcon = (props) => {
  const onClick = (e) => {
    if (props.onClick) {
      props.onClick(e, props);
    }
  };

  if (props.mode === "Text") {
    return (
      <div>
        <IconText onClick={(e) => onClick(e)} />
        {props.children}
      </div>
    );
  }

  if (props.mode === "Photo") {
    return (
      <div>
        <IconImage onClick={(e) => onClick(e)} />
        {props.children}
      </div>
    );
  }

  return null;
};

export default ClickIcon;
