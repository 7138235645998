import styled from "styled-components";
import { Container as ParentContainer } from "../../GlobalStyle";
import { Photo as ParentPhoto } from "../../Components";

export const ConfigureMenu = (state) => {
  if (state.menu !== "InPhotos") {
    state.setMenu("InPhotos");
  }
  return null;
};

export const TripContainer = styled(ParentContainer)`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const Container = styled(ParentContainer)``;

export const Title = styled.h2`
  display: block;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const PhotoContainer1 = styled(ParentContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const PhotoContainer2 = styled.div`
  display: flex;
  margin: 0.5rem;
`;

export const Photo = styled(ParentPhoto)`
  max-width: 300px;
  max-height: 200px;
  width: auto;
  height: auto;
  align-items: center;
  cursor: pointer;
  scroll-margin-top: var(--headerHeight);
`;
