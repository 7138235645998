import React from "react";
import {
  LyricsContainer,
  Processor,
  VideoContainer,
} from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
  VideoText,
} from "./_Include";
import styled from "styled-components";

export const AlbumArtRight = styled(AlbumArt)`
  margin-left: 2rem;
  margin-right: 0;
  float: right;
`;

const Link = styled.a`
  color: var(--highColor);
  font-size: 1.2rem;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const Break = styled.br`
  clear: both;
`;

const Error = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <LyricsContainer />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/error.jpg" />
          <AlbumText>
            ERROR is The Warning's third album, released on 24th June 2022 and
            the first album released since being signed to Lava Records.
          </AlbumText>
          <AlbumText>
            Like so many things The Warnings third album was delayed by COVID
            with the original plan to record it early in 2020. However, at the
            same time, they were in negotiations with Lava records and so once
            it was possible to record it they decided to hold off until they
            were signed so they could make it their first album while being
            signed artists.
          </AlbumText>
          <AlbumText>
            Having spent many many months at home on pre-production for the
            album their original timescale to record it was only a few weeks,
            but when they got David Bendeth (who also produced one of my other
            favourite albums, Paramore's RiOT) to produce the album, he
            convinced them to take their time and try a few different things,
            including adding two more songs to take the album from ten to twelve
            tracks. However it was released with fourteen tracks, the first
            being a short intro, but the last being a bonus song. At the time of
            writing this I don't know the details of how this came about, but as
            a patron, I'm sure I'll find out soon.
          </AlbumText>
          <AlbumArtRight src="/img/tw/mayday.jpg" />
          <AlbumText>
            They released CHOKE as the first single back in May 2021, followed
            by EVOLVE in July 2021 and the Mayday EP in September which also
            contained DISCIPLE, ANIMOSITY, Z and MARTIRIO.
          </AlbumText>
          <AlbumArt src="/img/tw/money.jpg" />
          <AlbumText>
            Although it hadn't been planned that they would release anymore new
            music after the Mayday EP until after the Mayday tour, after a
            hugely successful show in Las Vegas which was for media
            representitives, they decided to release one more song and released
            MONEY in March 2022 after playing it live for the first time while
            opening for Foo Fighters at Foro Sol, Mexico City.
          </AlbumText>
        </Album>
        <Break />
        <Videos>
          <VideoContainer id="disciple">
            <Video
              src="https://www.youtube.com/embed/p9GcS8ptpCY"
              title="DISCIPLE"
              showLyrics="true"
            />
            <VideoText>
              <span>
                DISCIPLE is about how on social media it's possible to influence
                people massively and how you can breed hate through ignorance.
                It the third single released and came with an awesome video,
                where they invited members of TWA to be extras.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="choke">
            <Video
              src="https://www.youtube.com/embed/TlOy0XbFVlM"
              title="CHOKE"
              showLyrics="true"
            />
            <VideoText>
              <span>
                CHOKE was the first single released and describes the feeling of
                being so overwhelmed by your feelings that your start to drown
                in them and choke on them.
              </span>
              <span>
                CHOKE has an incredible riff with thunderous bass and pounding
                drums and an incredibly catchy chorus, once you've listened to
                it, this song will never leave your head.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="animosity">
            <Video
              src="https://www.youtube.com/embed/LkAP-ydpTys"
              title="ANIMOSITY"
              showLyrics="true"
            />
            <VideoText>
              <span>
                At the time of writing ANIMOSITY is my favourite song from the
                album. Unlike most of the songs that Pau writes, where she uses
                her incredible imagination to paint a realistic portrayal of a
                situation, ANIMOSITY was written about a real-life experience.
                It has a rawness about it, you can feel the emotion pouring out
                of it and it's like a knife through the heart to think that Pau
                could have experienced such things.
              </span>
              <span>
                There are a couple of lines in the lyrics that I really love:
                <blockquote>
                  <pre>{`Drown in your guilt
If karma doesn't hit you then I will`}</pre>
                </blockquote>
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="money">
            <Video
              src="https://www.youtube.com/embed/vm78KpEw3uw"
              title="MONEY"
              showLyrics="true"
            />
            <VideoText>
              <span>
                MONEY is an absolutely incredible song. It's also a song that is
                very personal to me as I'm actually on it, just the backing
                vocals but to be part of such an incredible song / album even in
                a very small way is something I'll be proud of for the rest of
                my life. For more details see{" "}
                <Link href="/my-story/2020#money">MONEY</Link>
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="amour">
            <Video
              src="https://www.youtube.com/embed/DYSqnwVl2Hk"
              title="AMOUR"
              showLyrics="true"
            />
            <VideoText>
              <span>
                AMOUR appears to be a love song where the two people have been
                avoiding expressing their feelings about each other to each
                other, but now the protagonist is opening up and releasing the
                pent-up emotions by asking the other person
                <blockquote>
                  <pre>{`Would you die for me?`}</pre>
                </blockquote>
                Once more they show their versatility by including another
                language into their lyrics, by adding some lines in french.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="evolve">
            <Video
              src="https://www.youtube.com/embed/3_RB0tRI624"
              title="EVOLVE"
              showLyrics="true"
            />
            <VideoText>
              <span>
                EVOLVE was the second single released. It was based on a
                documentary Pau watched and was the most problematic for Pau to
                write the lyrics for, but is an incredibly fast and intense song
                which I absolutely love.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="error">
            <Video
              src="https://www.youtube.com/embed/aQwAGg-GGjw"
              title="ERROR"
              showLyrics="true"
            />
            <VideoText>
              <span>
                ERROR to me comes across as a modern-day Frankenstein set in a
                digital age from the perspective of the creation. In a sense
                stating that nothing human-made is ever without its flaws and
                while we might think we're in complete control, sometimes our
                creation is the one that's actually in control.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="z">
            <Video
              src="https://www.youtube.com/embed/49e_2pzDpSw"
              title="Z"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Z is about generation Z and how it's grown up using technology
                and being subject to social media where everything you do is
                subject to likes / dislikes and you can't tell what's true and
                what's a lie.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="23">
            <Video
              src="https://www.youtube.com/embed/wWW46SudR7Y"
              title="23"
              showLyrics="true"
            />
            <VideoText>
              <span>
                23 I think is about unrequited love, the protagonist clearly
                wants to tell the other person
                <blockquote>
                  <pre>{`Three words that I can't say out loud`}</pre>
                </blockquote>
                But just can't and clearly feels powerless, not knowing if what
                they feel is wrong but wanting the way they feel to stop.
                <br />
                It's one of the three songs where the lead vocals are sung by
                Pau and shows great emotion, very powerful.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="koolaidkids">
            <Video
              src="https://www.youtube.com/embed/bnxPtJuvmFE"
              title="KOOL AID KIDS"
              showLyrics="true"
            />
            <VideoText>
              <span>
                KOOL AID KIDS is describing the sad way that people are
                "drinking the kool-aid" of influential people who have an agenda
                which serves themselves and not the common good. The Kool-Aid
                Kids are those unable to properly think for themselves and
                submit to the will of others without any thought of the
                consequences of their actions.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="revenant">
            <Video
              src="https://www.youtube.com/embed/bTzjJNgAlTk"
              title="REVENANT"
              showLyrics="true"
            />
            <VideoText>
              <span>
                REVENANT is another song where Pau takes the lead vocals and
                this song really stands out. A slower ballad which starts with
                an acoustic guitar and Pau singing but builds and swells in
                places to fit the emotion of the song. I'll be honest and say I
                have no idea about the meaning behind the song but the way it's
                played and sung means I don't actually care. I do like songs
                where I become invested in the story, but sometimes the
                muscianship of the performance of the song is enough and this is
                one of them.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="martirio">
            <Video
              src="https://www.youtube.com/embed/KRA3nMzF31M"
              title="MARTIRIO"
              showLyrics="true"
            />
            <VideoText>
              <span>
                MARTIRIO is their second song in Spanish and was originally
                debuted during their tour of Argentina. It has changed quite a
                bit since then but the essence of the original song remains and
                even if you don't understand Spanish the melody is incredible
                and you can't help but love it.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="breathe">
            <Video
              src="https://www.youtube.com/embed/hrQZ9UJaqd0"
              title="BREATHE"
              showLyrics="true"
            />
            <VideoText>
              <span>
                BREATHE is a bonus song for the album, not recorded with the
                other in New Jersey, but I believe actually recorded at their
                home and is just Pau and a piano and sounds amazing. The lyrics
                make me think that it might be a closure to CHOKE which is about
                drowning in your emotions and maybe this is where the person
                can't even breathe due to everything that's happening to them
                and while they beg themselves to breathe they are at the point
                of giving up.
              </span>
            </VideoText>
          </VideoContainer>
        </Videos>
      </Container>
    </Processor>
  );
};

export default Error;
