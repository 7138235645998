import React from "react";
import { TripContainer, Text } from "../_Include";
import WhiskyAGoGo15 from "./WhiskyAGoGo15";
import WhiskyAGoGo16 from "./WhiskyAGoGo16";

const LosAngeles2020 = (state) => {
  return (
    <TripContainer visible={state.trip === "LosAngeles2020"}>
      <Text>
        Before my second trip to Los Angeles I'd eventually got around to
        upgrading my camera, getting a Canon EOS R. One of the issues I'd
        experienced during all my previous trips was that when being at the
        front of the stage it was often difficult to get Dany, Pau and Ale all
        in the shot together as I didn't have a decent wide angled lens and had
        to wait until Dany and Ale moved closer together. So I decided along
        with the new camera body to get a new wide angled lens to go with it, so
        got the new RF 15-35mm f2.8, with the intention to use my existing 17 -
        55mm f2.8 with an adapter for the more close up photos. However, I was
        so impressed by the quality of the new lens that I decided to also
        invest in the RF 24-70mm f2.8.
      </Text>
      <WhiskyAGoGo15 {...state} />
      <WhiskyAGoGo16 {...state} />
    </TripContainer>
  );
};

export default LosAngeles2020;
