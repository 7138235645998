import React from "react";
import { TripContainer, Text } from "../_Include";
import ForoDiDi from "./ForoDiDi";
import PapáSobreRuedas from "./PapáSobreRuedas";
import TheWarningCave from "./TheWarningCave";

const Monterrey2019 = (state) => {
  return (
    <TripContainer visible={state.trip === "Monterrey2019"}>
      <Text>
        Another trip and another excuse to buy a new lens, this time I realised
        that what I really needed was speed, so got myself a 17-55mm f2.8.
      </Text>
      <ForoDiDi {...state} />
      <PapáSobreRuedas {...state} />
      <TheWarningCave {...state} />
    </TripContainer>
  );
};

export default Monterrey2019;
