import React from "react";
import { TripContainer, Text } from "../_Include";
import LeestaVall from "./LeestaVall";
import MercuryLounge03 from "./MercuryLounge03";
import MercuryLounge04 from "./MercuryLounge04";

const NewYork2019 = (state) => {
  return (
    <TripContainer visible={state.trip === "NewYork2019"}>
      <Text>
        For my first time in New York, I didn't have any new gear to take with
        me, but I took my 17-55mm f2.8 and 50mm f1.8 prime with me.
      </Text>
      <MercuryLounge03 {...state} />
      <LeestaVall {...state} />
      <MercuryLounge04 {...state} />
    </TripContainer>
  );
};

export default NewYork2019;
