import React from "react";

const Copyright = () => {
  const Year = () => {
    return new Date(Date.now()).getUTCFullYear();
  };

  return (
    <>
      Copyright &#169; <Year />
    </>
  );
};

export default Copyright;
