import React from "react";
import { Container } from "../../GlobalStyle";
import { Hr, Processor } from "../../Components";
import {
  ConfigureMenu,
  Text,
  PhotoLeftSmall,
  PhotoRightSmall,
} from "./_Include";

function MyStory2020(state) {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          I'm writing this in November 2021 and as such some of my memories are
          a little vague.
        </Text>
        <Text>The reason for this will be apparent later.</Text>
      </Container>
      <Container>
        <Hr title="Whisky a Go GO" />
        <Text>
          Janurary 2020 and once more I made my way to Los Angeles for two more
          nights at the Whisky A Go Go.
        </Text>
        <Text>
          I knew that The Warning's North America tour was coming up I thought
          it would be nice if Pau had plenty of drum sticks to hand out to eager
          fans, so over Christmas I'd ordered 2 bricks (a brick is 12 pairs) for
          Pau, one regular and one pink, from a Los Angeles music store, plus a
          pair for myself (I play with a different size) and had arranged to
          collect them the day before the first show. I'd also being asked by
          Mony if I could bring something from the UK that they couldn't get in
          Mexico which obviously I did and so brought a box with everything to
          the soundcheck.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/10whisky2020/2020011680151.jpg"
          largeSrc="/img/10whisky2020/2020011680152.jpg"
          alt="Ale"
        />
        <Text>
          Usually the soundchecks have been pretty small affairs as only the
          high tier patrons are invited, but for these sold out shows in Los
          Angeles there were around 20 patrons which is around twice as many as
          I'd seen at any other show.
        </Text>
        <Text>
          After the soundcheck I gave them my box of goodies and got a photo and
          then we were waiting for the show itself.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/09whisky2020/2020011561491.jpg"
          largeSrc="/img/09whisky2020/2020011561492.jpg"
          alt="Pau"
        />
        <Text>
          Last time I was at the Whisky I was stood in front of Dany, so this
          time I stood just off center on Ale's side. There is a speaker mounted
          to the center of the stage and so I was just to the left of that so I
          could use the stage to put my camera down on when I wasn't taking
          photos as while I wanted to take a bunch of photos I also wanted at
          times to just enjoy the show.
        </Text>
        <Text>
          One particularly memorable event during this show was during Survive
          Pau forgot the lyrics, only for a few seconds. She said later that she
          doesn't normally think about what she's singing as they practice so
          much it's automatic, but in that instance she started thinking about
          it and messed it up.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/09whisky2020/2020011571121.jpg"
          largeSrc="/img/09whisky2020/2020011571122.jpg"
          alt="Dany"
        />
        <Text>
          But little issues like that just make the show unique and if every
          show was "perfect" you might as well just listen to the album.
        </Text>
        <Text>
          The second day was very similar to the first, the soundcheck, as
          always, was a lot of fun. This time during the show itself I had
          planned to stand just right of center, but someone else got there
          first, so I ended up standing in the same place as the previous day.
          Which from enjoying the show perspective was ideal, but being on the
          other side would have provided a slightly different angle for photos
          which would have been nice.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/09whisky2020/2020011573851.jpg"
          largeSrc="/img/09whisky2020/2020011573852.jpg"
          alt="Ale"
        />
        <Text>
          A particularly memorable moment during this show was that Dany was
          talking to the crowd and it was a Thursday and it was getting towards
          midnight, although wasn't quite yet, but it was close enough for Dany
          that she claimed it to be Dany day and was getting the entire crowd to
          say "Dany day is the best day" and now I can't say that she was
          specifically talking to me, but she looked in my direction and said
          "You know you want to" and of course I did.
        </Text>
        <Text>
          Every The Warning show is incredible and these two were no exception,
          they never dissapoint.
        </Text>
      </Container>
      <Container>
        <Hr title="Kickstarter + North America Tour" />
        <Text>
          After their shows in Los Angeles 2020 The Warning had some very big
          plans, a third album and a 23 date North American tour. At the end of
          Janurary they launched their kickstarter to pay for them. Once again I
          was overcome with excitement at the various items on offer.
        </Text>
        <Text>
          One thing I already knew was going to be included was a private show
          in the UK. I'd been given the heads up prior to my trip to Los Angeles
          and had spoke to Luis about it when I saw him at the Whisky, but more
          about that later.
        </Text>
        <Text>
          The other reward that imediately grabbed my attention was "Participate
          on our Album". As an ameteur musician the opportunity to record
          something in a proper studio and have a credit on what I know will be
          an incredible album was amazing, I had no hesitation on clicking that
          button.
        </Text>
        <Text>
          There were of course many, many other items on offer, including Dany's
          Manson guitar that I had come so close to getting during their
          previous Kickstarter, Pau's snare and hi-hats, but while I had to show
          some restraint the one other thing I couldn't say no to were VIP
          tickets to their debut show in Mexico City, much like the Lunario
          tickets I'd got last time.
        </Text>
        <Text>
          Of course most of the rewards I got straight away, the UK Show worked
          out to be around £12,500 and so I had to be a bit more careful before
          comitting to it. I estimated that I would need a minimum of £15,000 to
          be able to just cover the basic costs and while I had the money to
          cover it I've always been very careful with money.
        </Text>
        <Text>
          I posted on Facebook to see what sort of interest there was and to try
          and judge a sensible ticket price. I was expecting a huge response but
          after a few days the response was actually pretty poor. I'd got
          together a group of fellow TWA to help organise the show and we were
          discussing the best way forward and I had the idea to run our own
          Kickstarter campaign to raise the money. This had the benefit of
          removing the risk, if we didn't hit the required target then no-one
          had to pay anything and we wouldn't proceed, but if we did hit the
          required target then we knew we'd have enough money to put on the
          show.
        </Text>
        <Text>
          Throughout this entire process we were working closely with Rudy as a
          lot of the decisions would require The Warning's consent. We wanted to
          offer different types of tickets as we knew from personal experience
          that things like access to the soundcheck are hugely popular, but it
          was Rudy's idea to add a second acoustic show, and that will really
          make this a unique event.
        </Text>
        <Text>
          After launching the Kickstarter the next priority was to find a venue
          and a date. We'd decided it had to be in London as the most easily
          accessible city in the UK for the many people from overseas we were
          expecting, but we weren't sure if people would commit to paying for
          ticket without knowing when it would be and if they could even make
          it.
        </Text>
        <Text>
          With a little bit of help from The Warning themselves in promoting the
          UK show we hit our target and had booked Bush Hall for the 12th / 13th
          September 2020.
        </Text>
        <Text>
          More on the UK show later, but The Warning's own Kickstarter was an
          incredible success as they hit 100% of their target within hours and
          was over 200% the first day managing to hit over 400% by the end of
          the campagin, making it the most successful campaign to come out of
          Mexico.
        </Text>
        <Text>
          When they announced the tour dates I knew I'd be going to see some
          shows, but ended up getting tickets to see the first 10 shows,
          starting in Austin Texas and going all the way across and up the west
          coast to Vancouver. I was being joined by Christian (from Germany) who
          I'd met during my first time in Los Angeles and Carol (from Brazil)
          who I'd met in New York, with the plan being that we'd hire a car and
          drive from city to city following The Warning.
        </Text>
        <Text>
          As it turned out we were fortunate in that Tim (from Seattle) was
          going to the Portland, Seattle and Vancouver shows and the show before
          Portland, Oakland was a very long drive, so we'd planned to ditch the
          car in Oakland and fly to Portland where Tim had offered to drive us
          as he was driving to those shows anyway.
        </Text>
        <Text>
          Something I thought about while planning this trip is I could imagine
          us crossing the border from the US to Canada and having to explain
          that a Brit, German, Brazzilian and American were driving into Canada
          to see a band from Mexico. It sounds like the start of a joke but I
          think it's an incredible testament to what The Warning has acheived in
          bringing people together.
        </Text>
        <Text>
          Of course a lot of the plans and rewards were heavily affected by the
          COVID pandemic...
        </Text>
      </Container>
      <Container>
        <Hr title="COVID" />
        <Text>
          On March 12th 2020 Luis messaged me to tell me they were postponing
          the tour, he wanted to let me know personally as he knew how much
          planning and preperation had gone into this trip I was planning. I was
          touched that he took the time to chat with me and told him that I was
          both gutted and releived, gutted because of course it would be a trip
          of a lifetime, a road trip up the west coast of the US, getting to see
          The Warning play live so many times. Releived because it was clear the
          world was in a bad way and I was feeling uneasy about going.
        </Text>
        <Text>
          Also at that time Tim had already pulled out because of COVID and it
          had only just been announced that residents of the EU had been blocked
          from entering the US, so Christian was already unable to go, and I
          knew it wouldn't be long before the UK followed.
        </Text>
        <Text>
          The official announcement was made later that day, with the plan to
          re-schedule the dates for later in the year, although eventually those
          re-scheduled dates were ultimately cancelled.
        </Text>
        <Text>
          At this point I'd been so busy since coming back from LA, with trying
          to sort out the UK Show and planning this huge road trip in the US /
          Canada and I was exhausted, mostly from the stress of it all and just
          the day to day stuff of living in a COVID world. When everything fell
          apart I just wasn't in the mood to update this site.
        </Text>
        <Text>
          We were still hopeful that COVID would be a short term problem and the
          UK show would still be able to go ahead as planned. Eventually of
          course we had to postpone that as well and at the time of writing it
          is still postponed and we don't yet have a new date.
        </Text>
      </Container>
      <Container>
        <Hr id="money" title="MONEY" />
        <Text>
          In September The Warning flew to New Jersey to record their new album,
          but before that I got a message from them about arranging a zoom call
          to discuss what they could do to make up for the fact that COVID
          prevented me from visiting them in the studio for the Participate On
          The Album reward from Kickstarter.
        </Text>
        <Text>
          Knowing that it wouldn't be possible to be there I had been thinking
          about what a suitable replacemment might be, but during the call they
          came up with a list of things they wanted to offer me as a replacement
          and I honestly teared up.
        </Text>
        <Text>
          Being there in person wasn't an option, but they knew I had the
          ability to record at home so to stil be able to participate they said
          I'd be able to record my bit at home, send it to them and they'd
          incorportate it into the song.
        </Text>
        <Text>
          A couple of months later in November 2020, Rudy messaged me with
          instructions from Pau, I recorded my bit for Money. Although I didn't
          know what it was called at the time, but the backing vocals of CASH,
          MONEY were a bit of a clue.
        </Text>
        <Text>
          So while my contribution to this song is small, I am on what I know
          will be one of the greatest albums.
        </Text>
      </Container>
    </Processor>
  );
}

export default MyStory2020;
