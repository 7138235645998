import React from "react";
import styled from "styled-components";
import { DialogContainer } from "../GlobalStyle";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Fieldset = styled.fieldset`
  min-width: 300px;
  max-width: 400px;
  margin: 10rem auto;
  background-color: var(--dialogColor);
`;

const Input = styled.input`
  display: block;
  margin: 1rem;
  padding: 0.2rem;
  width: calc(100% - 2rem);
`;

const Button = styled.input`
  display: inline-block;
  margin: 0 1rem 1rem 1rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
`;

const Error = styled.div`
  display: block;
  margin: 1rem;
  color: var(--errorColor);
  font-weight: bold;
`;

const SetPhoto = (state) => {
  const Upload = () => {
    const input = document.getElementById("fileToUpload");
    if (input.value.length !== 0) {
      const form = document.getElementById("uploadForm");
      form.submit();
    }
  };

  const Cancel = (state) => {
    state.setShowDialog(null);

    if (GetParameter("d") === "setphoto") {
      document.location.href =
        document.location.origin + document.location.pathname;
    }
  };

  const visible =
    state.showDialog === "SetPhoto" || GetParameter("d") === "setphoto";
  if (!visible) {
    return null;
  }

  return (
    <DialogContainer direction="right" visible={visible}>
      <Container>
        <Fieldset>
          <form
            action={state.settings.UploadUrl}
            method="post"
            encType="multipart/form-data"
            id="uploadForm"
          >
            <input type="hidden" name="authToken" value={state.authToken} />
            <input
              type="hidden"
              name="returnUrl"
              value={document.location.origin + document.location.pathname}
            />
            <Input
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              accept=".jpg,.gif,.png"
              onChange={() => Upload()}
            />
            <Button
              type="button"
              value="Cancel"
              onClick={() => Cancel(state)}
            />
          </form>
          <RenderError />
        </Fieldset>
      </Container>
    </DialogContainer>
  );
};

const RenderError = (state) => {
  if (GetParameter("d") !== "setphoto") {
    return null;
  }

  const c = GetParameter("c");
  switch (c) {
    case "1":
      return <Error>The filetype specified isn't supported.</Error>;

    case "2":
      return <Error>Unable to upload the photo.</Error>;

    default:
      return <Error>Unknown error: {c}</Error>;
  }
};

const GetParameter = (name) => {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(window.location.href);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export default SetPhoto;
