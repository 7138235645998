import React from "react";
import styled from "styled-components";
import { FaUserCircle, FaUser } from "react-icons/fa";
import { ApiValidate } from "../Api/Authenticate";
import Logon from "./Logon";
import ChangePassword from "./ChangePassword";
import SetPhoto from "./SetPhoto";
import UserMenu from "./UserMenu";

const AuthContainer = styled.div`
  display: flex;
  margin-left: 1rem;
`;

const UserIconUnknown = styled(FaUserCircle)`
  color: var(--foreColor);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--highColor);
    }
  }
`;

const UserIconKnown = styled(FaUser)`
  color: var(--foreColor);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--highColor);
    }
  }
`;

const UserPhoto = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.2rem solid transparent;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: 0.2rem solid var(--highColor);
    }
  }
`;

const Authenticate = (state) => {
  if (state.authToken !== null) {
    if (PerformValidation(state)) {
      ApiValidate({ authToken: state.authToken }).then((user) => {
        if (typeof user === "number") {
          state.setUser(null);
        } else {
          state.setUser({ ...user, wait: new Date(Date.now() + 60000) });
        }
      });
    }
  } else {
    return (
      <>
        <AuthContainer>
          <UserIconUnknown
            onClick={() => {
              state.setShowDialog("Logon");
            }}
          />
          <Logon {...state} />
        </AuthContainer>
      </>
    );
  }

  const props = {
    state,
    handleClick: () =>
      state.setShowDialog(state.showDialog === null ? "UserMenu" : null),
  };

  return (
    <AuthContainer>
      <RenderPhoto {...props} />
      <ChangePassword {...state} />
      <SetPhoto {...state} />
      <UserMenu {...state} />
    </AuthContainer>
  );
};

const PerformValidation = (state) => {
  if (state.authToken === null) {
    return false;
  }

  if (state.user?.wait) {
    return state.user.wait < Date.now();
  }

  return true;
};

const RenderPhoto = (props) => {
  if (props.state.userPhoto) {
    return (
      <UserPhoto
        src={"/photos/" + props.state.userPhoto}
        onClick={props.handleClick}
      />
    );
  }

  return <UserIconKnown onClick={props.handleClick} />;
};

export default Authenticate;
