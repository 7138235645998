import React from "react";
import {
  LyricsContainer,
  Processor,
  VideoContainer,
} from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
  VideoText,
} from "./_Include";

const QueenOfTheMurderScene = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <LyricsContainer />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/qotms2.jpg" />
          <AlbumText>
            Queen Of The Murder Scene is their second album, and a concept based
            around the story of a woman obsessed. While it was formally released
            as an album in November 2018 they actually released Dust To Dust in
            early October and then every few weeks released a 3 song chapter
            until the final chapter and the full album were released the day of
            their show at Lunario.
          </AlbumText>
          <AlbumText>
            From Ale's opening bass line in Dust To Dust to the final note of
            The End the whole album is an absolute masterpiece. The quality of
            the songwriting, arranging, recording and producing took a huge leap
            in comparison to XXI Century Blood which in of itself is still a
            truly magnificent album.
          </AlbumText>
          <AlbumText>
            Ale's bass playing has come on leaps and bounds, often overlooked in
            a song the bass lines prove pivotal to carrying the momentum,
            particularly in songs like Dust To Dust, Sinister Smiles, Dull
            Knives and P.S.Y.C.H.O.T.I.C. It also cannot be understated how her
            vocals on songs like P.S.Y.C.H.O.T.I.C. and the falsetto on Sinister
            Smiles really help make the songs.
          </AlbumText>
          <AlbumText>
            Pau continues to show off her many talents, the drumming throughout
            the entire album is incredible. The ride accents in Ugh and the
            little fills in The One are subtle but really help the song come
            alive. The intensity of Red Hands Never Fade and the fill in
            Sinister Smiles which on first hearing it made me think "now you're
            just showing off", although that was mostly as I was trying to work
            out if I was even capable of being able to play it.
          </AlbumText>
          <AlbumText>
            Pau also plays the many piano parts on the album, from the ending of
            Ugh to The One and masterpiece that is The End, that's on top of the
            many songs she sings, taking lead vocals on Dust To Dust and The End
            and sharing them with Dany on Sinister Smiles while providing backup
            vocals on the remaining songs.
          </AlbumText>
          <AlbumText>
            Dany's guitar playing is as always exceptional; the various
            instruments layered in Crimson Queen complement her incredible vocal
            performance which makes it one of the standout songs of the album.
          </AlbumText>
          <AlbumText>
            Dany also manages to get in character for the many songs where she
            has to portray a more sinister side and delivers incredible
            performances making you really believe that she could be a dark and
            evil monster, whereas in real life she's one of the nicest people
            I've ever met.
          </AlbumText>
          <AlbumText>
            The whole album is a real mix of fast, slow and medium tempo songs
            which help carry the story and the intensity of songs like Red Hands
            Never Fade and P.S.Y.C.H.O.T.I.C. are guaranteed to make the crowd
            go wild when played live.
          </AlbumText>
        </Album>
        <Videos>
          <VideoContainer id="dusttodust">
            <Video
              src="https://www.youtube.com/embed/TeIbG8nkOiU"
              title="Dust To Dust"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Dust To Dust was the first single that was released and is the
                introduction to the album. It's quite a haunting dark and
                intense song and Pau takes the lead vocal and delivers an
                incredible performance. It really sets the scene for the rest of
                the album.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="crimsonqueen">
            <Video
              src="https://www.youtube.com/embed/N0MJXxfcKEQ"
              title="Crimson Queen"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Crimson Queen is a departure from their normal style, with Dany
                on an acoustic guitar and lead vocal and delivers a mesmerising
                performance. It starts the story of the album and is about a
                woman who becomes enchanted with another person and the feelings
                she has become corrupted and lead her onto a dark path.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="ugh">
            <Video
              src="https://www.youtube.com/embed/aHUz9dR3gwg"
              title="Ugh"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Ugh is a more traditional TW song with pounding drums, a
                thundering bassline and powerful guitars. It continues the story
                where the obsession grows and I think it's more of an internal
                monologue where she is battling her feelings and emotions.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="theone">
            <Video
              src="https://www.youtube.com/embed/iHZjJb41hDI"
              title="The One"
              showLyrics="true"
            />
            <VideoText>
              <span>
                The One starts softly with just guitar and vocals before the
                bass and drums come in and the song builds throughout the verse
                into an amazing chorus. I love the whole feel of this song, it's
                one of my favourites to play on the drums and the piano parts
                give it an extra dimension.
              </span>
              <span>
                It takes the story to a new level of intensity with the lines
                <blockquote>
                  <pre>{`And I want to kill all the love that's for you
So only mine remains, there's nothing to choose
You'll look at me, and only me`}</pre>
                </blockquote>
                showing that this has moved far from mere obsession into
                psychosis.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="stalker">
            <Video
              src="https://www.youtube.com/embed/Pq5LehocYkY"
              title="Stalker"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Stalker as the name suggests is where she takes their obsession
                to the next level, the way Dany delivers the lines
                <blockquote>
                  <pre>{`I'll have you someday
I'll have you forever`}</pre>
                </blockquote>
                is really quite sinister and I think it's the point in her mind
                that she's no longer going to "stare from afar" as in The One
                but she's going to start to turn thoughts into actions.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="redhandsneverfade">
            <Video
              src="https://www.youtube.com/embed/s1X3BxxUOBk"
              title="Red Hands Never Fade"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Red Hands Never Fade is an incredible song, it's fast-paced and
                intense and live it's a pure adrenalin rush. I think this is the
                point in the story where she snaps and the red hands refer to
                the blood on her hands as she's now killed the person of her
                affection. But it wasn't intentional and she wants someone to
                tell her that things will be alright, even though she knows they
                won't because "red hands never fade".
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="thesacrifice">
            <Video
              src="https://www.youtube.com/embed/QTCxXL11L1A"
              title="The Sacrifice"
              showLyrics="true"
            />
            <VideoText>
              <span>
                The Sacrifice I think is an internal battle between two parts of
                her psyche. To cope with what she's done and to try and come to
                terms with it she allows an alternate personality to develop
                which is trying to persuade her that she needs her help, to
                relinquish control so that this new personality can take away
                the pain of having killed. But while this evil side of her is
                trying to make out that it's there to help it really wants to
                take over and destroy what good is left in her.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="sinistersmiles">
            <Video
              src="https://www.youtube.com/embed/Lh2eY0pe9X0"
              title="Sinister Smiles"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Sinister Smiles continues the struggle between the woman and the
                voice in her head, which is being much more defiant and trying
                to wear her down with the lyrics
                <blockquote>
                  <pre>{`Break break, just break apart`}</pre>
                </blockquote>
                being repeated over and over.
              </span>
              <span>
                Pau sings the "good" side and Dany the evil side. Although
                "good" is a relative term as of course the woman did kill
                someone and it's as a result of that death that the evil side is
                allowed to come out.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="dullknives">
            <Video
              src="https://www.youtube.com/embed/Pt-jNW1c1T0"
              title="Dull Knives"
              showLyrics="true"
            />
            <VideoText>
              <span>
                This song from the first time I heard it has been one of my
                favourites. Ale's driving bass line and Pau's thundering drums
                are a solid backing to Dany's majestic guitar. The first song
                from the album they played live gave an indication that not only
                was this second album going to be amazing but also darker and
                heavier than XXI Century Blood.
              </span>
              <span>
                It continues where Sinister Smiles leaves off only now the good
                side is right on the breaking point and is resigned to letting
                the evil take control.
                <blockquote>
                  <pre>{`I hate to live like this, can't save me now!`}</pre>
                </blockquote>
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="qotms">
            <Video
              src="https://www.youtube.com/embed/OHN0-arxsx4"
              title="Queen Of The Murder Scene"
              lyrics="qotms"
              showLyrics="true"
            />
            <VideoText>
              <span>
                The title track of the album is another incredibly intense song
                that sees the evil voice take control, take her crown and become
                the Queen Of The Murder Scene. Pau's love of Japan comes through
                with the chant "Korosu, korosu, koro, korosu" which translates
                to "Kill, Kill, Kill" and the song is very bass and drums heavy
                giving it a heavy dark feeling, until Dany's guitar solo which
                gives a sense of hope to the song before being plunged back into
                despair as the queen solidifies her hold.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="psychotic">
            <Video
              src="https://www.youtube.com/embed/Zz4SQWo-V3M"
              title="P.S.Y.C.H.O.T.I.C."
              lyrics="psychotic"
              showLyrics="true"
            />
            <VideoText>
              <span>
                P.S.Y.C.H.O.T.I.C. is another incredibly intense and powerful
                song with a distinct punk feel. And as the title suggests the
                queen is psychotic and she knows it and doesn't care, she knows
                she's going to hell and she intends to take her throne and
                reign.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="hunter">
            <Video
              src="https://www.youtube.com/embed/Z6R-Cg8q3PA"
              title="Hunter"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Hunter is another twist to the tale as it is sung from the
                viewpoint of death itself.
                <blockquote>
                  <pre>
                    {`You think you can take my title away
Well I'm coming back, better run away
It's my turn to pass on judgment
You can't escape your fate`}
                  </pre>
                </blockquote>
                The grim reaper isn't happy with the queen trying to claim the
                throne and decides to set the record straight by killing the
                queen.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="theend">
            <Video
              src="https://www.youtube.com/embed/FK7pLOWEYJU"
              title="The End (Stars Always Seem To Fade)"
              lyrics="The End"
              showLyrics="true"
            />
            <VideoText>
              <span>
                The End is where the queen is dying and while she laments what
                has happened in terms of her own personal pain she still
                believes that everything she did she did for love.
              </span>
              <span>
                It's an incredibly powerful and emotional song that perfectly
                ends what is a monumental album. Pau's haunting vocals bring a
                tear to my eye every time I hear it and Dany's guitar solo just
                puts the icing on the cake of what is pretty much a perfect song
                and the end to an incredible album.
              </span>
            </VideoText>
          </VideoContainer>
        </Videos>
      </Container>
    </Processor>
  );
};

export default QueenOfTheMurderScene;
