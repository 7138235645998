import styled from "styled-components";
import { Photo as ParentPhoto } from "../../Components";

export const ConfigureMenu = (state) => {
  if (state.menu !== "MyStory") {
    setTimeout(() => {
      state.setMenu("MyStory");
    });
  }
  return null;
};

export const Text = styled.div`
  margin: 1rem 0 1rem 0;
  font-size: 1.2rem;

  blockquote {
    font-size: 0.8rem;
    margin-left: 1rem;
  }

  pre {
    font-family: monospace;
    margin: 1rem 0;
    line-height: 1.5rem;
  }
`;

export const Break = styled.br`
  clear: both;
`;

export const Photo = styled(ParentPhoto)`
  cursor: pointer;
  max-height: 480px;

  @media screen and (max-width: 950px) {
    max-height: 240px;
  }
`;

export const PhotoCenter = styled(ParentPhoto)`
  display: block;
  margin: 0 auto;
  max-width: 600px;
  max-height: 400px;

  @media screen and (max-width: 950px) {
    max-width: 360px;
    max-height: 240px;
  }
`;

export const PhotoLeft = styled(Photo)`
  float: left;
  margin: 0 1.5rem 0.5rem 0;
`;

export const PhotoLeftSmall = styled(PhotoLeft)`
  width: 360px;
  height: 240px;
`;

export const PhotoRight = styled(Photo)`
  float: right;
  margin: 0 0 0.5rem 1.5rem;
`;

export const PhotoRightSmall = styled(PhotoRight)`
  width: 360px;
  height: 240px;
`;

export const Sisters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Sister = styled.div`
  align-self: center;
  flex-grow: 1;
  text-align: center;
  margin: 0.9rem;
`;

export const SisterPhoto = styled(Photo)`
  margin: auto;
  margin-bottom: 1rem;
  width: 360px;
  height: 240px;
`;
