import React from "react";
import {
  TripContainer,
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "./_Include";

const LosAngeles2019 = (state) => {
  return (
    <TripContainer visible={state.trip === "LosAngeles2019"}>
      <Container>
        <Title>Whisky A Go GO - 24th Jan 2019</Title>
        <Text>
          At Lunario while I had used my 55-250mm during the soundcheck I stuck
          with the 18-55mm during the show as I was still new to the camera and
          wasn't confident about switching lenses in the relative darkness.
          Before my second time going to see The Warning play live I decided to
          get a second hand 18-135mm f3.5-5.6 as it has a bit more flexibility.
        </Text>
        <Text>
          I did also get myself a 50mm f1.8 to see just how much better it
          performed in low-light.
        </Text>
        <Text>
          This show was a private show and as a Patreon of The Warning I was
          allowed in for the soundcheck and so was able to get to the front of
          the stage before the doors opened so I could pick my spot and I stood
          right in front of Dany. This also had the advantage that I could use
          the stage to rest my camera on when switching lenses which made it a
          whole lot easier than at Lunario.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012413411.jpg"
            largeSrc="/img/02whisky2019/2019012413412.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 50mm f1.8 1/125s ISO 6400"
          >
            <Text>Dany at the soundcheck</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012413431.jpg"
            largeSrc="/img/02whisky2019/2019012413432.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 50mm f2.5 1/160s ISO 6400"
          >
            <Text>Ale taking a break during the soundcheck</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012413961.jpg"
            largeSrc="/img/02whisky2019/2019012413962.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 24mm f4.0 1/60s ISO 3200"
          >
            <Text>
              Pau during the meet and greet, which for this private show they
              had before the show.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012414341.jpg"
            largeSrc="/img/02whisky2019/2019012414342.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 50mm f2.8 1/160s ISO 4935"
          >
            <Text>
              I love this photo of Dany, with the smoke machine going in the
              background it provides a perfect back drop.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012414361.jpg"
            largeSrc="/img/02whisky2019/2019012414362.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 50mm f2.8 1/160s ISO 1234"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012418211.jpg"
            largeSrc="/img/02whisky2019/2019012418212.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 50mm f2.5 1/160s ISO 6400"
          >
            <Text>
              I got a few photos of Ale using my new 50mm prime, which produces
              really nice results but as I couldn't move around it was a little
              bit close for even Ale who was at the far side of the stage.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012418501.jpg"
            largeSrc="/img/02whisky2019/2019012418502.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 50mm f2.2 1/160s ISO 6400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012420451.jpg"
            largeSrc="/img/02whisky2019/2019012420452.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 18mm f3.5 1/50s ISO 6400"
          >
            <Text>
              No show would be complete without the obligatory bow at the end
              and the perfect opportunity to capture Ale, Pau and Dany together.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/02whisky2019/2019012420601.jpg"
            largeSrc="/img/02whisky2019/2019012420602.jpg"
            camera="Whisky A Go Go, Los Angeles - 24th Jan 2019 - Canon EOS 200D 42mm f4.5 1/25s ISO 6400"
          >
            <Text>
              At the end of the show I managed to get a good closeup of Pau
              without her drumkit getting in the way.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </TripContainer>
  );
};

export default LosAngeles2019;
