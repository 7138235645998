import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const LeestaVall = (state) => {
  return (
    <>
      <Container>
        <Title>Leesta Vall - 4th Dec 2019</Title>
        <Text>
          In the morning The Warning was recording their single Narcisista
          direct to vinyl for twenty lucky fans and I was allowed to be there in
          the studio with them.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/07leestavall2019/2019120447611.jpg"
            largeSrc="/img/07leestavall2019/2019120447612.jpg"
            camera="Leesta Vall, New York - 04th Dec 2019 - Canon EOS 200D 50mm f1.8 1/200s ISO 800"
          >
            <Text>
              Most of the time was spent in the control room while The Warning
              were in the live room. It did make it interesting trying to avoid
              too many reflections.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/07leestavall2019/2019120447931.jpg"
            largeSrc="/img/07leestavall2019/2019120447932.jpg"
            camera="Leesta Vall, New York - 04th Dec 2019 - Canon EOS 200D 50mm f1.8 1/200s ISO 4935"
          >
            <Text>
              I did get the opportunity to enter the live room to get a few
              photos.
            </Text>
            <Text>
              Pau wasn't actually feeling very well so was drinking tea during
              breaks.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/07leestavall2019/2019120447951.jpg"
            largeSrc="/img/07leestavall2019/2019120447952.jpg"
            camera="Leesta Vall, New York - 04th Dec 2019 - Canon EOS 200D 50mm f1.8 1/200s ISO 4935"
          >
            <Text>
              While Ale is the shyest of the three, I think in this case the
              scarf was more due to the cold than to hide from prying cameras.
              While it was bitterly cold outside and felt perfectly fine inside
              for someone who's used to a colder climate, The Warning are used
              to a much warmer climate.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default LeestaVall;
