import React from "react";
import { Container } from "../../GlobalStyle";
import { Processor } from "../../Components";
import { ConfigureMenu, Text } from "./_Include";

function MyStory2021(state) {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          Ignoring 2017 when I discovered The Warning in December, 2021 would be
          the first year where I didn't get to see them in person. For someone
          like me who's a diehard fan and admittedely a little bit obsessed this
          was torture.
        </Text>
        <Text>
          During most of 2020 The Warning had been very active on social media
          while they were stuck and home and preparing for recording their third
          album. But for a large part of 2021 they were either in the studio or
          actively promoting the new singles, with a lot of interviews in
          Spanish which I couldn't understand.
        </Text>
        <Text>
          The result was that I was suffering from serious withdrawl and that
          coupled with the enforced lockdown in the UK meant that 2021 is a year
          best forgotten...
        </Text>
        <Text>
          It did have some highlights though, as The Warning released the first
          six songs from the album as their Mayday EP and their updated cover of
          Enter Sandman that they recorded with Alessia Cara for the Blacklist
          album and in December Dany introduced her Masterclass as a new reward
          on Patreon.
        </Text>
      </Container>
    </Processor>
  );
}

export default MyStory2021;
