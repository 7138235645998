import React from "react";
import { LyricsContainer, Processor } from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
} from "./_Include";

const FreeFalling = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <LyricsContainer />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/free_falling.jpg" />
          <AlbumText>
            Free Falling was the first single released by The Warning and is
            featured in the video game Rock Band 4.
          </AlbumText>
        </Album>
        <Videos>
          <Video
            src="https://www.youtube.com/embed/wlipfTBaLII"
            title="Free Falling"
            showLyrics="true"
          />
        </Videos>
      </Container>
    </Processor>
  );
};

export default FreeFalling;
