import React from "react";
import { TripContainer, Text } from "../_Include";
import Troubadour23 from "./Troubadour23";
import Troubadour24 from "./Troubadour24";

const LosAngeles2022 = (state) => {
  return (
    <TripContainer visible={state.trip === "LosAngeles2022"}>
      <Text>
        During the pandemic I decided to complete the trinity by getting the RF
        70-200mm f2.8. Having had only a few occasions to use it I really wanted
        to be able to capture much better closeup shots of Pau.
      </Text>
      <Troubadour23 {...state} />
      <Troubadour24 {...state} />
    </TripContainer>
  );
};

export default LosAngeles2022;
