import React from "react";
import { isMobile } from "react-device-detect";

const Image = (props) => {
  function GetImageScale() {
    return window.devicePixelRatio < 2 ? 0.05 : 0.03;
  }

  function GetSrc(props) {
    return props.src ? props.src : isMobile ? props.smallSrc : props.largeSrc;
  }

  function GetId(props) {
    if (props.id) {
      return props.id;
    }

    const src = GetSrc(props);
    const index = src.lastIndexOf("/");
    if (index === -1) {
      return src;
    }

    return src.slice(index + 1);
  }

  function RenderImage(props) {
    const id = GetId(props);
    const src = GetSrc(props);
    const scale = GetImageScale();

    const imgProps = {
      id: id,
      src: src,
      align: props.align,
      alt: props.alt,
      className: props.className,
      onLoad: props.onLoad,
      onClick: props.onClick,
      style: {
        ...props.style,
        width: props.width * scale + "em",
        height: props.height * scale + "em",
      },
    };

    const hoverProps = props.hoverSrc
      ? {
          onMouseOver: (e) => {
            e.target.src = props.hoverSrc;
          },
          onMouseOut: (e) => {
            e.target.src = imgProps.src;
          },
        }
      : {};

    return Render(imgProps, hoverProps);
  }

  function Render(imgProps, hoverProps) {
    // eslint-disable-next-line
    return <img {...imgProps} {...hoverProps} loading="lazy" />;
  }

  function View(e) {
    if (props.scrollPosition) {
      window.scroll(props.scrollPosition.x, props.scrollPosition.y);
      props.setScrollPosition(null);
    }
  }

  return RenderImage({
    ...props,
    onLoad: (e) => View(e),
  });
};

export default Image;
