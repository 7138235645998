import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const TeatroMetropólitan29 = (state) => {
  return (
    <>
      <Container>
        <Title>Teatro Metropólitan - 26th Aug 2022</Title>
        <Text>
          For this first show things were a little hectic, we only had short
          notice about the the time for the sound check and as we were out for
          lunch at the time I didn't have a chance to go back to the hotel to
          pick up my Canon, so went just taking my phone.
        </Text>
        <Text>
          I also wasn't sure what the situation was about bring my Canon to the
          show itself, but had been assured that it would be OK for the second
          show. So once again just took my phone to the show. This did give me
          the opportunity to fully test out the camera on my iPhone, and I will
          say that it is a huge improvement over my previous OnePlus, but still
          doesn't really compare to a proper camera.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/14metropolitan2022/2022082601011.jpg"
            largeSrc="/img/14metropolitan2022/2022082601012.jpg"
            camera="Teatro Metropólitan, Mexico City - 26th Aug 2022 - iPhone 12 Pro 26mm f1.6 1/60s ISO 200"
          >
            <Text>
              Taken during the sound check, for this show The Warning went all
              out with the light show, getting Brian Bogovic to run the lights,
              a job he'd done for them while they were on the Halestorm tour,
              and he did an amazing job.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/14metropolitan2022/2022082601511.jpg"
            largeSrc="/img/14metropolitan2022/2022082601512.jpg"
            camera="Teatro Metropólitan, Mexico City - 26th Aug 2022 - iPhone 12 Pro 52mm f2 1/120s ISO 80"
          >
            <Text>
              Dany in front of some of the many video walls they had on stage.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/14metropolitan2022/2022082601531.jpg"
            largeSrc="/img/14metropolitan2022/2022082601532.jpg"
            camera="Teatro Metropólitan, Mexico City - 26th Aug 2022 - iPhone 12 Pro 52mm f2 1/60s ISO 200"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/14metropolitan2022/2022082602871.jpg"
            largeSrc="/img/14metropolitan2022/2022082602872.jpg"
            camera="Teatro Metropólitan, Mexico City - 26th Aug 2022 - iPhone 12 Pro 52mm f2 1/100s ISO 80"
          >
            <Text>Ale looking particularly happy.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/14metropolitan2022/2022082602921.jpg"
            largeSrc="/img/14metropolitan2022/2022082602922.jpg"
            camera="Teatro Metropólitan, Mexico City - 26th Aug 2022 - iPhone 12 Pro 52mm f2 1/100s ISO 100"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/14metropolitan2022/2018112504801.jpg"
            largeSrc="/img/14metropolitan2022/2018112504802.jpg"
            camera="Teatro Metropólitan, Mexico City - 26th Aug 2022 - iPhone 12 Pro 52mm f2 1/66s ISO 200"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default TeatroMetropólitan29;
