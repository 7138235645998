export const GetSettings = () => {
  if (window.location.port === "3000") {
    return {
      ApiUrl: "http://localhost/php/api",
      UploadUrl: "http://localhost/php/api/upload.php",
      PostUrl: "http://localhost/php/api/post.php",
    };
  }

  return {
    ApiUrl: "/php/api",
    UploadUrl: "/php/api/upload.php",
    PostUrl: "/php/api/post.php/",
  };
};

export const GetAuthHeader = (authToken) => {
  var headers = new Headers();
  headers.append("authtoken", authToken);
  return headers;
};
