import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const WhiskyAGoGo15 = (state) => {
  return (
    <>
      <Container>
        <Title>Whisky A Go Go - 15th Jan 2020</Title>
        <Text>
          My first time at the Whisky I'd stood in front of Dany, so this time I
          managed to get in front of Ale. While ideally, I'd have tried to get
          dead centre they have speakers fitted to the stage so I was just to
          the left of them so I could once again use the stage to rest my camera
          when I changed lenses.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011556921.jpg"
            largeSrc="/img/09whisky2020/2020011556922.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/500s ISO 39481"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011557091.jpg"
            largeSrc="/img/09whisky2020/2020011557092.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/500s ISO 25600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011557811.jpg"
            largeSrc="/img/09whisky2020/2020011557812.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 24mm f2.8 1/640s ISO 39481"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011561181.jpg"
            largeSrc="/img/09whisky2020/2020011561182.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 50mm f2.8 1/500s ISO 25600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011561491.jpg"
            largeSrc="/img/09whisky2020/2020011561492.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/500s ISO 25600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011562371.jpg"
            largeSrc="/img/09whisky2020/2020011562372.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/500s ISO 16600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011563181.jpg"
            largeSrc="/img/09whisky2020/2020011563182.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 16600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011563491.jpg"
            largeSrc="/img/09whisky2020/2020011563492.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011564551.jpg"
            largeSrc="/img/09whisky2020/2020011564552.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 15mm f2.8 1/200s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011564731.jpg"
            largeSrc="/img/09whisky2020/2020011564732.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 48mm f2.8 1/500s ISO 6400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011568401.jpg"
            largeSrc="/img/09whisky2020/2020011568402.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 33mm f2.8 1/500s ISO 19740"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011571121.jpg"
            largeSrc="/img/09whisky2020/2020011571122.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 50mm f2.8 1/500s ISO 8300"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011571431.jpg"
            largeSrc="/img/09whisky2020/2020011571432.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/500s ISO 8300"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011573721.jpg"
            largeSrc="/img/09whisky2020/2020011573722.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 48mm f2.8 1/100s ISO 6400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011573851.jpg"
            largeSrc="/img/09whisky2020/2020011573852.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/100s ISO 6400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011574231.jpg"
            largeSrc="/img/09whisky2020/2020011574232.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 70mm f2.8 1/100s ISO 6400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011576181.jpg"
            largeSrc="/img/09whisky2020/2020011576182.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 24mm f2.8 1/100s ISO 4935"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/09whisky2020/2020011577561.jpg"
            largeSrc="/img/09whisky2020/2020011577562.jpg"
            camera="Whisky A Go Go, Los Angeles - 15th Jan 2020 - Canon EOS R 35mm f2.8 1/100s ISO 4150"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default WhiskyAGoGo15;
