import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: none;
  top: var(--headerHeight);
  left: 2rem;
  width: calc(100% - 3rem);
  height: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  z-index: 991;
  background-color: var(--transparent);

  @media screen and (max-width: 960px) {
    left: 0;
    width: 100%;
  }
`;

const Text = styled.div`
  width: 100%;
  height: calc(100% - var(--headerHeight));
  overflow-y: auto;
  padding: 2rem 6rem;
  column-count: 2;
  font-size: 1.3rem;

  @media screen and (max-width: 960px) {
    padding: 1rem 3rem;
    column-count: auto;
  }
`;

function LyricsContainer() {
  window.addEventListener("keydown", KeyDown);
  return (
    <Container id="lyricsContainer" onClick={() => Hide()}>
      <Text id="lyricsText"></Text>
    </Container>
  );
}

function Hide() {
  var container = document.getElementById("lyricsContainer");
  container.style.display = "none";

  var lyrics = document.getElementById("lyricsText");
  lyrics.innerText = "";
}

function KeyDown(e) {
  if (e.keyCode === 27) {
    Hide();
  }
}

export default LyricsContainer;
