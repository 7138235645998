import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const WhiskyAGoGo16 = (state) => {
  return (
    <>
      <Container>
        <Title>Whisky A Go Go - 16th Jan 2020</Title>
        <Text>
          The second night I had hoped to be just to the right of centre but
          someone else beat me to it so I ended up standing in the exact same
          spot as the first night, although I can't complain as it provided a
          really good view.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011679901.jpg"
            largeSrc="/img/10whisky2020/2020011679902.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 70mm f2.8 1/500s ISO 19740"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011680151.jpg"
            largeSrc="/img/10whisky2020/2020011680152.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 70mm f2.8 1/100s ISO 6400"
          >
            <Text>
              This photo was taken at the soundcheck for their second night at
              the Whisky. Ale is a formidable talent on the bass and while she's
              the shyest of the three and doesn't like to sing lead, she exudes
              confidence while playing.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011685131.jpg"
            largeSrc="/img/10whisky2020/2020011685132.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 70mm f2.8 1/200s ISO 16600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011685161.jpg"
            largeSrc="/img/10whisky2020/2020011685162.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/200s ISO 16600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011685501.jpg"
            largeSrc="/img/10whisky2020/2020011685502.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011685711.jpg"
            largeSrc="/img/10whisky2020/2020011685712.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 61mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011685861.jpg"
            largeSrc="/img/10whisky2020/2020011685862.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011693141.jpg"
            largeSrc="/img/10whisky2020/2020011693142.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 43mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011693331.jpg"
            largeSrc="/img/10whisky2020/2020011693332.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 43mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011693591.jpg"
            largeSrc="/img/10whisky2020/2020011693592.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011694021.jpg"
            largeSrc="/img/10whisky2020/2020011694022.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011694811.jpg"
            largeSrc="/img/10whisky2020/2020011694812.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 50mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011696621.jpg"
            largeSrc="/img/10whisky2020/2020011696622.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 9870"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/10whisky2020/2020011696801.jpg"
            largeSrc="/img/10whisky2020/2020011696802.jpg"
            camera="Whisky A Go Go, Los Angeles - 16th Jan 2020 - Canon EOS R 24mm f2.8 1/500s ISO 9870"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default WhiskyAGoGo16;
