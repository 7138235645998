import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Debug, Navbar, Footer, Photo } from "./Components";
import GlobalStyle, { Container } from "./GlobalStyle";
import State from "./State";
import Dialog from "./Components/Dialog";
import Home from "./Sites/Home";
import InPhotos from "./Sites/InPhotos/InPhotos";
import TheWarning from "./Sites/TheWarning/TheWarning";
import Discography from "./Sites/TheWarning/Discography";
import EscapeTheMind from "./Sites/TheWarning/Discography/EscapeTheMind";
import FreeFalling from "./Sites/TheWarning/Discography/FreeFalling";
import XXICenturyBlood from "./Sites/TheWarning/Discography/XXICenturyBlood";
import QueenOfTheMurderScene from "./Sites/TheWarning/Discography/QueenOfTheMurderScene";
import Narcisista from "./Sites/TheWarning/Discography/Narcisista";
import EnterSandman from "./Sites/TheWarning/Discography/EnterSandman";
import Error from "./Sites/TheWarning/Discography/Error";
import SocialMedia from "./Sites/TheWarning/SocialMedia";
import MyStory2017 from "./Sites/MyStory/2017";
import MyStory2018 from "./Sites/MyStory/2018";
import MyStory2019 from "./Sites/MyStory/2019";
import MyStory2020 from "./Sites/MyStory/2020";
import MyStory2021 from "./Sites/MyStory/2021";
import MyStory2022 from "./Sites/MyStory/2022";
import "./index.css";

function App() {
  const state = State();

  if (state.photo) {
    state.photo.className = "";
    return (
      <>
        <Container>
          <GlobalStyle {...state} />
          <Photo {...state.photo} />
        </Container>
      </>
    );
  }

  return (
    <Router>
      <GlobalStyle {...state} />
      <Dialog {...state} />
      <Debug />
      <Navbar {...state} />
      <Switch>
        <Route exact path="/" render={() => <Home {...state} />} />
        <Route exact path="/in-photos" render={() => <InPhotos {...state} />} />
        <Route
          exact
          path="/the-warning"
          render={() => <TheWarning {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography"
          render={() => <Discography {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/escape-the-mind"
          render={() => <EscapeTheMind {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/free-falling"
          render={() => <FreeFalling {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/xxicb"
          render={() => <XXICenturyBlood {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/qotms"
          render={() => <QueenOfTheMurderScene {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/narcisista"
          render={() => <Narcisista {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/entersandman"
          render={() => <EnterSandman {...state} />}
        />
        <Route
          exact
          path="/the-warning/discography/error"
          render={() => <Error {...state} />}
        />
        <Route
          exact
          path="/the-warning/social-media"
          render={() => <SocialMedia {...state} />}
        />
        <Route
          exact
          path="/my-story"
          render={() => <MyStory2017 {...state} />}
        />
        <Route
          exact
          path="/my-story/2017"
          render={() => <MyStory2017 {...state} />}
        />
        <Route
          exact
          path="/my-story/2018"
          render={() => <MyStory2018 {...state} />}
        />
        <Route
          exact
          path="/my-story/2019"
          render={() => <MyStory2019 {...state} />}
        />
        <Route
          exact
          path="/my-story/2020"
          render={() => <MyStory2020 {...state} />}
        />
        <Route
          exact
          path="/my-story/2021"
          render={() => <MyStory2021 {...state} />}
        />
        <Route
          exact
          path="/my-story/2022"
          render={() => <MyStory2022 {...state} />}
        />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
