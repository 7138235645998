import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const PapáSobreRuedas = (state) => {
  return (
    <>
      <Container>
        <Title>Papá Sobre Ruedas - 15th Jun 2019</Title>
        <Text>
          Papá Sobre Ruedas is an annual car show held in Monterrey which The
          Warning was headlining.
        </Text>
        <Text>
          While being right up against the barrier for the start of the show,
          patrons of The Warning were allowed in front of the barrier partway
          through, which allowed for more freedom of movement and the ability to
          get some good photos, although the light made things especially
          difficult as The Warning went on just as it was getting dark and the
          stage lights illuminated Dany and Ale more from above than from the
          front and Pau was so far back on the stage that at 55mm I struggled to
          get a good shot.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/04papasobreruedas2019/2019061532551.jpg"
            largeSrc="/img/04papasobreruedas2019/2019061532552.jpg"
            camera="Papá Sobre Ruedas, Monterrey - 15th Jun 2019 - Canon EOS 200D 55mm f2.8 1/640s ISO 6400"
          >
            <Text>
              Ale playing her distinctive Euro4 Rachel Bolan signature Spector.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/04papasobreruedas2019/2019061532681.jpg"
            largeSrc="/img/04papasobreruedas2019/2019061532682.jpg"
            camera="Papá Sobre Ruedas, Monterrey - 15th Jun 2019 - Canon EOS 200D 40mm f2.8 1/640s ISO 6400"
          >
            <Text>
              This is my favourite photo I got during this show, not so much the
              quality of the photo itself but I really like Dany's energy and
              the way the light catches her hair.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/04papasobreruedas2019/2019061534611.jpg"
            largeSrc="/img/04papasobreruedas2019/2019061534612.jpg"
            camera="Papá Sobre Ruedas, Monterrey - 15th Jun 2019 - Canon EOS 200D 23mm f2.8 1/100s ISO 12800"
          >
            <Text>
              While I couldn't get any really good photos of Pau during the
              show, I did manage to get a few during an exclusive meet and greet
              for patrons after the show.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default PapáSobreRuedas;
