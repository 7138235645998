import React from "react";
import {
  LyricsContainer,
  Processor,
  VideoContainer,
} from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
  VideoText,
} from "./_Include";

const XXICenturyBlood = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <LyricsContainer />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/xxicb.jpg" />
          <AlbumText>
            XXI Century Blood is their first full album; released in March 2017
            it showcased their incredible writing ability.
          </AlbumText>
          <AlbumText>
            After the varied nature of Escape The Mind they realised that rock
            was the way to go and the result is supremely good. Although while
            they focused on rock each song still has a very varied and unique
            sound and the whole album is a joy to listen to from start to end.
          </AlbumText>
        </Album>
        <Videos>
          <VideoContainer id="xxicenturyblood">
            <Video
              src="https://www.youtube.com/embed/syTyga3lVL0"
              title="XXI Century Blood"
              showLyrics="true"
            />
            <VideoText>
              <span>
                The title song of the album represents how we are becoming
                dependent on technology to the extent that we become slaves to
                the virtual environment we reside in.
              </span>
              <span>
                The accompanying video has won awards and you can see why, the
                first time I saw it I was totally blown away. The production
                quality is as good as anything and the story really comes
                through.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="shatteredheart">
            <Video
              src="https://www.youtube.com/embed/YwbYUT3A1po"
              title="Shattered Heart"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Shattered Heart is a classic song of love and betrayal, where
                the protagonist discovers they've been lied to and deceived and
                can't cope with the resultant broken heart. It's also an
                incredibly intense song and is one of my favourites, in
                particular, the lines
                <blockquote>
                  <pre>{`I don't think you understand
This love was all I had`}</pre>
                </blockquote>
                just totally chokes me up whenever I'm singing along to it.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="survive">
            <Video
              src="https://www.youtube.com/embed/DwtQwT0YgTo"
              title="Survive"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Survive is a true classic and is used to close most of their
                live shows due to its incredible ending where the drums and
                guitar build to a crescendo and then it climaxes with a solitary
                bass line.
              </span>
              <span>
                It's also special in that Pau and Dany share the lead vocal with
                Pau opening the song and singing the chorus and Dany singing the
                verses.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="wildfire">
            <Video
              src="https://www.youtube.com/embed/MQVJs5PG_AM?t=248"
              title="Wildfire"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Wildfire is an incredible song, in an interview, Dany says that
                the song is about people having the courage to stand together
                and fight for what is right and to not give up.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="blackholes">
            <Video
              src="https://www.youtube.com/embed/lM8KeQ_tNQc"
              title="Black Holes (Don't Hold On)"
              lyrics="Black Holes"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Black Holes is one of the two ballads where Pau leaves the drums
                behind for the piano. It's a song about someone battling
                addiction and not wanting to drag their loved ones down with
                them. Pau's vocal performance is absolutely sublime and with
                Ale's steady bass and Dany's guitar and backing vocals they
                deliver a song that will melt the heart of anyone who hears it.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="ourmistakes">
            <Video
              src="https://www.youtube.com/embed/MQVJs5PG_AM"
              title="Our Mistakes"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Our Mistakes is a rather sad song about when the spark goes from
                a relationship but you're worried about being alone so you stick
                with it even though you know it's not right.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="whenimalone">
            <Video
              src="https://www.youtube.com/embed/xfT6QyU1h0c"
              title="When I'm Alone"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Dany wrote When I'm Alone when suffering from homesickness while
                studying at Berkley College of Music. It's truly a remarkable
                song which on a personal note I really relate to and is why it's
                my favourite song on the album. I love it so much that I bought
                the Ovation guitar that Dany used to write it.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="runaway">
            <Video
              src="https://www.youtube.com/embed/4ljQZCpODzk"
              title="Runaway"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Runaway is a solid rock song which is one of Ale's favourites;
                she says that she loves the lyrics to this song. I personally
                love the drum part and the whole feel of the song.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="unmendable">
            <Video
              src="https://www.youtube.com/embed/VuOBMQCtatM"
              title="Unmendable"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Up until Lunario Unmendable was used to open most of their
                shows, it's such a hard-hitting song about someone who's fake
                and tries to trick people but who will ultimately get what they
                deserve. It also has a supreme drum solo at the end which shows
                off Pau's awesome abilities.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="copperbullets">
            <Video
              src="https://www.youtube.com/embed/6E5rQw6JtgI"
              title="Copper Bullets"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Copper Bullets was a slightly controversial addition to the
                album due to the subject matter. Originally their parents
                weren't sure it should be included, but fortunately, they were
                persuaded otherwise. It tells a story of an abusive relationship
                where the man kills his wife then himself and is Pau's favourite
                because she says it's the first song where they were narrating a
                story and is very different lyrically from their other songs.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="showmethelight">
            <Video
              src="https://www.youtube.com/embed/3CJ1KRhMxeE"
              title="Show Me The Light"
              showLyrics="true"
            />
            <VideoText>
              <span>
                Show Me The Light is the second ballad where Pau plays the piano
                and sings and is also the second of Ale's favourite songs on the
                album and she says in an interview that a lot of the songs on
                the album are about problems but Show Me The Light is a song of
                hope.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="exterminated">
            <Video
              src="https://www.youtube.com/embed/zsgmwbJvgKI"
              title="Exterminated"
              showLyrics="true"
            />
            <VideoText>
              <span>
                When asked about the inspiration for their songs they will say
                that the majority of them are not from personal experience and
                Exterminated was inspired by the Hunger Games series of books.
                As the title suggests, like many of their songs, it is quite
                dark.
              </span>
            </VideoText>
          </VideoContainer>
          <VideoContainer id="riverssoul">
            <Video
              src="https://www.youtube.com/embed/VF_COP9yClg"
              title="River's Soul"
              showLyrics="true"
            />
            <VideoText>
              <span>
                River's Soul along with Exterminated is one of two songs that
                were recorded as a live session. I particularly like Ale's bass
                line on this and I've loved the few times that Ale's sang it
                live and hope that I'll get to witness it live one day. But I
                also love the ensemble singing at the end of the song.
              </span>
            </VideoText>
          </VideoContainer>
        </Videos>
      </Container>
    </Processor>
  );
};

export default XXICenturyBlood;
