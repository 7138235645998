import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const Troubadour23 = (state) => {
  return (
    <>
      <Container>
        <Title>Troubadour - 23rd May 2022</Title>
        <Text>
          With all the rescheduling involved in this trip I'd managed to get the
          camera policy for the Troubadour confused with another venue. The
          result being that while I could take my camera into the soundcheck as
          this was before the main security were present, I needed a photo pass
          to get it into the show.
        </Text>
        <Text>
          I only included one photo that I took with my phone as the rest of
          them just aren't good enough. But while I love taking photos, when I'm
          at a show, the music and enjoying the performance is always the most
          important thing.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/11troubadour2022/2022052328441.jpg"
            largeSrc="/img/11troubadour2022/2022052328442.jpg"
            camera="Troubadour - 23rd May 2022 - Canon EOS R 200mm f2.8 1/100s ISO 6400"
          >
            <Text>
              Dany with Petunia (the camcorder), I think she was looking back at
              either her dad or Lalo (their sound engineer) who were stood just
              to my right.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/11troubadour2022/2022052328611.jpg"
            largeSrc="/img/11troubadour2022/2022052328612.jpg"
            camera="Troubadour - 23rd May 2022 - Canon EOS R 135mm f2.8 1/100s ISO 8300"
          >
            <Text>Ale sharing a laugh with Pau.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/11troubadour2022/2022052328791.jpg"
            largeSrc="/img/11troubadour2022/2022052328792.jpg"
            camera="Troubadour - 23rd May 2022 - Canon EOS R 200mm f2.8 1/100s ISO 12800"
          >
            <Text>
              I like this photo as I got focus on Ale even though Dany was
              mostly blocking her.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/11troubadour2022/2022052329311.jpg"
            largeSrc="/img/11troubadour2022/2022052329312.jpg"
            camera="Troubadour - 23rd May 2022 - Canon EOS R 200mm f2.8 1/100s ISO 6400"
          >
            <Text>
              Pau looking every bit the rock star with her sun glasses on during
              the soundcheck.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/11troubadour2022/2022052329351.jpg"
            largeSrc="/img/11troubadour2022/2022052329352.jpg"
            camera="Troubadour - 23rd May 2022 - Canon EOS R 43mm f2.8 1/100s ISO 6400"
          />
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/11troubadour2022/202205232203331.jpg"
            largeSrc="/img/11troubadour2022/202205232203332.jpg"
            camera="Troubadour - 23rd May 2022 - OnePlus 6T 25mm f1.7 1/25s ISO 500"
          >
            <Text>
              This was the one useable photo I got during the show on my phone
              as I was stood right in front of Ale.
              <br />I like that I managed to get Pau and the Troubadour neon
              sign in the shot.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default Troubadour23;
