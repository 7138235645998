import React from "react";
import { Processor } from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
} from "./_Include";

const EnterSandman = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/blacklist.jpg" />
          <AlbumText>
            I wouldn't normally include a cover in their discography as I want
            to highlight The Warnings amazing original material, but their cover
            of Enter Sandman that they recorded with Alessia Cara has a special
            significance.
          </AlbumText>
          <AlbumText>
            As is well known, The Warning garnered a lot of attention when their
            cover of Enter Sandman, recorded in their basement, went viral when
            they were only 9, 11 &amp; 13.
          </AlbumText>
          <AlbumText>
            Chris Smith who founded 21 Entertainment Group is The Warning's
            international manager and also manages Alessia Cara so when Alessia
            was invited to participate in The Blacklist project Chris suggested
            that she join up with The Warning.
          </AlbumText>
          <AlbumText>
            They had only a week to come up with an idea and record it as
            Metallica weren't looking for artists to just cover the original but
            put their own spin on it and do something new. They worked with Matt
            Squire to come up with an arrangement and recorded it in Monterrey
            with Alessia recording her parts remotely from Canada.
          </AlbumText>
          <AlbumText>
            I know I'm a little bit biased but I think this is the best song on
            the Blacklist by a long way.
          </AlbumText>
        </Album>
        <Videos>
          <Video
            src="https://www.youtube.com/embed/9tKQgesCcTc"
            title="Alessia Cara &amp; The Warning - Enter Sandman"
            showTitle="true"
          />
        </Videos>
      </Container>
    </Processor>
  );
};

export default EnterSandman;
