import { GetSettings, GetAuthHeader } from "./Common";

export const ApiAuthenticate = (data) => {
  const settings = GetSettings();
  return new Promise((resolve) => {
    fetch(settings.ApiUrl + "/credentials.php/authenticate", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          resolve(response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  });
};

export const ApiValidate = (data) => {
  const settings = GetSettings();
  return new Promise((resolve) => {
    fetch(settings.ApiUrl + "/credentials.php/validate", {
      method: "GET",
      headers: GetAuthHeader(data.authToken),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          resolve(response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  });
};

export const ApiLogout = (data) => {
  const settings = GetSettings();
  return new Promise((resolve) => {
    fetch(settings.ApiUrl + "/credentials.php/logout", {
      method: "POST",
      headers: GetAuthHeader(data.authToken),
    })
      .then((response) => {
        if (response.ok) {
          resolve();
        } else {
          resolve(response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  });
};

export const ApiChangePassword = (data) => {
  const settings = GetSettings();
  return new Promise((resolve) => {
    fetch(settings.ApiUrl + "/credentials.php/" + data.Id, {
      method: "PATCH",
      headers: GetAuthHeader(data.authToken),
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          resolve();
        } else {
          resolve(response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  });
};
