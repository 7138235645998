import React from "react";
import styled from "styled-components";
import { ApiLogout } from "../Api/Authenticate";
import { DialogContainer } from "../GlobalStyle";

const NavMenu = styled.ul`
  margin-top: var(--headerHeight);
  list-style: none;
`;

const NavItem = styled.li`
  width: 100%;
  max-width: 400px;
  height: var(--headerHeight);

  &:hover {
    border: none;
  }
`;

const NavLink = styled.div`
  color: var(--foreColor);
  text-decoration: none;
  font-size: 1.6rem;
  padding-left: 2rem;
  height: 100%;

  &:hover {
    color: var(--highColor);
    transition: all 0.3s ease;
  }

  text-align: center;
  padding: 2rem;
  width: 100%;
  display: table;
`;

const UserMenu = (state) => {
  const handleClick = () =>
    state.setShowDialog(state.showDialog === null ? "UserMenu" : null);

  const visible = state.showDialog === "UserMenu";
  if (!visible) {
    return null;
  }

  return (
    <DialogContainer direction="right" visible={visible} onClick={handleClick}>
      <NavMenu>
        <NavItem>
          <NavLink
            onClick={(e) => {
              e.stopPropagation();
              state.setShowDialog("ChangePassword");
            }}
          >
            Change Password
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={(e) => {
              e.stopPropagation();
              state.setShowDialog("SetPhoto");
            }}
          >
            Set Photo
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => Logout(state)}>Logout</NavLink>
        </NavItem>
      </NavMenu>
    </DialogContainer>
  );
};

function Logout(state) {
  ApiLogout({ authToken: state.authToken }).then((e) => {
    state.setUser(null);
    state.setShowDialog(null);
  });
}

export default UserMenu;
