import React from "react";
import {
  TripContainer,
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "./_Include";

const MexicoCity2018 = (state) => {
  return (
    <TripContainer visible={state.trip === "MexicoCity2018"}>
      <Container>
        <Title>Lunario - 25th Nov 2018</Title>
        <Text>
          When I went to Lunario I took with me my Canon EOS 200D which I'd
          bought primarily for recording my YouTube videos. I'd never really
          intended to use it for photography but as this trip was so important
          to me I thought it would be nice to get a few photos.
        </Text>
        <Text>
          I had the 18-55mm f4-5.6 kit lens and the 55-250mm f4-5.6 which under
          good lighting produces really nice results, but under the difficult
          lighting conditions of a live show wasn't ideal. That said I was
          really happy with some of the photos as while not the cleanest,
          sharpest or even perfectly in focus I was able to capture the moments
          which enable me to relive one of the greatest experiences of my life.
        </Text>
        <Text>
          It also didn't hurt that I had a VIP ticket which allowed me access to
          the soundcheck and to be in front of the barrier during the show which
          gave me some freedom to move around.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/01lunario2018/2018112504541.jpg"
            largeSrc="/img/01lunario2018/2018112504542.jpg"
            camera="Lunario, Mexico City - 25th Nov 2018 - Canon EOS 200D 109mm f5.0 1/200s ISO 12800"
          >
            <Text>Ale warming up during the soundcheck.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/01lunario2018/2018112504661.jpg"
            largeSrc="/img/01lunario2018/2018112504662.jpg"
            camera="Lunario, Mexico City - 25th Nov 2018 - Canon EOS 200D 200mm f5.6 1/200s ISO 12800"
          >
            <Text>
              I'm not sure who / what Pau was looking at, but it would be nice
              to think she saw me pointing my camera at her.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/01lunario2018/2018112505001.jpg"
            largeSrc="/img/01lunario2018/2018112505002.jpg"
            camera="Lunario, Mexico City - 25th Nov 2018 - Canon EOS 200D 135mm f5.0 1/160s ISO 12800"
          >
            <Text>
              I beleive this was the first time I saw Dany when she wasn't
              smiling. She's well known for her infectious smile but she was
              clearly concentrating on the job of at hand.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/01lunario2018/2018112505321.jpg"
            largeSrc="/img/01lunario2018/2018112505322.jpg"
            camera="Lunario, Mexico City - 25th Nov 2018 - Canon EOS 200D 35mm f5.0 1/160s ISO 12800"
          >
            <Text>
              Taken during Crimson Queen, this was the first time I got to see
              my new guitar in person. I'd set the ISO too high and the skin had
              lost a lot of the detail. Being the first concert I'd photographed
              I had to learn quickly how to get the best out of the camera,
              although I was there mostly to enjoy the show and taking photos
              was a secondary consideration.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/01lunario2018/2018112508221.jpg"
            largeSrc="/img/01lunario2018/2018112508222.jpg"
            camera="Lunario, Mexico City - 25th Nov 2018 - Canon EOS 200D 18mm f5.0 1/320s ISO 6400"
          >
            <Text>
              This photo is actually a composite of two photos, I was stood at
              the front of the stage and found it difficult to get Dany, Pau and
              Ale in the same shot. I love the drama of this photo, it was taken
              right at the end of their performace of Survive that they closed
              the show with. The first photo (right side) is of Dany playing her
              last few notes and then the second photo (left side) shows Pau
              having hit her last cymbal and Ale playing her iconic ending.
            </Text>
            <Text>
              I have a printed copy of this hanging on my wall which I was able
              to get signed during my second trip to Mexico.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/01lunario2018/2018112508301.jpg"
            largeSrc="/img/01lunario2018/2018112508302.jpg"
            camera="Lunario, Mexico City - 25th Nov 2018 - Canon EOS 200D 49mm f5.6 1/320s ISO 6400"
          >
            <Text>
              This photo is a happy accident which captured a truly special
              moment. I was trying to get a photo of Pau at the end of the show
              and so was looking through the viewfinder when Dany walked into
              the shot and handed me my new guitar.
            </Text>
            <Text>
              I'd bought the guitar from the Kickstarter they'd ran to fund
              Queen Of The Murder Scene, as at the time they were still an
              independent band. I'd told them that rather than ship it I'd
              collect it when I came over. I hadn't really though about when I'd
              actually get it and I was so pumped up from the show that when
              Dany handed it to me it took me a while to realise what was
              happening.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </TripContainer>
  );
};

export default MexicoCity2018;
