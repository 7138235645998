import React from "react";
import { LyricsContainer, Processor, Song } from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
} from "./_Include";

const Narcisista = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <LyricsContainer />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/narcisista2.jpg" />
          <AlbumText>
            Narcisista is a very special song, the first song The Warning wrote
            entirely in Spanish.
          </AlbumText>
          <AlbumText>
            It speaks to some of the criticism they've received for writing the
            majority of their songs in English and being judged as being
            "malinchista" which is a derogatory term used in Mexico to describe
            someone who shows a preference for all things foreign.
          </AlbumText>
          <AlbumText>
            It shows that they are not going to just sit back and take it, they
            are very proud of their Mexican heritage, but as they grew up
            listening to music that was almost exclusively in English it's just
            the way they feel about music.
          </AlbumText>
          <AlbumText>
            They've said that writing music in English just feels more natural
            as English suits rock music more, it's easier to express their
            thoughts in English, but Narcisista proves that when they want to
            they can write a song in Spanish that totally rocks.
          </AlbumText>
        </Album>
        <Song names="Spanish,English" />
        <Videos>
          <Video
            src="https://www.youtube.com/embed/67iWwThdXbs"
            title="NARCISISTA Lyric Video - The Warning"
            showTitle="true"
          />
          <Video
            src="https://www.youtube.com/embed/yIfot-LLySc"
            title="The Warning - Narcisista - Foro DiDi"
            showTitle="true"
          />
          <Video
            src="https://www.youtube.com/embed/YA2eMXEm0E8"
            title="The Warning - Narcisista - Papá Sobre Ruedas"
            showTitle="true"
          />
        </Videos>
      </Container>
    </Processor>
  );
};

export default Narcisista;
