import React from "react";
import styled from "styled-components";

const Link = styled.a`
  color: var(--highColor);
  font-size: 1.2rem;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

function Processor(props) {
  const pages = [
    {
      page: "external",
      links: [
        { name: "David Bendeth", url: "https://davidbendeth.com" },
        { name: "Alessia Cara", url: "https://alessiacara.com" },
        { name: "Matt Squire", url: "https://www.mattsquireproductions.com" },
        { name: "21 Entertainment Group", url: "http://21ent.com" },
        {
          name: "Triple Triggers Band",
          url: "https://www.facebook.com/tripletriggersband",
        },
        {
          name: "Henry's",
          url: "https://www.facebook.com/grandpahenrysbbq",
        },
        {
          name: "Devon",
          url: "https://www.instagram.com/devonsounds",
        },
      ],
    },
    {
      page: "/the-warning",
      links: [{ name: "The Warning" }],
    },
    {
      page: "/the-warning/discography/escape-the-mind",
      links: [
        { name: "Take Me Down", tag: "takemedown" },
        { name: "Fade Away", tag: "fadeaway" },
        { name: "Eternal Love", tag: "eternallove" },
        { name: "Escape The Mind", tag: "escapethemind" },
      ],
    },
    {
      page: "/the-warning/discography/free-falling",
      links: [{ name: "Free Falling" }],
    },
    {
      page: "/the-warning/discography/xxicb",
      links: [
        { name: "XXI Century Blood", tag: "xxicenturyblood" },
        { name: "Shattered Heart", tag: "shatteredheart" },
        { name: "Survive", tag: "survive" },
        { name: "Wildfire", tag: "wildfire" },
        { name: "Black Holes", tag: "blackholes" },
        { name: "Our Mistakes", tag: "ourmistakes" },
        { name: "When I'm Alone", tag: "whenimalone" },
        { name: "Runaway", tag: "runaway" },
        { name: "Unmendable", tag: "unmendable" },
        { name: "Copper Bullets", tag: "copperbullets" },
        { name: "Show Me The Light", tag: "showmethelight" },
        { name: "Exterminated", tag: "exterminated" },
        { name: "River's Soul", tag: "riverssoul" },
      ],
    },
    {
      page: "/the-warning/discography/qotms",
      links: [
        { name: "Dust To Dust", tag: "dusttodust" },
        { name: "Crimson Queen", tag: "crimsonqueen" },
        { name: "Ugh", tag: "ugh" },
        { name: "The One", tag: "theone" },
        { name: "Stalker", tag: "stalker" },
        { name: "Red Hands Never Fade", tag: "redhandsneverfade" },
        { name: "The Sacrifice", tag: "thesacrifice" },
        { name: "Sinister Smiles", tag: "sinistersmiles" },
        { name: "Dull Knives", tag: "dullknives" },
        { name: "Queen Of The Murder Scene", tag: "qotms" },
        { name: "P.S.Y.C.H.O.T.I.C.", tag: "psychotic" },
        { name: "Hunter", tag: "hunter" },
        { name: "The End", tag: "theend" },
      ],
    },
    {
      page: "/the-warning/discography/narcisista",
      links: [{ name: "Narcisista" }],
    },
    {
      page: "/the-warning/discography/entersandman",
      links: [{ name: "Enter Sandman" }],
    },
    {
      page: "/the-warning/discography/error",
      links: [
        { name: "Mayday", tag: "mayday" },
        { name: "DISCIPLE", tag: "disciple" },
        { name: "CHOKE", tag: "choke" },
        { name: "ANIMOSITY", tag: "animostiy" },
        { name: "MONEY", tag: "money" },
        { name: "AMOUR", tag: "amour" },
        { name: "EVOLVE", tag: "evolve" },
        { name: "ERROR", tag: "error" },
        { name: "Z", tag: "z" },
        /*{ name: "23", tag: "23" },*/
        { name: "KOOL AID KIDS", tag: "koolaidkids" },
        { name: "REVENANT", tag: "revenant" },
        { name: "MARTIRIO", tag: "martirio" },
        { name: "BREATHE", tag: "breathe" },
      ],
    },
  ];

  function Process(parent) {
    if (parent.props && parent.props.skipProcessor) {
      return <parent.type>{parent.props.children}</parent.type>;
    }

    if (typeof parent === "string") {
      return ProcessString(parent);
    }

    if (Array.isArray(parent)) {
      let children = [];
      for (var i = 0; i < parent.length; ++i) {
        children.push(Process(parent[i]));
      }

      return children;
    }

    if (parent.props && parent.props.children) {
      let children = [];
      React.Children.toArray(parent.props.children).map((child) =>
        children.push(Process(child))
      );

      return React.cloneElement(parent, {
        children,
      });
    }

    return parent;
  }

  function ProcessString(parent) {
    const currentPage = document.location.pathname;
    let value = parent;

    for (let p = 0; p < pages.length; ++p) {
      if (!currentPage.startsWith(pages[p].page)) {
        for (let l = 0; l < pages[p].links.length; ++l) {
          value = ProcessLink(value, pages[p].page, pages[p].links[l]);
        }
      }
    }

    return value;
  }

  function ProcessLink(value, page, link) {
    if (typeof value !== "string") {
      return value;
    }

    const index = value.indexOf(link.name);
    if (index === -1) {
      return value;
    }

    const url =
      page === "external"
        ? `${link.url}`
        : link.tag
        ? `${page}#${link.tag}`
        : `${page}`;

    const target = page === "external" ? { target: "_blank" } : null;

    return (
      <>
        {ProcessString(value.slice(0, index))}
        <Link href={url} {...target}>
          {link.name}
        </Link>
        {ProcessString(value.slice(index + link.name.length, value.length))}
      </>
    );
  }

  return Process(props.children);
}

export default Processor;
