import React from "react";
import styled from "styled-components";
import { ClickIcon } from "../../Components";
import { ConfigureMenu, Container, Text } from "./_Include";
import MexicoCity2018 from "./MexicoCity2018";
import LosAngeles2019 from "./LosAngeles2019";
import Monterrey2019 from "./Monterrey2019";
import NewYork2019 from "./NewYork2019";
import LosAngeles2020 from "./LosAngeles2020";
import LosAngeles2022 from "./LosAngeles2022";
import SantaAna2022 from "./SantaAna2022";
import MexicoCity2022 from "./MexicoCity2022";

const Trips = styled.ul`
  list-style: none;
`;

const Trip = styled.li``;

const Title = styled.div`
  font-size: 1.4rem;
  margin: 2rem;
  cursor: pointer;

  &:hover {
    color: var(--highColor);
  }
`;

const InPhotos = (state) => {
  const toggleDisplay = (name) => {
    if (state.trip === name) {
      state.setTrip(null);
    } else {
      state.setTrip(name);
    }
  };

  return (
    <>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          I've seen The Warning play 13 live shows and was especially privileged
          to be present at a private show and a recording session.
        </Text>
        <Text>
          During these trips, I've taken over 3000 photos and I want to share
          the stories behind some of them.
        </Text>
        <Text>
          Make sure to click on each photo to see it full size and get
          additional information, and you can swipe left and right (use left and
          right cursors on a PC) to move between the photos.
        </Text>
        <Trips>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("MexicoCity2018")}>
                Mexico City - Nov 2018
              </Title>
            </ClickIcon>
            <MexicoCity2018 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("LosAngeles2019")}>
                Los Angeles - Jan 2019
              </Title>
            </ClickIcon>
            <LosAngeles2019 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("Monterrey2019")}>
                Monterrey - Jun 2019
              </Title>
            </ClickIcon>
            <Monterrey2019 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("NewYork2019")}>
                New York - Dec 2019
              </Title>
            </ClickIcon>
            <NewYork2019 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("LosAngeles2020")}>
                Los Angeles - Jan 2020
              </Title>
            </ClickIcon>
            <LosAngeles2020 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("LosAngeles2022")}>
                Los Angeles - May 2022
              </Title>
            </ClickIcon>
            <LosAngeles2022 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("SantaAna2022")}>
                Santa Ana - May 2022
              </Title>
            </ClickIcon>
            <SantaAna2022 {...state} />
          </Trip>
          <Trip>
            <ClickIcon mode="Text">
              <Title onClick={() => toggleDisplay("MexicoCity2022")}>
                Mexico City - Aug 2022
              </Title>
            </ClickIcon>
            <MexicoCity2022 {...state} />
          </Trip>
        </Trips>
      </Container>
    </>
  );
};

export default InPhotos;
