import React from "react";
import styled from "styled-components";
import { Container } from "../../GlobalStyle";
import { Age, Hr, Photo, Popup, Processor, YouTube } from "../../Components";
import { ConfigureMenu } from "./_Include";

const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
  justify-content: center;
  font-size: 1.5rem;
`;

const Logo = styled.img`
  height: 100px;
  margin-right: 1rem;
`;

const Sisters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Sister = styled.div`
  align-self: center;
  flex-grow: 1;
  text-align: center;
  margin: 0.9rem;
`;

const SisterPhoto = styled(Photo)`
  margin: auto;
  margin-bottom: 1rem;
  width: 360px;
  height: 240px;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    width: 300px;
    height: 200px;
  }
`;

const Text = styled.div`
  margin: 1rem 0 1rem 0;
  font-size: 1.2rem;
`;

const SisterText = styled.div``;

const TheWarning = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Title>
          <Logo
            src={
              state.darkMode
                ? "/img/tw-logo-white.png"
                : "/img/tw-logo-black.png"
            }
          />
          is a three-sister rock band from Monterrey Mexico.
        </Title>
        <Sisters>
          <Sister>
            <SisterPhoto
              {...state}
              id="dany"
              group="Sisters"
              smallSrc="/img/15metropolitan2022/2022082952411.jpg"
              largeSrc="/img/15metropolitan2022/2022082952412.jpg"
              alt="Dany"
            />
            <SisterText>
              Daniela (<Popup popup="dany">Dany</Popup>) is{" "}
              <Age birthday="30 Jan 2000" />
            </SisterText>
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              id="pau"
              group="Sisters"
              smallSrc="/img/11troubadour2022/2022052329311.jpg"
              largeSrc="/img/11troubadour2022/2022052329312.jpg"
              alt="Pau"
            />
            <SisterText>
              Paulina (<Popup popup="pau">Pau</Popup>) is{" "}
              <Age birthday="05 Feb 2002" />
            </SisterText>
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              id="ale"
              group="Sisters"
              smallSrc="/img/15metropolitan2022/2022082946411.jpg"
              largeSrc="/img/15metropolitan2022/2022082946412.jpg"
              alt="Ale"
            />
            <SisterText>
              Alejandra (<Popup popup="ale">Ale</Popup>) is{" "}
              <Age birthday="13 Dec 2004" />
            </SisterText>
          </Sister>
        </Sisters>
        <Text>
          It was while attending a summer music camp at a very young age that
          Dany and Pau developed their passion for music. They started taking
          piano lessons, but it was a few years later when they started playing
          Rock Band that they identified with the instruments that they would
          become known for with Dany always picking the guitar and Pau the
          drums. As the youngest Ale could only watch on, but a few years later
          Ale would start with piano lessons and after a brief fascination with
          the harp Ale picked the bass as her instrument of choice.
        </Text>
        <Text>
          A few years later after recording covers of Muse's Hysteria and Lady
          Gaga's Born This Way The Warning was born.
        </Text>
        <Text>
          They started out recording covers and uploading them to YouTube so
          that their family could see how they were progressing, but it wasn't
          long before the rest of the world started to take notice.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/1boUYB9LFJY"
          title="Enter Sandman - METALLICA Cover - The Warning"
          float="left"
          margin="0 2rem 0 0"
        />
        <Text>
          Their most famous cover is of Metallica's Enter Sandman which went
          viral and has racked up over 20 million views, garnering the attention
          of none other than Kirk Hammett himself who tweeted "The drummer kicks
          maximum ass!".
        </Text>
        <Text>
          It also brought them to the attention of Ellen DeGeneres who invited
          them to play on her show and made a very generous donation which
          allowed them to attend a summer program at the Berklee College of
          Music.
        </Text>
        <Text>
          All three have a grounding in classical music but it's from their
          parents that they inherited their love of classic rock.
        </Text>
        <Text>
          They can all sing although Dany is the lead singer for the majority of
          their songs with Ale and Pau providing backing vocals. Although
          increasingly Pau will take the lead from behind her drum kit, which is
          no easy feat and she also takes the lead vocals for a few ballads
          where she leaves the drums and plays the piano.
        </Text>
        <Text>
          Ale has always been the shy quiet one of the three and has resisted
          taking the lead until during their performance at Agua Fria she sang
          River's Soul, much to the delight of the audience. Although this
          wasn't the last time, most notably during their show in Tijuana when
          Dany had a throat infection Ale sang Hunter and Red Hands Never Fade,
          with Pau singing additional songs to give Dany a break.
        </Text>
        <Hr />
        <Text>
          Until recently The Warning was an independent band, raising
          much-needed funds through playing live and their album sales, but in
          March 2018 they launched their Patreon site which is a subscription
          site with multiple tiers providing rewards at each tier.
        </Text>
        <Text>
          Patreon, other than providing a mechanism for The Warning to connect
          with their fans, provides a steady and somewhat predictable income
          stream that allows them to invest in themselves, improving their
          equipment and allowing them to take advantage of unique opportunities
          as they arise.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/0LVNfh22FXk"
          title="1st night opening for The Killers in Arena Monterrey"
          float="right"
          margin="0 0 0 0"
        />
        <Text>
          An example of which is while they were in LA recording songs for Queen
          Of The Murder Scene they had the opportunity to open for The Killers
          for two nights back in their home town of Monterrey, they used money
          raised from Patreon to pay for the flights from LA to Monterrey and
          back again.
        </Text>
        <Text>
          The video of the first night is one of my favourites, their
          performance was exceptional, and while Pau injured herself while
          playing on the first night, she kept on playing and was faultless.
          Unfortunately, they had technical issues with the video of the second
          night which they have said was better as they'd overcome their initial
          nervousness and were a lot more relaxed.
        </Text>
        <Text>
          Other than Patreon they have run two incredibly successful Kickstarter
          campaigns to fund the second album, Queen Of The Murder Scene and
          their newly released third album ERROR.
        </Text>
        <Text>
          On the 12th August 2020, The Warning announced that they had signed
          with Lava Records, a subsidiary of Republic Records and Universal
          Music.
        </Text>
        <Text>
          For some of their fans, signing to a record label proved a daunting
          prospect as they have so far managed incredible things while being
          independent which shows that the team they've built up around them can
          deliver results. But they and their incredible management team spent
          seven months negotiating the terms of the Lava deal and I have no
          doubt that they will achieve even greater things together.
        </Text>
      </Container>
    </Processor>
  );
};

export default TheWarning;
