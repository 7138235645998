import React from "react";
import styled from "styled-components";

export const Break = styled.div`
  display: flex;
  justify-content: center;
  clear: both;
  width: 100%;
  margin: 1rem auto 1rem auto;

  span {
    color: darkred;
    display: inline-block;
    font-family: "icomoon" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2rem;
  }

  p {
    color: darkred;
    display: inline-block;
    font-size: 2rem;
  }

  div {
    display: inline-block;
    flex-grow: 1;
    background-color: #a00;
    height: 0.2rem;
    align-self: center;
    margin: auto 1rem auto 1rem;
  }
`;

function Hr(props) {
  if (props.id) {
    return (
      <Break id={props.id}>
        <HrBody {...props} />
      </Break>
    );
  }

  return (
    <Break>
      <HrBody {...props} />
    </Break>
  );
}

function HrBody(props) {
  if (props.title) {
    return (
      <>
        <span>&#xe900;</span>
        <div />
        <p>{props.title}</p>
        <div />
        <span>&#xe900;</span>
      </>
    );
  }

  return (
    <>
      <span>&#xe900;</span>
      <div />
      <span>&#xe900;</span>
      <div />
      <span>&#xe900;</span>
    </>
  );
}

export default Hr;
