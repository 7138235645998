import { useState } from "react";
import { GetSettings } from "./Api/Common";

export default function State() {
  const [photo, setPhoto] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [menu, setMenu] = useState(null);
  const [showDialog, setShowDialog] = useState(null);

  const [darkMode, setDarkModeState] = useState(
    localStorage.getItem("darkMode") !== "false"
  );

  const setDarkMode = (darkMode) => {
    setDarkModeState(darkMode);
    localStorage.setItem("darkMode", darkMode);
  };

  const [authToken, setAuthToken] = useState(
    sessionStorage.getItem("authToken")
  );

  const [userPhoto, setUserPhoto] = useState(null);
  const [user, setUserState] = useState(null);

  const setUser = (user) => {
    if (user === null) {
      sessionStorage.removeItem("authToken");
      setAuthToken(null);
    } else {
      if (user.AuthToken) {
        sessionStorage.setItem("authToken", user.AuthToken);
        setAuthToken(user.AuthToken);
      }

      setUserPhoto(user.Photo?.Path);
    }

    setUserState(user);
  };

  const [trip, setTrip] = useState(null);

  const [photos, setPhotos] = useState({});

  return {
    settings: GetSettings(),
    showYouTube: true,
    photo,
    setPhoto,
    scrollPosition,
    setScrollPosition,
    menu,
    setMenu,
    showDialog,
    setShowDialog,
    darkMode,
    setDarkMode,
    authToken,
    userPhoto,
    user,
    setUser,
    trip,
    setTrip,
    photos,
    setPhotos,
  };
}
