import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const MercuryLounge04 = (state) => {
  return (
    <>
      <Container>
        <Title>Mercury Lounge - 4th Dec 2019</Title>
        <Text>
          The first night was an incredible show, but I realised that I really
          missed being right at the front. So for the second night, I decided I
          would queue for three hours in the freezing cold to ensure it. Not
          only was it worth it to enable me to get great photos, but The Warning
          project so much energy that you're bombarded with it being that close.
          This show was also incredibly special because in my opinion it was the
          best show they've played and Jason Flom, founder of Lava Records was
          there.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120448591.jpg"
            largeSrc="/img/08newyork2019/2019120448592.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 25600"
          >
            <Text>
              There was an annoying light behind Ale from where I was stood
              which made it difficult to get good photos, and even though I was
              zoomed in a bit too much and chopped off the top of her head I
              still love this photo.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120448641.jpg"
            largeSrc="/img/08newyork2019/2019120448642.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 25600"
          >
            <Text>
              A combination of a small stage and good lighting meant I could get
              some great photos of Pau.
            </Text>
            <Text>
              Here Pau was playing a rented drum kit almost identical to the one
              they bought for their upcoming US tour.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120449001.jpg"
            largeSrc="/img/08newyork2019/2019120449002.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 17mm f2.8 1/500s ISO 16600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120449081.jpg"
            largeSrc="/img/08newyork2019/2019120449082.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 17mm f2.8 1/500s ISO 25600"
          >
            <Text>
              Dany always loves to gee up the crowd, she loves to see us
              animated and feeds off our energy.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120449111.jpg"
            largeSrc="/img/08newyork2019/2019120449112.jpg"
            camera="Mercury Lounge, New York - 04th Dec 2019 - Canon EOS 200D 17mm f2.8 1/500s ISO 19740"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120450251.jpg"
            largeSrc="/img/08newyork2019/2019120450252.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 52mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120450321.jpg"
            largeSrc="/img/08newyork2019/2019120450322.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 31mm f2.8 1/500s ISO 25600"
          >
            <Text>
              Pau singing the start of Survive, taking an opportunity to reach
              out to the fans.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120450971.jpg"
            largeSrc="/img/08newyork2019/2019120450972.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 28mm f2.8 1/500s ISO 25600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120451241.jpg"
            largeSrc="/img/08newyork2019/2019120451242.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 49mm f2.8 1/500s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120451401.jpg"
            largeSrc="/img/08newyork2019/2019120451402.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 44mm f2.8 1/500s ISO 12800"
          >
            <Text>
              This is my favourite photo of Dany, I love the way the light
              catches her hair and The Warning flag is just off her shoulder in
              the background.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120451921.jpg"
            largeSrc="/img/08newyork2019/2019120451922.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 50mm f1.8 1/500s ISO 25600"
          >
            <Text>Pau looking fierce as she tends to when she's playing.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/08newyork2019/2019120452201.jpg"
            largeSrc="/img/08newyork2019/2019120452202.jpg"
            camera="Mercury Lounge, New York - 4th Dec 2019 - Canon EOS 200D 50mm f1.8 1/500s ISO 19740"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default MercuryLounge04;
