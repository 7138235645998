import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../GlobalStyle";
import { FaBars, FaTimes } from "react-icons/fa";
import { CgDarkMode } from "react-icons/cg";
import { IconContext } from "react-icons/lib";
import Authenticate from "./Authenticate";
import MainMenu from "./MainMenu";

const Nav = styled.nav`
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 990;
  padding: 2rem;
  background-color: var(--backColor);
`;

const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: var(--headerHeight);
`;

const NavbarItem = styled.div`
  display: flex;
`;

const NavLogo = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  font-family: "Oswald";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 0.5ch;
  color: var(--foreColor);

  @media screen and (max-width: 960px) {
    font-size: 1.6rem;
    letter-spacing: 0.3ch;
  }
`;

const NavIcon = styled.img`
  margin-right: 1rem;
  padding: 0.5rem 0;
  height: var(--headerHeight);
`;

const HamburgerIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
  }
`;

const Settings = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DarkModeIcon = styled(CgDarkMode)`
  color: var(--foreColor);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--highColor);
    }
  }
`;

const Navbar = (state) => {
  const resetMenu = () => state.setMenu(null);

  return (
    <>
      <IconContext.Provider value={{ size: 25 }}>
        <Nav>
          <NavbarContainer>
            <NavbarItem>
              <NavLogo to="/" onClick={resetMenu}>
                <NavIcon src={"/img/tw-lightning-bolt.png"} />
                The Warned
              </NavLogo>
              <MainMenu {...state} />
            </NavbarItem>
            <NavbarItem>
              <Hamburger {...state} />
              <Settings>
                <DarkModeIcon
                  onClick={() => {
                    state.setDarkMode(!state.darkMode);
                  }}
                />
                <Authenticate {...state} />
              </Settings>
            </NavbarItem>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

const Hamburger = (state) => {
  const handleClick = () =>
    state.setShowDialog(state.showDialog === null ? "MainMenu" : null);

  if (state.menu) {
    return (
      <>
        <HamburgerIcon onClick={handleClick}>
          {state.showDialog === "MainMenu" ? <FaTimes /> : <FaBars />}
        </HamburgerIcon>
      </>
    );
  }

  return null;
};

export default Navbar;
