import React from "react";
import { Container } from "../../GlobalStyle";
import { Break, Hr, Processor, YouTube } from "../../Components";
import {
  ConfigureMenu,
  Text,
  PhotoCenter,
  PhotoLeftSmall,
  PhotoRightSmall,
  PhotoLeft,
  PhotoRight,
  Sisters,
  Sister,
  SisterPhoto,
} from "./_Include";

function MyStory2019(state) {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <Container>
        <Text>
          On 21st January 2019, I flew 5,500 miles from the UK to Los Angeles to
          attend what turned out to be for the second time the greatest concert
          of my life.
        </Text>
        <Text>
          Unlike Mexico where I travelled alone and knew no one when I arrived,
          this time I was travelling with a friend (admittedly one I'd not
          actually met until I got to the airport) but I also knew I'd be
          meeting people there I'd met in Mexico. On the Monday I met Paul at
          Heathrow and we boarded the plane to LAX.
        </Text>
        <PhotoLeftSmall
          {...state}
          src="/img/mystory/2019/los_angeles/IMAG0357.jpg"
          alt="LA"
        />
        <Text>
          We were staying at a Motel 6 which was pretty basic, but it was clean
          and also cheap over the next couple of days more people turned up and
          I think there was a total of 14 of us were staying there. We would
          meet for breakfast at one of the two diners within a minutes walk
          which typically took most of the morning as we were never there that
          early and they never seemed in any rush to get rid of us.
        </Text>
        <PhotoRightSmall
          {...state}
          src="/img/mystory/2019/los_angeles/IMAG0360.jpg"
          alt="Union Station"
        />
        <Text>
          On Tuesday us brits and the three Swedes caught a train into Union
          Station and went for a wander around, although LA is not a place you
          walk around as we saw that Sunset Boulevard wasn't far so decided to
          head in that direction only when we got there we realised that all the
          interesting sites were at the other end, 10 miles away.
        </Text>
        <Break />
        <Text>
          Wednesday was the first of the big events of the trip, a BBQ at
          Henry's house.
        </Text>
        <Sisters>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2019/los_angeles/2019012311841.jpg"
              largeSrc="/img/mystory/2019/los_angeles/2019012311842.jpg"
              alt="Jim and Henry"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2019/los_angeles/2019012312171.jpg"
              largeSrc="/img/mystory/2019/los_angeles/2019012312172.jpg"
              alt="BBQ"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2019/los_angeles/2019012311811.jpg"
              largeSrc="/img/mystory/2019/los_angeles/2019012311812.jpg"
              alt="Ribs"
            />
          </Sister>
        </Sisters>
        <Text>
          It was my first time sampling Henry's cooking and from everything I'd
          heard it was going to be incredible. It didn't disappoint, the
          burgers, chicken, ribs and various nibbles with dips were beautiful
          and there was plenty more if only I hadn't been stuffed.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012311891.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012311892.jpg"
          alt="Triple Triggers"
        />
        <Text>
          Not only did I get to meet even more of the TWA but I also got to
          eventually meet Triple Triggers Band after many months of talking to
          them online and helping them by giving them drum backing tracks.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012311821.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012311822.jpg"
          alt="Henry's BBQ"
        />
        <Break />
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012412481.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012412482.jpg"
          alt="The Warning"
        />
        <Text>
          Eventually, Thursday comes along and once again I was able to attend
          the soundcheck and we were waiting outside the Whisky when The Warning
          arrived.
        </Text>
        <Text>
          They greeted every one of us personally with a few words and a hug and
          I managed to get a couple of really good photos.
        </Text>
        <Text>
          The soundcheck was incredible as always, although unlike at Lunario
          they didn't play any songs for the first time, but they still managed
          to delight the very small crowd (due to the policy of the Whisky there
          could only be 10 of us attend the soundcheck unless we paid for
          additional security) with a little taste of Crimson Queen and a full
          performance of Hunter and Dust To Dust.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012412531.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012412532.jpg"
          alt="The Warning"
        />
        <Text>
          Watching them play when they're not actually performing is quite an
          honour as they are so relaxed, even Pau who usually puts more energy
          into her playing than someone running a marathon was relaxed and
          played perfectly with what looked like very little effort. Ale as
          always is the queen of cool, her playing effortless and so relaxed you
          could believe she could play in her sleep. Dany took her time making
          sure each of her guitars sounded as good as it could and her vocals
          were incredible, hearing her belt out Crimson Queen with so few people
          felt like such an intimate performance.
        </Text>
        <PhotoCenter
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012413961.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012413962.jpg"
          alt="Meet and Greet"
        />
        <Text>
          Unlike Lunario where they did the meet and greet after the show, this
          time they did it before the show. As I was already inside for the
          soundcheck I was second in line to meet them and got my ticket signed.
          I'd already picked up a couple of hoodies and the poster from the BBQ
          and while I really wanted one of the t-shirts they were selling (as it
          was a design I didn't already own) they didn't have one in my size. So
          I didn't get anything else from their merch stand to get signed,
          although I think due to the excitement I wasn't thinking clearly as I
          really should have got another CD signed as not only could I have got
          it signed by Dany, Pau and Ale but also Gretel Joffroy was there who
          created a lot of the art for Queen Of The Murder Scene.
        </Text>
        <PhotoCenter
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012420771.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012420772.jpg"
          alt="The Warning"
        />
        <Text>
          Time for the show itself and I had got a spot right at the front of
          the stage. As with Lunario Ale started the show with her bass intro to
          Dust To Dust and from then on I was in a state of pure ecstasy. It's
          been over a week since that night and I still can't really come up
          with the words to describe just how amazing it was. I took a lot of
          photo's and a few videos but mostly I just enjoyed the performance
        </Text>
        <Sisters>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/mystory/2019/los_angeles/2019012419991.jpg"
              largeSrc="/img/mystory/2019/los_angeles/2019012419992.jpg"
              alt="Ale"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/02whisky2019/2019012414361.jpg"
              largeSrc="/img/02whisky2019/2019012414362.jpg"
              alt="Pau"
            />
          </Sister>
          <Sister>
            <SisterPhoto
              {...state}
              smallSrc="/img/02whisky2019/2019012414341.jpg"
              largeSrc="/img/02whisky2019/2019012414342.jpg"
              alt="Dany"
            />
          </Sister>
        </Sisters>
        <Text>
          At the end of the show, Dany handed me one of her picks and Pau had
          been handing out drum sticks, but I noticed one had been dropped on
          the stage so I waited while Carlos their tec was packing up Dany's
          guitars and he spotted the stick and saw me eying it up and handed it
          to me. After the show I was one of the honoured few that got invited
          to join The Warning at the Rainbow Bar and Grill which is just a block
          up from the Whisky and another iconic rock venue. While I was there I
          told them how much I'd enjoyed the show and Pau signed my drum stick.
          In contrast to Lunario where they were clearly exhausted they seemed a
          lot more energetic, although I suspect it was because the adrenaline
          hadn't worn off yet.
        </Text>
        <PhotoCenter
          {...state}
          smallSrc="/img/mystory/2019/los_angeles/2019012420451.jpg"
          largeSrc="/img/mystory/2019/los_angeles/2019012420452.jpg"
          alt="The Warning"
        />
        <Text>
          I never thought it would be possible for anything to beat the
          experience I had at Lunario. It was the first time seeing The Warning
          play live, the first time meeting them and Dany handed me her Ovation
          at the end of the show. Even with all that the show at the Whisky was
          better, the energy was more intense and they played Black Holes which
          they didn't at Lunario. I think the simple fact is that The Warning
          just gets better with every show.
        </Text>
      </Container>
      <Container>
        <Hr title="Monterrey" />
        <Text>
          On 12th June 2019 I made the long trip to Mexico for the second time,
          only this time I was heading to Monterrey, the home town of The
          Warning for their show at Foro DiDi.
        </Text>
        <PhotoLeftSmall
          {...state}
          src="/img/mystory/2019/monterrey/IMG_20190613_091618.jpg"
          alt="Foro DiDi"
          width="400"
          height="420"
        />
        <Text>
          I arrived in Mexico City where I met up with Kent who'd flown in from
          Sweden and we got the same flight to Monterrey, landing around 8 am.
          We dropped our bags at the hotel and took the opportunity to explore.
          First stop was to find Foro DiDi and although Rudy was going to put me
          on the guestlist I wanted to buy a ticket. Foro DiDi itself is located
          inside a large building which includes some shops, restaurants, a
          cinema and offices, so we hung around there for a bit until the box
          office opened.
        </Text>
        <Text>
          While we were waiting we checked online and saw that Christian was
          also in Monterrey, he was staying at a different hotel from us but not
          far away so he came over to join us and we got our tickets then
          grabbed a bite to eat. After lunch we decided to take a look around
          Monterrey and went for a wander, stopping in one of the museums
          detailing the history of Monterrey and Nuevo León which fortunately
          for us had the descriptions in both Spanish and English. After a
          while, we headed back to the hotel to freshen up before we headed out
          again for one of the big highlights of the trip.
        </Text>
        <PhotoRightSmall
          {...state}
          src="/img/mystory/2019/monterrey/carne_asada.jpg"
          alt="Foro DiDi"
          width="600"
          height="306"
        >
          Carne Asada &#169; 2019 Tim Little
        </PhotoRightSmall>
        <Text>
          Every TW concert I attend I meet so many really nice people and this
          time was no different. The night before Foro DiDi the local TWA
          invited us to join them for carne asada. I'd met the two Ana's and
          Néstor before at Lunario and it was so nice to see them again and to
          meet all the others, most of which I knew from various social media
          and everyone was so welcoming to us visitors.
        </Text>
        <PhotoLeftSmall
          {...state}
          src="/img/mystory/2019/monterrey/twa.jpg"
          alt="The Warning Army"
          width="600"
          height="345"
        >
          The Warning Army &#169; 2019 Ana Villaseñor
        </PhotoLeftSmall>
        <Text>
          Now I'd heard great things about carne asada (I think the literal
          translation is barbequed meat) but words do not do it justice. It was
          probably the nicest thing I've ever eaten. It's basically thinly
          cooked steak, with some special seasoning and cooked to perfection.
        </Text>
        <Text>
          On top of that, I tried some local beers and we ended the night with a
          sing-a-long of some of our favourite songs. Luis turned up and
          pretended that he was sick of hearing TW songs but we knew he loved
          it.
        </Text>
        <Break />
        <Text>
          The big day itself arrived and after a late breakfast, we made our way
          to Foro DiDi for the soundcheck. Being a patron I get to attend the
          soundchecks and they are always interesting. This one was particularly
          good as they played Narcisista for the very first time.
        </Text>
        <Text>
          Having only just heard the studio version the night before (another
          perk of being a patron is we got to hear it before its official
          release date) I knew it was an awesome song, but as always with The
          Warning the live version is so much better.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/monterrey/2019061429901.jpg"
          largeSrc="/img/mystory/2019/monterrey/2019061429902.jpg"
          alt="The Warning"
        />
        <Text>
          Rudy asked us where we wanted to be during the show, next to where the
          soundboard was would be where the best sound would be, but right at
          the front is where I wanted to be. Rudy said that he would let us in
          first so that we didn't have to join the main queue which was
          fantastic and unlike Lunario where I was slightly on Dany's side this
          time I got the perfect spot on Ale's side.
        </Text>
        <Text>
          The show itself was just spectacular, Ale opened it with her now
          familiar extended intro to Dust To Dust and throughout the night they
          played little bits of music in-between songs which tied the songs
          together and just made the entire night that extra bit more special.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/yIfot-LLySc"
          title="The Warning - Narcisista - Foro DiDi"
          float="left"
        />
        <Text>
          The show was sold out and the energy of the crowd was electric,
          singing along to all the songs including a group of girls who seemed
          to know all the lyrics to Narcisista, although I found out later that
          they were Pau's friends and also had early access to it.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/monterrey/2019061426501.jpg"
          largeSrc="/img/mystory/2019/monterrey/2019061426502.jpg"
          alt="The Warning"
        />
        <Text>
          Inevitably it seems there were some technical issues. The first of
          which was that during Crimson Queen Dany's Ovation which sounded fine
          during the soundcheck was making a really bad sound during the show.
          Dany said later that they thought it had been left plugged in after
          the soundcheck which had drained its battery, but as always Dany
          improvised and just stopped playing it, grabbed her microphone from
          its stand and sang her heart out. With Crimson Queen the magic of that
          song is always Dany's vocals so the lack of the guitar really didn't
          hurt the song and Dany's performance was incredible.
        </Text>
        <Text>
          The other issue was that during Stalker the microphone that is over
          the digital piano didn't work, so Dany once again grabbed her
          microphone and got her guitar tec Tebo to hold it for her while she
          played the intro. The tricky part came when she would move from the
          piano back to her normal mic to continue the song, but fortunately,
          Tebo understood what the plan was and walked the microphone back to
          her stand while she was singing as at that point she had started
          playing the guitar and didn't have her hands free.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/monterrey/2019061430271.jpg"
          largeSrc="/img/mystory/2019/monterrey/2019061430272.jpg"
          alt="The Warning"
        />
        <Text>
          The way they all handle these issues when something goes wrong is a
          testament to their professionalism and determination to put on the
          best show regardless of what life can throw at them. There's never any
          panic, just quick thinking and they improvise a way out of the
          problem.
        </Text>
        <PhotoLeftSmall
          {...state}
          src="/img/mystory/2019/monterrey/IMG_20190615_003912.jpg"
          alt="The Warning and I"
        />
        <Text>
          Unlike a more traditional show the support band Element played after
          The Warning, this was because of their show the following day The
          Warning didn't want to be up too late. Element is a covers band and
          for this show played songs that had influenced The Warning. They are
          incredibly good and there's a private balcony where during the start
          of their performance I could see Dany singing along. But about 20
          minutes in The Warning started their meet and greet which was just
          outside the venue itself and so I left to get a photo with them.
        </Text>
        <Break />
        <YouTube
          src="https://www.youtube.com/embed/2kTtTR2Yo6Y"
          title="Element - Bohemian Rhapsody - Papá Sobre Ruedas"
          float="left"
        />
        <Text>
          Saturday came around and after some sightseeing, we made our way to
          Papá Sobre Ruedas which was a car show that The Warning was
          headlining.
        </Text>
        <Text>
          The day was incredibly hot and so after a look around at the cars, we
          got some food and took shelter from the sun in a large marque.
          Fortunately, we got a bit of cloud cover so we wandered over to check
          out the current band, they were a Foo Fighters cover band and pretty
          good although I don't recall their name. Then Element played and put
          on an awesome show, finishing with a superb version of Bohemian
          Rhapsody.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/monterrey/2019061531331.jpg"
          largeSrc="/img/mystory/2019/monterrey/2019061531332.jpg"
          alt="The Warning"
        />
        <Text>
          Then once again The Warning took to the stage and they were
          magnificent. I'd got a spot right at the front again but the stage was
          much higher to provide a good view for everyone with Pau's drum riser
          even higher still which meant getting good photos was difficult but
          that's always secondary to the music, which was spot on.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/monterrey/2019061534611.jpg"
          largeSrc="/img/mystory/2019/monterrey/2019061534612.jpg"
          alt="The Warning"
        />
        <Text>
          Earlier in the day, I'd been talking to Rudy and he said he was going
          to try and get us in front of the barrier but it might not be
          possible, he'd have to get the OK from the show's security. I didn't
          think any more about it but about halfway through the show Rudy
          rounded us up and we were allowed up front. I'd had a pretty good view
          already, but the main benefit of being in front of the barrier is the
          ability to move about, so not stuck in just one place I managed to get
          some better photo's and it was just an awesome show.
        </Text>
        <Text>
          At the end of the show, The Warning came across to the barrier at the
          side of the stage and met with some fans and took photos with them,
          but once they were done they went to a marque behind the stage and we
          were invited to join them for a special meet and greet. We sat and
          talked with them for what was probably half an hour, talking about
          both shows and their recent vacation in Canada. It was a true honour
          to get to spend that time with them.
        </Text>
        <Break />
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/monterrey/2019061635001.jpg"
          largeSrc="/img/mystory/2019/monterrey/2019061635002.jpg"
          alt="The Warning"
        />
        <Text>
          Eventually, Sunday came along and with it what was to be the greatest
          day of my life. Watching them play live is truly incredible, I love
          their music and live they are simply the best, but I'd been invited to
          their home, to their basement for a private show with only a handful
          of other patrons.
        </Text>
        <Text>
          We met up with Rudy at a hotel where he drove us in a mini-bus to
          their house, making sure to emphasise that this isn't just their place
          of work but their home and we need to be respectful of that.
        </Text>
        <Text>
          We entered their house into the room where they can be seen recording
          some of their vlogs and where they play rock band and also have their
          new music writing workstation (super nice setup). We sat and chatted
          for a while and then they signed various items we'd brought with us. I
          had a few items including the sheet music for Dull Knives on bass that
          I'd transcribed from the tutorial that Ale had provided me and I got
          Ale to sign it which was totally cool.
        </Text>
        <Text>
          I also had got some of my Lunario photos printed and had brought them
          with me to get signed but the gold and silver sharpies I'd brought
          wouldn't write very well on the gloss prints so they ended up singing
          with a fine point black sharpie which you can see but doesn't show up
          as well as I would have liked, but is entirely my own fault. Maybe
          next time I see them I'll get them printed again and make sure I take
          a suitable pen.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/s589vw3Ht1E"
          title="Hannah Montana - Best of both worlds Drums 2009 Pau (7)"
          float="left"
        />
        <Text>
          Then we moved next door into The Warning Cave itself... this is where
          it began, where the very first video on their YouTube site was
          recorded nearly 10 years ago. Where they recorded Enter Sandman which
          is the video that gained them so many fans. It really is a special
          place.
        </Text>
        <Text>
          They started off playing Narcisista which was fantastic, this was the
          fourth time I'd heard it live and it just got better and better. They
          followed it with Unmendable, P.S.Y.C.H.O.T.I.C. and Queen Of The
          Murder Scene then they asked me and Thomas if we wanted to play with
          them.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/6n8ZpSYoSE4"
          title="The Warning - Copper Bullets - With guest drummer"
          float="right"
        />
        <Text>
          This is something I've dreamed about, something I always hoped would
          happen and I'll be honest I wasn't surprised when it did. Now I don't
          say that in some arrogant way that "obviously they were going to let
          me play with them", but knowing them like I do I know how much they
          love and value their fans and they are incredibly generous and they
          knew how much it would mean to us.
        </Text>
        <Text>
          They asked me which song I wanted to play and I immediately said
          Copper Bullets. This was an easy choice because I wanted a song that
          was fun to play and that Pau could sing as I wouldn't want her to be
          left out, I wanted to play with all three of them.
        </Text>
        <Text>
          I was surprisingly calm, a little bit nervous because I'd not played
          on a real drum kit for about 10 years, only my electronic kit. Also, I
          was going to play a song written by Pau, on Pau's drumkit while she
          was watching. It didn't help that Pau's only 5'0" whereas I'm 5'10" so
          the setup wasn't ideal for me, but with all that I was ready...almost.
          I had a slight panic moment when I couldn't remember what tempo it was
          played at, and in the end, I played it a little bit slower than they
          usually do but I was quite pleased with my own performance.
        </Text>
        <Text>
          The star however was Pau. Not being constrained by her drum kit she
          was free to dance around and the best bit was when the murder happens,
          usually, the backing track plays the dialogue, the sirens and the
          gun-shots, but we were playing without the backing track and so Pau
          acted out the whole scene and it was a moment of pure awesomeness.
        </Text>
        <Text>
          It's impossible to properly describe how incredible an experience that
          was, as an amateur musician to get to play with your favourite band
          one of your favourite songs and you could tell from the look on their
          faces that they enjoyed it as much as I did.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/Hw4r6o1wCXM"
          title='The Warning - Hunter - Live from "The Warning Cave" (with guest fan/bassist)'
          float="left"
        />
        <Text>
          Then it was Thomas's turn and when they asked him which song he wanted
          to play he gave them three to pick from (I can't remember all three)
          but I think it was Dany who picked Hunter with the suggestion that Ale
          should sing it like she had in the Patreon anniversary show.
        </Text>
        <Text>
          Ale herself didn't seem quite as keen but was truly magnificent as she
          always is. I don't know exactly why she doesn't like singing the lead
          vocal as she has no trouble with backing vocals and has an amazing
          voice. I hope one day she'll fully overcome whatever it is holding her
          back as she really is spectacularly good.
        </Text>
        <Text>
          But it's probably also worth saying that Thomas was incredibly good,
          so good in fact that Pau said at the end "That was great, if I had
          closed my eyes I would have not told the difference." which is high
          praise indeed.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/0ed4c7b4cyI"
          title="The Warning - Survive - With guest guitarist"
          float="right"
        />
        <Text>
          After that, they asked for any requests and Steve requested Free
          Falling which they said they hadn't played in a long time. So long in
          fact that Dany forgot how to play the solo and ended up signing part
          of it which was really funny.
        </Text>
        <Text>
          They then played Sinister Smiles and Wildfire before inviting Néstor
          to play Survive with them. Néstor is an incredibly talented guitarist
          and did an awesome job while Dany just sang and danced, although while
          recording the iconic ending I got a bit caught up focusing on Pau and
          missed Néstors big finish.
        </Text>
        <Text>
          To end the session off with they played Narcisista one more time
          because Thomas had arrived late and had yet to see it live. He
          couldn't make either of the shows and had flown in specifically for
          this visit to The Warning Cave.
        </Text>
        <Text>
          We then moved back into the other room where we chatted a bit more
          before it was time to say goodbye. I can't thank everyone enough, Luis
          and Mony for inviting us into their home and being the perfect hosts,
          Rudy for organising it and most importantly Dany, Pau and Ale for
          being just the most amazing people I've ever met. Also a very very
          special thank you to Pau for letting me play her drums.
        </Text>
        <Text>
          We left and Rudy dropped us back at the hotel where Kent and I got a
          taxi to the airport and so ended what was truly the greatest trip of
          my life.
        </Text>
        <br />
        <PhotoCenter
          {...state}
          src="/img/mystory/2019/monterrey/warning_cave.jpg"
          alt="The Warning Patrons"
          showDescription="true"
        >
          Patrons in The Warning Cave &#169; 2019 "THE WARNING"
        </PhotoCenter>
      </Container>
      <Container>
        <Hr title="New York" />
        <Text>
          On 1st December 2019 I made the now-familiar journey to the airport
          and boarded a plane to New York. I was heading off to see two shows at
          the Mercury Lounge where The Warning was headlining plus an extra
          bonus of going to see them record Narcisista in the studio.
        </Text>
        <PhotoRightSmall
          {...state}
          src="/img/mystory/2019/newyork/CloosBasment.jpg"
          alt="Art + Allie Cloos Basement"
        >
          Art + Allie Cloos Basement &#169; 2019 Art Cloos
        </PhotoRightSmall>
        <Text>
          After arriving at JFK airport I had a wait as Kent's flight was due in
          30 minutes later and we were going to share a taxi to the hotel. While
          I was waiting I was online and Art Cloos messaged me to ask if Kent
          and I wanted to go to theirs and get something to eat. Obviously, I
          said yes and once Kent landed and cleared customs we got a taxi to Art
          and Alice's house.
        </Text>
        <PhotoLeftSmall
          {...state}
          src="/img/mystory/2019/newyork/Dinner.jpg"
          alt="Dinner with Art + Allie + Kent"
        >
          Dinner in Queens, New York &#169; 2019 Art Cloos
        </PhotoLeftSmall>
        <Text>
          After a chat and a tour of their basement (which is incredible), we
          went for food at a small Italian place. Forgetting that American
          portions are typically larger than in the UK and with still adjusting
          to the time difference I didn't manage to eat much of what was an
          incredibly nice pasta dish, although in fairness there was enough to
          feed three people.
        </Text>
        <Break />
        <Text>
          Skipping Monday which was cold and wet and it snowed, eventually,
          Tuesday came along. After a late breakfast at a small place just
          around the corner from the hotel Kent and I headed into Manhattan
          again and did a bit of sightseeing before heading down to the Mercury
          Lounge. We met a few more fans at a nearby deli before getting to the
          Mercury Lounge at the agreed time of 2 pm.
        </Text>
        <Text>
          Unsurprisingly (I've been to enough to know they almost never start on
          time) they were running a bit late, and while normally I'd be happy
          waiting outside the venue, it was freezing cold so we headed off to a
          bar just across the road. When we returned we found Rudy outside and
          after greeting us all he let us in.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/wfcPpKnLE3U"
          title="The Warning - Dust To Dust - Mercury Lounge Soundcheck - New York"
          float="right"
        />
        <Text>
          The girls were already on stage getting set up and as per usual we
          stood back out of the way and just took in all that was going on.
          After the usual individual instrument checks and microphone checks,
          they played a few songs to get everything fine-tuned. Although as Luis
          once told me they are so efficient now at the soundcheck they often
          play a few more songs than they need to for the fans who are present.
        </Text>
        <Text>
          This soundcheck was a little bit different though as it was also the
          first time that The Warning would have a chance to practice Narcisista
          with Jay de la Cueva who was going to be a guest performer on that
          song. So they actually ran through Narcisista three times, I recorded
          one time as video and then used the rest of the time to take some
          photos.
        </Text>
        <PhotoLeft
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120343761.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120343762.jpg"
          alt="Dany"
        />
        <Text>
          After the soundcheck was over the girls came over to say hello to
          everyone, at this point I was standing right by the stage and Dany
          walked straight over to me. It's amazing to me that this would be the
          seventh time meeting them and it was just as exciting as the first. It
          also still amazes me that when they're stood on stage they have this
          huge presence, even at a soundcheck, but when they come off stage
          you're reminded of how small they are.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120343921.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120343922.jpg"
          alt="The Warning and I"
        />
        <Text>
          After talking for a bit and getting some photos they had to go get
          some food and then get ready for the show. We also had to get food and
          after much discussion decided on a small European place just up the
          road. Rather than heading straight back to join the queue to ensure a
          front-row spot; because it was so cold outside we decided to stay in
          the warmth of the restaurant until doors open then wander down.
        </Text>
        <Break />
        <Text>
          We got into the venue and immediately saw Rudy manning the merch
          stand. I was a bit disappointed that they didn't have any Narcisista
          t-shirts as that's one of the designs I don't yet own and really want.
          But they did have a new design and I was going to just pick one up on
          the way out as they had plenty but then saw they had a few hoodies
          left and Paul had asked me if I could get him one, I asked Rudy if
          they had any large left and there was just the one, so I got it then
          along with the t-shirt but fortunately, Rudy said he would leave it
          behind the merch stand for me so I didn't have to worry about holding
          onto it throughout the show.
        </Text>
        <Text>
          The support act Devon came on and played a more bluesy style of music,
          Devon herself plays the guitar and sings accompanied by her brother on
          guitar and a bassist and drummer. They were quite good (actually the
          drummer I thought was really good) and I would go so them again if
          they happened to be playing somewhere nearby.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120346001.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120346002.jpg"
          alt="The Warning"
        />
        <Text>
          Eventually, the time came and The Warning took the stage. As I was
          stood further back than I'm used to, having been on the front for
          every time I'd seen them so far, it was quite a different experience.
          The sound is definitely better when you're actually listening to the
          PA whereas at the front you quite often hear a lot of Ale's or Dany's
          amps directly. You also get to see the reaction of the rest of the
          crowd and there was a lot of energy in the crowd that night. The stage
          in the Mercury Lounge isn't very high so being far back meant I didn't
          get a very good view but by holding my camera above my head I still
          managed to get a few good photos, and at the end of the day I could
          hear the show really well and they sounded so good.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120345601.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120345602.jpg"
          alt="Ale with Thomas"
        />
        <Text>
          As usual it wouldn't be a The Warning show without some technical
          difficulties, and for the third or fourth time now Ale was mid-song
          and the strap came off her bass. Coincidentally it was during Sinister
          Smiles just as she was singing "Break, break, just break apart". Well
          in fact it wasn't actually her bass but it belonged to Thomas who is a
          super fan who had loaned her two of his basses so they didn't have to
          bring Ale's and they could save a bit of money on travel costs. Ale
          had managed to catch it before it hit the stage and knelt down so she
          could continue playing with it resting on her leg. Thomas had also
          offered to act as her bass tech for the shows which they had gladly
          accepted and so he was quick to act, grabbing a stool that was at the
          side of the stage so Ale could sit down and play a bit more
          comfortably, he also managed to adjust her microphone just in time for
          her all-important falsetto during the chorus.
        </Text>
        <PhotoLeft
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120347041.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120347042.jpg"
          alt="Dany"
        />
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120347461.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120347462.jpg"
          alt="The Warning and I"
        />
        <Text>
          After the show I was very surprised when Luis told me Pau hadn't been
          feeling well throughout the entire show, her playing and singing was
          impeccable although later she said it was the reason why they didn't
          play The End which was on the playlist.
        </Text>
        <Break />
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120447611.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120447612.jpg"
          alt="Dany"
        />
        <Text>
          Wednesday morning and I couldn't sleep in as long as I might like as I
          had to get to Leesta Vall Sound Recordings in Brooklyn to witness
          something very special. The Warning was going to record 20
          individually dedicated versions of Narcisista direct to vinyl and I
          was one of only four fans who would be there to witness it. As it
          happens I was the only one of the four of us to have actually bought
          one, although that was more because they sold out incredibly quickly.
          I have to admit I was surprised how quickly they all sold (about an
          hour if I remember correctly) because they weren't cheap. They were
          offered to patrons first and then would have been offered to a wider
          audience if they hadn't all been snapped up.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120447931.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120447932.jpg"
          alt="Pau"
        />
        <Text>
          When I arrived the girls were in the studio getting set up and Randy
          was already there in the control room watching. Shortly after Steve
          and Thomas arrived and it wasn't much longer before they were ready to
          record the first one. Aaron who runs the studio had a list of all the
          orders and when they were about to start Pau asked "what's the first
          name" and Dany immediately replied "Matthew" so I got the very first
          recording. Although technically I got the second and third recordings
          as just as they set the first one going Pau shouted out they weren't
          getting their click track. So they reset and just before Dany's solo,
          she managed to turn off her guitar. Third time lucky and I ended up
          with a unique double-sided recording.
        </Text>
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120447951.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120447952.jpg"
          alt="Ale"
        />
        <Text>
          The rest of the recordings went without any technical issues, although
          during one Pau forgot the lyrics for part of the song they decided to
          continue as that will be a unique version unlike any other. Although
          the entire purpose of this is that it captures the rawness of a live
          recording in pure analogue and each recording is a little bit
          different. One noticeable difference between the start and the end of
          the session was that on this day Dany wasn't feeling too well and so
          stopped recording her backing vocals on the later takes to save her
          voice for the show that night. After the final recording, they took a
          few publicity photos and we got a group photo and then we headed out
          to get food.
        </Text>
        <Text>
          There was a lunch meet up planned for a group of fans which I'm glad I
          didn't miss, although I was a little bit late, Art (who organised it)
          understood. It was fun meeting up with everyone and afterwards, a
          group of us headed off back to the Mercury Lounge for the second night
          show.
        </Text>
        <Break />
        <PhotoLeftSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120449111.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120449112.jpg"
          alt="Dany"
        />
        <Text>
          After the first night where I didn't get to be right up front, I
          decided that wouldn't happen the second night, so I queued for three
          hours in the freezing cold to ensure I was right there. I ended up
          standing right in front of Dany and that made everything OK.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120448641.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120448642.jpg"
          alt="Pau"
        />
        <Text>
          The second night was also special as we knew that there were going to
          be record company execs at one of the shows and Rudy confirmed it was
          the second night. Which worked out well because the energy was more
          intense, The Warning played with no technical issues and I was jumping
          around like a teenager.
        </Text>
        <YouTube
          src="https://www.youtube.com/embed/cgK3DzJl7qI"
          title="The Warning - Red Hands Never Fade - Mercury Lounge 4th Dec 2019"
          float="left"
        />
        <Text>
          I was really surprised that Ale sang Red Hands Never Fade and at first
          wondered if it was to show their diversity and highlight the fact that
          all three of them can take the lead vocal, but it was a bit later that
          Dany said she wasn't feeling well, but like Pau you really couldn't
          tell because she performed flawlessly.
        </Text>
        <PhotoRightSmall
          {...state}
          smallSrc="/img/mystory/2019/newyork/2019120451001.jpg"
          largeSrc="/img/mystory/2019/newyork/2019120451002.jpg"
          alt="Ale"
        />
        <Text>
          As always with their shows it was mostly a blur, I remember just the
          feeling of exhilaration and intense pleasure and it was over in a
          flash.
        </Text>
        <Text>
          After the show, I got another photo with the girls and then Kent and I
          went outside to wait for our Uber, but while we were waiting for Rudy
          came outside and we chatted with him for a bit. He was very happy
          about how everything had gone and told us about the meeting the next
          day with Lava Records, our Uber arrived and we headed back to the
          hotel.
        </Text>
        <Break />
        <PhotoLeft
          {...state}
          src="/img/mystory/2019/newyork/81234310_1019830965063333_8863371833768935424_n.jpg"
          alt="Luis, Pau and Dany in Time Square"
        >
          The Warning in Time Square &#169; 2019 "THE WARNING"
        </PhotoLeft>
        <PhotoRight
          {...state}
          src="/img/mystory/2019/newyork/80882511_552950981926434_4408046603718361088_n.jpg"
          alt="Kent, Carol and I in Time Square"
        >
          Kent, Carol and I in Time Square &#169; 2019 Carol Mantovani
        </PhotoRight>
        <Text>
          On our final full day in New York, we decided to go see the 9/11
          memorial which is an incredible place and like most people I can
          remember exactly where I was when I first heard about what happened.
          Afterwards, we met up with Carol and went for something to eat and
          while we were eating The Warning had responded to a message from Carol
          with a photo which was taken just a few minutes walk from where we
          were. After we'd left the restaurant we decided to try to recreate
          their photo (we got it sort of right) and had a general wander around
          Times Square and Rockefeller Plaza but this time it was dark and we
          got the full effect of all the lights.
        </Text>
        <Text>
          The next morning we packed up to leave the hotel, the night before I'd
          messaged Rudy to ask what time they were leaving, as I knew they were
          also flying home that day. It turned out they were going to be at the
          airport early afternoon, and while it was a bit earlier than we needed
          to be there we had nothing better to do and had to be out of the hotel
          by 11 am so decided to head to the airport to see them off.
        </Text>
        <PhotoRight
          {...state}
          src="/img/mystory/2019/newyork/IMG_20191207_155059_480.jpg"
          alt="The Warning at airport with Carol, Kent and I"
        >
          The Warning with Carol, Kent and I &#169; 2019 "THE WARNING"
        </PhotoRight>
        <Text>
          Kent and I arrived in plenty of time and Carol (who wasn't flying home
          that day but wanted to see them off as well) turned up just as they
          did. We looked after their carry on luggage while they checked in and
          asked Rudy how the previous days meeting had gone, although he
          wouldn't tell us very much (which is understandable) we also took the
          opportunity to ask about some of their plans for the future. When the
          Villarreal family returned we got to chat a little bit and take a few
          photos before they had to head through security to allow them time to
          eat before they boarded the plane.
        </Text>
        <Text>
          Kent, Carol and I ate in a diner in the airport and then headed our
          separate ways, Kent and I were flying from different terminals and
          Carol was heading back into the city and after what seemed an
          interminable wait I eventually boarded my own plane home.
        </Text>
      </Container>
    </Processor>
  );
}

export default MyStory2019;
