import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const ForoDiDi = (state) => {
  return (
    <>
      <Container>
        <Title>Foro DiDi - 14th Jun 2019</Title>
        <Text>
          Foro DiDi was the first of three shows I saw during my first trip to
          The Warnings home town of Monterrey Mexico.
        </Text>
        <Text>
          I, along with the other patrons of The Warning, was allowed into the
          venue before everyone else which provided us with the opportunity to
          pick our ideal spots to see the show. For me, I decided to go a bit
          left of centre which put me in front of Ale but also with a clear line
          of sight to Pau without her drums getting in the way (too much). This
          also provided a different perspective from Lunario and The Whisky
          where I was slightly right of centre and more in front of Dany.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061424641.jpg"
            largeSrc="/img/03forodidi2019/2019061424642.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 6400"
          >
            <Text>Dany takes a break while Pau soundchecks</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061425441.jpg"
            largeSrc="/img/03forodidi2019/2019061425442.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 48mm f2.8 1/400s ISO 1600"
          >
            <Text>Ale talking to the sound guy during the soundcheck</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061425591.jpg"
            largeSrc="/img/03forodidi2019/2019061425592.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 55mm f2.8 1/400s ISO 3200"
          >
            <Text>Pau clearly having fun during the soundcheck</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061425711.jpg"
            largeSrc="/img/03forodidi2019/2019061425712.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 6400"
          >
            <Text>
              This was a special moment for one of my fellow patrons as they
              sing Happy Birthday to him.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061426021.jpg"
            largeSrc="/img/03forodidi2019/2019061426022.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 1600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061426381.jpg"
            largeSrc="/img/03forodidi2019/2019061426382.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 33mm f2.8 1/500s ISO 3200"
          >
            <Text>
              Dany had technical issues with her Ovation during Crimson Queen,
              it was believed that it had been left plugged after the soundcheck
              and the battery had gone flat.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061426441.jpg"
            largeSrc="/img/03forodidi2019/2019061426442.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 38mm f2.8 1/500s ISO 3200"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061427711.jpg"
            largeSrc="/img/03forodidi2019/2019061427712.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 28mm f2.8 1/500s ISO 1600"
          >
            <Text>Dany always puts so much passion into her solos.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061427961.jpg"
            largeSrc="/img/03forodidi2019/2019061427962.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 24mm f2.8 1/500s ISO 3200"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061429251.jpg"
            largeSrc="/img/03forodidi2019/2019061429252.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 3200"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061429581.jpg"
            largeSrc="/img/03forodidi2019/2019061429582.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 55mm f2.8 1/500s ISO 1600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061429641.jpg"
            largeSrc="/img/03forodidi2019/2019061429642.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 40mm f2.8 1/500s ISO 1600"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061430011.jpg"
            largeSrc="/img/03forodidi2019/2019061430012.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 17mm f2.8 1/500s ISO 1600"
          >
            <Text>
              This photo was taken at the end of Survive, Ale actually stood on
              the sub-woofer at the front of the stage while she played her
              iconic ending. I really love the fact that while she was stood
              right in front of me I still managed to get Pau and Dany in shot.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/03forodidi2019/2019061430131.jpg"
            largeSrc="/img/03forodidi2019/2019061430132.jpg"
            camera="Foro DiDi, Monterrey - 14th Jun 2019 - Canon EOS 200D 33mm f2.8 1/500s ISO 1600"
          >
            <Text>Now that's what I call a Sinister Smile</Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default ForoDiDi;
