import React from "react";
import styled from "styled-components";
import { DialogContainer } from "../GlobalStyle";
import { ApiChangePassword } from "../Api/Authenticate";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Fieldset = styled.fieldset`
  min-width: 300px;
  max-width: 400px;
  margin: 10rem auto;
  background-color: var(--dialogColor);
`;

const Label = styled.label`
  display: block;
  margin: 1rem 1rem 0.2rem 1rem;
`;

const Input = styled.input`
  display: block;
  margin: 0 1rem 1rem 1rem;
  padding: 0.2rem;
  width: calc(100% - 2rem);
`;

const Button = styled.input`
  display: inline-block;
  margin-left: 1rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
`;

const Error = styled.div`
  display: block;
  margin: 1rem;
  color: var(--errorColor);
  font-weight: bold;
`;

const ChangePassword = (state) => {
  const Validate = (e, state) => {
    if (e.keyCode === 13) {
      const oldPassword = document.getElementById("oldPassword").value;
      const newPassword = document.getElementById("newPassword").value;
      const confirmPassword = document.getElementById("confirmPassword").value;
      if (
        oldPassword.length !== 0 &&
        newPassword.length !== 0 &&
        confirmPassword.length !== 0
      ) {
        ChangePasswordOK(state);
      }
    }
  };

  const Cancel = (state) => {
    state.setShowDialog(null);
  };

  const ChangePasswordOK = (state) => {
    const oldPassword = document.getElementById("oldPassword").value;
    const newPassword = document.getElementById("newPassword").value;
    const confirmPassword = document.getElementById("confirmPassword").value;

    if (newPassword !== confirmPassword) {
      document.getElementById("credentialError").innerText =
        "Passwords not the same.";
    } else {
      ApiChangePassword({
        Id: state.user.Id,
        OldPassword: oldPassword,
        NewPassword: newPassword,
      }).then((user) => {
        if (typeof user === "number") {
          const credentialError = document.getElementById("credentialError");
          if (user === 401) {
            credentialError.innerText = "Invalid credentials.";
          } else {
            credentialError.innerText = "Unable to change password: " + user;
          }
        } else {
          state.setShowDialog(null);
        }
      });
    }
  };

  const visible = state.showDialog === "ChangePassword";
  if (!visible) {
    return null;
  }

  return (
    <DialogContainer direction="right" visible={visible}>
      <Container>
        <Fieldset>
          <Label>Old Password</Label>
          <Input
            id="oldPassword"
            type="password"
            onKeyDown={(e) => Validate(e, state)}
          />
          <Label>New Password</Label>
          <Input
            id="newPassword"
            type="password"
            onKeyDown={(e) => Validate(e, state)}
          />
          <Label>Confirm Password</Label>
          <Input
            id="confirmPassword"
            type="password"
            onKeyDown={(e) => Validate(e, state)}
          />
          <Button type="button" value="Cancel" onClick={() => Cancel(state)} />
          <Button
            type="button"
            value="OK"
            onClick={() => ChangePasswordOK(state)}
          />
          <Error id="credentialError" />
        </Fieldset>
      </Container>
    </DialogContainer>
  );
};

export default ChangePassword;
