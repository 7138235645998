import React from "react";

function Age(props) {
  function Age() {
    const birthday = new Date(props.birthday);
    return Math.abs(
      new Date(Date.now() - birthday.getTime()).getUTCFullYear() - 1970
    );
  }

  return <>{Age()}</>;
}

export default Age;
