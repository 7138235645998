import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DialogContainer } from "../GlobalStyle";

const MenuContainer = styled.span`
  display: flex;
  align-items: center;
  float: left;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;

  @media screen and (max-width: 960px) {
    display: block;
    margin-top: var(--headerHeight);
  }
`;

const NavItem = styled.li`
  height: var(--menuHeight);

  @media screen and (max-width: 960px) {
    width: 100%;
    max-width: 400px;
    height: var(--headerHeight);

    &:hover {
      border: none;
    }
  }
`;

const NavLink = styled(Link)`
  color: var(--foreColor);
  text-decoration: none;
  font-size: 1.6rem;
  padding-left: 2rem;
  height: 100%;

  &:hover {
    color: var(--highColor);
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
`;

const MainMenu = (state) => {
  const handleClick = () =>
    state.setShowDialog(state.showDialog === null ? "MainMenu" : null);

  const visible = state.menu && state.showDialog === "MainMenu";
  if (!visible) {
    return (
      <MenuContainer>
        <NavMenu>
          <MenuItems {...state} />
        </NavMenu>
      </MenuContainer>
    );
  }

  return (
    <DialogContainer direction="left" visible={visible} onClick={handleClick}>
      <NavMenu>
        <MenuItems {...state} />
      </NavMenu>
    </DialogContainer>
  );
};

const MenuItems = (state) => {
  switch (state.menu) {
    case "TheWarning":
      return <MenuItemsTheWarning />;

    case "MyStory":
      return <MenuItemsMyStory />;

    default:
      return null;
  }
};

const MenuItemsTheWarning = () => {
  return (
    <>
      <NavItem>
        <NavLink to="/the-warning">The Warning</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/the-warning/discography">Discography</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/the-warning/social-media">Social Media</NavLink>
      </NavItem>
    </>
  );
};

const MenuItemsMyStory = () => {
  return (
    <>
      <NavItem>
        <NavLink to="/my-story/2017">2017</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/my-story/2018">2018</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/my-story/2019">2019</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/my-story/2020">2020</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/my-story/2021">2021</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/my-story/2022">2022</NavLink>
      </NavItem>
    </>
  );
};

export default MainMenu;
