import React from "react";
import {
  TripContainer,
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "./_Include";

const SantaAna2022 = (state) => {
  return (
    <TripContainer visible={state.trip === "SantaAna2022"}>
      <Container>
        <Title>Santa Ana - 26th May 2022</Title>
        <Text>
          For Santa Ana I had never intended to take my camera so that I could
          fully enjoy the show, but I did manage to get a few half decent photos
          on my phone.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/13santaana2022/202205262239591.jpg"
            largeSrc="/img/13santaana2022/202205262239592.jpg"
            camera="Constellation Room, Santa Ana - 26th May 2022 - OnePlus 6T 4mm f1.7 1/50s ISO 800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/13santaana2022/202205262249241.jpg"
            largeSrc="/img/13santaana2022/202205262249242.jpg"
            camera="Constellation Room, Santa Ana - 26th May 2022 - OnePlus 6T 4mm f1.7 1/35s ISO 400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/13santaana2022/202205262304431.jpg"
            largeSrc="/img/13santaana2022/202205262304432.jpg"
            camera="Constellation Room, Santa Ana - 26th May 2022 - OnePlus 6T 4mm f1.7 1/50s ISO 320"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/13santaana2022/202205262304121.jpg"
            largeSrc="/img/13santaana2022/202205262304122.jpg"
            camera="Constellation Room, Santa Ana - 26th May 2022 - OnePlus 6T 4mm f1.7 1/50s ISO 250"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </TripContainer>
  );
};

export default SantaAna2022;
