import React from "react";
import styled from "styled-components";
import { DialogContainer } from "../GlobalStyle";
import { ApiAuthenticate } from "../Api/Authenticate";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Fieldset = styled.fieldset`
  min-width: 300px;
  max-width: 400px;
  margin: 10rem auto;
  background-color: var(--dialogColor);
`;

const Label = styled.label`
  display: block;
  margin: 1rem 1rem 0.2rem 1rem;
`;

const Input = styled.input`
  display: block;
  margin: 0 1rem 1rem 1rem;
  padding: 0.2rem;
  width: calc(100% - 2rem);
`;

const Button = styled.input`
  display: inline-block;
  margin-left: 1rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
`;

const Error = styled.div`
  display: block;
  margin: 1rem;
  color: var(--errorColor);
  font-weight: bold;
`;

const Logon = (state) => {
  const Validate = (e, state) => {
    if (e.keyCode === 13) {
      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;
      if (email.length !== 0 && password.length !== 0) {
        LogonOK(state);
      }
    }
  };

  const Cancel = (state) => {
    state.setShowDialog(null);
  };

  const LogonOK = (state) => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    ApiAuthenticate({
      Email: email,
      Password: password,
    }).then((user) => {
      if (typeof user === "number") {
        const credentialError = document.getElementById("credentialError");
        if (user === 401) {
          credentialError.innerText = "Invalid credentials.";
        } else {
          credentialError.innerText = "Unable to authenticate: " + user;
        }
      } else {
        state.setUser({ ...user, wait: new Date(Date.now() + 60000) });
        state.setShowDialog(null);
      }
    });
  };

  const visible = state.showDialog === "Logon";
  if (!visible) {
    return null;
  }

  return (
    <DialogContainer direction="right" visible={visible}>
      <Container>
        <Fieldset>
          <Label>Email</Label>
          <Input id="email" onKeyDown={(e) => Validate(e, state)} />
          <Label>Password</Label>
          <Input
            id="password"
            type="password"
            onKeyDown={(e) => Validate(e, state)}
          />
          <Button type="button" value="Cancel" onClick={() => Cancel(state)} />
          <Button type="button" value="OK" onClick={() => LogonOK(state)} />
          <Error id="credentialError" />
        </Fieldset>
      </Container>
    </DialogContainer>
  );
};

export default Logon;
