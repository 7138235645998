/*
import React from "react";

function screenDetails() {
  return {
    devicePixelRatio: window.devicePixelRatio,
    width: window.screen.width,
    height: window.screen.height,
    availWidth: window.screen.availWidth,
    availHeight: window.screen.availHeight,
  };
}

function render(thing) {
  return toArray(thing).map((o) => {
    return <div>{o}</div>;
  });
}

function toArray(thing) {
  let array = [];
  for (let o in thing) {
    array.push(o + " = " + thing[o]);
  }
  return array;
}
*/

const Debug = () => {
  return null;
  //return render(screenDetails());
};

export default Debug;
