import React from "react";
import { LyricsContainer, Processor } from "../../../Components";
import { Container } from "../../../GlobalStyle";
import {
  ConfigureMenu,
  Album,
  AlbumArt,
  AlbumText,
  Videos,
  Video,
} from "./_Include";

const EscapeTheMind = (state) => {
  return (
    <Processor>
      <ConfigureMenu {...state} />
      <LyricsContainer />
      <Container>
        <Album>
          <AlbumArt src="/img/tw/escape_the_mind.jpg" />
          <AlbumText>
            Escape The Mind was released in January 2015 and was their first
            foray into writing their own songs. As a result, it is a mix of
            styles and genres but shows that even at a very young age they could
            write some seriously good music.
          </AlbumText>
          <AlbumText>
            Dany, Pau and Ale were influenced by their parent's love of rock
            music and that shows with tracks like Take Me Down and Fade Away
            having a solid rock pedigree whereas Eternal Love has more of a
            country feel and highlights their experimentation with their early
            music.
          </AlbumText>
          <AlbumText>
            Escape The Mind is unusual as it's performed entirely by Pau, just
            piano and vocals and is has a distinct melancholy air to it while
            being quite beautiful.
          </AlbumText>
          <AlbumText>
            Free Falling was a clear sign of things to come, it's pure rock and
            very indicative of the style of music that they would continue with
            their album XXI Century Blood.
          </AlbumText>
          <AlbumText>
            Inspired by their early success and the fact that Rock Band had
            played a large part in their desire to learn to play Harmonix Music
            Systems included Free Falling in Rock Band 4.
          </AlbumText>
        </Album>
        <Videos>
          <Video
            id="takemedown"
            src="https://www.youtube.com/embed/rnUeJrHJNW8"
            title="Take Me Down"
            showLyrics="true"
          />
          <Video
            id="fadeaway"
            src="https://www.youtube.com/embed/Ehp9PKDlI5U"
            title="Fade Away"
            showLyrics="true"
          />
          <Video
            id="eternallove"
            src="https://www.youtube.com/embed/P6DC32_Q7ME"
            title="Eternal Love"
            showLyrics="true"
          />
          <Video
            id="escapethemind"
            src="https://www.youtube.com/embed/8fqvw0Pz_YI"
            title="Escape The Mind"
            showLyrics="true"
          />
          <Video
            id="freefalling"
            src="https://www.youtube.com/embed/wlipfTBaLII"
            title="Free Falling"
            showLyrics="true"
          />
        </Videos>
      </Container>
    </Processor>
  );
};

export default EscapeTheMind;
