import React from "react";
import { TripContainer, Text } from "../_Include";
import TeatroMetropólitan26 from "./TeatroMetropólitan26";
import TeatroMetropólitan29 from "./TeatroMetropólitan29";

const MexicoCity2022 = (state) => {
  return (
    <TripContainer visible={state.trip === "MexicoCity2022"}>
      <Text>
        Since my last trip to the US I'd got a new phone, the battery wasn't
        lasting very long on my old OnePlus and I thought I'd get something with
        a much better camera. I ended up getting a refurbished 12 Pro as I when
        I'd seen other people's photos and videos they'd taken using their
        phone, the best ones were typically taken on an iPhone.
      </Text>
      <Text>
        I also took my Canon EOS R with the RF 24-70mm f2.8 and RF 70-200mm f2.8
        lenses.
      </Text>
      <TeatroMetropólitan26 {...state} />
      <TeatroMetropólitan29 {...state} />
    </TripContainer>
  );
};

export default MexicoCity2022;
