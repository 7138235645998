import React from "react";
import styled from "styled-components";
import { BsFileText } from "react-icons/bs";

const SongContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SongText = styled.div`
  font-size: 1.2rem;
`;

const SongIcon = styled(BsFileText)`
  font-size: 1.6rem;
  margin-left: 1rem;
  cursor: pointer;

  &:hover {
    color: var(--highColor);
  }
`;

function Lyrics(props) {
  function Show(e, name) {
    const url = GetUrl(name);
    var request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        RenderText(this.responseText);
      }
    };

    request.open("GET", url, true);
    request.send();
  }

  function GetUrl(name) {
    let album = document.location.pathname;
    const index = album.lastIndexOf("/");
    album = album.slice(index);

    const song = props.song
      ? props.song
      : name.replace(/[ ']/g, "").toLowerCase();

    return `/lyrics${album}/${song}.txt`;
  }

  function RenderText(text) {
    var container = document.getElementById("lyricsContainer");
    container.style.display = "block";
    container.style.left = container.getBoundingClientRect().x + "px";
    container.style.position = "fixed";

    var lyrics = document.getElementById("lyricsText");
    lyrics.innerText = text;
  }

  const song = props.title;
  const lyrics = props.lyrics ?? props.title;

  return (
    <SongContainer>
      <SongText>{song}</SongText>
      <SongIcon onClick={(e) => Show(e, lyrics)} />
    </SongContainer>
  );
}

export default Lyrics;
