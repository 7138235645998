import React from "react";
import styled from "styled-components";
import { Container } from "../GlobalStyle";
import { Copyright } from "./";

const FooterContainer = styled(Container)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2rem;
  margin-top: 1rem;
`;

const FooterDisclaimer = styled.p`
  display: flex;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 0.7rem;
`;

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterDisclaimer>
          This site is not affiliated with The Warning, all opinions expressed
          on this site are my own. <br />
          The Warning's logo, album artwork and lyrics are used with permission{" "}
          <Copyright /> "THE WARNING".
          <br />
          All other content unless otherwise stated is <Copyright /> Matthew
          Fraser
        </FooterDisclaimer>
      </FooterContainer>
    </>
  );
};

export default Footer;
