import React from "react";
import styled from "styled-components";
import { Container as ParentContainer } from "../../GlobalStyle";
import { Image, YouTube } from "../../Components";
import { ConfigureMenu } from "./_Include";
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaPatreon,
  FaSpotify,
  FaTwitch,
} from "react-icons/fa";
import { SiTiktok } from "react-icons/si";

const Container = styled(ParentContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
`;

const Content = styled.div`
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin: auto;
  justify-content: center;
`;

const Text = styled.p`
  margin-bottom: 1rem;
  justify-content: left;
`;

const Items = styled.ul`
  list-style: none;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 0 1rem 1rem 1rem;
  font-size: 2rem;
`;

const Icon = styled.span`
  display: flex;
  margin-right: 1rem;
  align-items: middle;
`;

const Link = styled.a`
  color: var(--foreColor);
  text-decoration: none;

  &:hover {
    color: var(--highColor);
    transition: all 0.3s ease;
  }
`;

const SocialMedia = (state) => {
  return (
    <>
      <ConfigureMenu {...state} />
      <Container>
        <Content>
          <Text>
            The Warning, having essentially grown up on social media after
            gaining a lot of popularity from their viral cover of Enter Sandman,
            have put it to good use.
          </Text>
          <Text>
            They started uploading videos to YouTube so that their family could
            see their progress but as they grew as a band they expanded their
            social media horizons to maximise their exposure and reach as many
            fans as possible.
          </Text>
        </Content>
        <Content>
          <Items>
            <Item>
              <Icon>
                <Image
                  {...state}
                  src="/img/tw//thewarning.jpg"
                  alt="The Warning"
                  width="20"
                  height="20"
                />
              </Icon>
              <Link target="_blank" href="https://www.thewarningband.com">
                Official Website
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaYoutube />
              </Icon>
              <Link
                target="_blank"
                href="https://www.youtube.com/channel/UCeZHVOA5rP9rHoa7zKCx7kQ"
              >
                YouTube
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaFacebookF />
              </Icon>
              <Link
                target="_blank"
                href="https://www.facebook.com/TheWarningRockBand"
              >
                Facebook
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaInstagram />
              </Icon>
              <Link
                target="_blank"
                href="https://www.instagram.com/thewarningrockband"
              >
                Instagram - The Warning
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaInstagram />
              </Icon>
              <Link
                target="_blank"
                href="https://www.instagram.com/dany.thewarning"
              >
                Instagram - Dany
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaInstagram />
              </Icon>
              <Link
                target="_blank"
                href="https://www.instagram.com/paulina.thewarning"
              >
                Instagram - Pau
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaInstagram />
              </Icon>
              <Link
                target="_blank"
                href="https://www.instagram.com/alejandra.thewarning"
              >
                Instagram - Ale
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaTwitter />
              </Icon>
              <Link target="_blank" href="https://twitter.com/TheWarningBand2">
                Twitter
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaPatreon />
              </Icon>
              <Link target="_blank" href="https://www.patreon.com/TheWarning">
                Patreon
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaSpotify />
              </Icon>
              <Link
                target="_blank"
                href="https://open.spotify.com/artist/2SmW1lFlBJn4IfBzBZDlSh"
              >
                Spotify
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaTwitch />
              </Icon>
              <Link target="_blank" href="https://www.twitch.tv/danythewarning">
                Twitch - Dany
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaTwitch />
              </Icon>
              <Link target="_blank" href="https://www.twitch.tv/pauthewarning">
                Twitch - Pau
              </Link>
            </Item>
            <Item>
              <Icon>
                <FaTwitch />
              </Icon>
              <Link target="_blank" href="https://www.twitch.tv/alethewarning">
                Twitch - Ale
              </Link>
            </Item>
            <Item>
              <Icon>
                <SiTiktok />
              </Icon>
              <Link
                target="_blank"
                href="https://www.tiktok.com/@thewarningrockband"
              >
                TicTok - The Warning
              </Link>
            </Item>
            <Item>
              <Icon>
                <SiTiktok />
              </Icon>
              <Link
                target="_blank"
                href="https://www.tiktok.com/@danythewarning"
              >
                TicTok - Dany
              </Link>
            </Item>
            <Item>
              <Icon>
                <SiTiktok />
              </Icon>
              <Link
                target="_blank"
                href="https://www.tiktok.com/@paulina.thewarning"
              >
                TicTok - Pau
              </Link>
            </Item>
          </Items>
        </Content>
        <Content>
          <YouTube src="https://open.spotify.com/embed/playlist/4zBefAi7njUxCO3dTQWwYv"></YouTube>
        </Content>
      </Container>
    </>
  );
};

export default SocialMedia;
