import React from "react";
import Image from "./Image";
import ClickIcon from "./ClickIcon";
import styled from "styled-components";
import { AiOutlineCamera } from "react-icons/ai";

const PhotoContainer1 = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
`;

const PhotoContainer2 = styled.div`
  display: grid;
  place-items: center;
  object-fit: cover;

  img {
    max-width: 90vw;
    max-height: 60vh;
  }

  @media screen and (min-width: 400px) {
    margin: 1rem;
  }

  @media screen and (min-width: 950px) {
    img {
      max-height: 80vh;
    }
  }
`;

const PhotoDescription = styled.div`
  text-align: center;
  margin: auto;
  margin-top: 1em;
  padding: 0 1rem 0 1rem;
`;

const Camera = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0 2rem 0 2rem;
  justify-content: center;
`;

const CameraIcon = styled(AiOutlineCamera)`
  margin-right: 0.5rem;
  font-size: 2rem;
`;

const CameraText = styled.span``;

const Photo = (tempProps) => {
  const src = tempProps.src ?? tempProps.smallSrc;
  const img =
    src.match(/\/img\/[^/]+\/(?<img>\d+)\d\.jpg/)?.groups.img ??
    src.match(/\/img\/[^/]+\/(?<img>.+)\.jpg/)?.groups.img;

  let group = tempProps.group;
  if (typeof group === "undefined") {
    group = src.match(/\/img\/(?<group>[^/]+)\//)?.groups.group;
  }

  const props = { ...tempProps, group };

  if (!props.photos[img]) {
    props.photos[img] = props;
  }

  function RenderPhoto(props) {
    if (props.showFullSize) {
      if (props.width) delete props.width;
      if (props.height) delete props.height;
      if (props.camera) {
        return (
          <PhotoContainer1>
            <PhotoContainer2>
              <Image {...props} />
              <Camera>
                <CameraIcon />
                <CameraText>{props.camera}</CameraText>
              </Camera>
              {RenderDescription(props)}
            </PhotoContainer2>
          </PhotoContainer1>
        );
      }

      return (
        <PhotoContainer1>
          <PhotoContainer2>
            <Image {...props} />
            {RenderDescription(props)}
          </PhotoContainer2>
        </PhotoContainer1>
      );
    }

    if (props.showCamera && props.camera) {
      return (
        <PhotoContainer2>
          <ClickIcon mode="Photo">
            <Image {...props} />
          </ClickIcon>
          <Camera>
            <CameraIcon />
            <CameraText>{props.camera}</CameraText>
          </Camera>
          {RenderDescription(props)}
        </PhotoContainer2>
      );
    }

    if (props.showDescription) {
      return (
        <PhotoContainer2>
          <ClickIcon mode="Photo">
            <Image {...props} />
          </ClickIcon>
          {RenderDescription(props)}
        </PhotoContainer2>
      );
    }

    return (
      <ClickIcon mode="Photo">
        <Image {...props} />
      </ClickIcon>
    );
  }

  function RenderDescription(props) {
    if (props.children) {
      return <PhotoDescription>{props.children}</PhotoDescription>;
    }

    return null;
  }

  function GetSiblings(props, element) {
    let previousSibling = null;
    let nextSibling = null;
    let group = null;

    const src = element.src ?? element.smallSrc;
    const img =
      src.match(/\/img\/[^/]+\/(?<img>\d+)\d\.jpg/)?.groups.img ??
      src.match(/\/img\/[^/]+\/(?<img>.+)\.jpg/)?.groups.img;

    for (let photo in props.photos) {
      if (photo === img) {
        group = props.photos[photo].group;
      } else if (group === null) {
        previousSibling = props.photos[photo];
      } else if (group !== null && props.photos[photo].group === group) {
        nextSibling = props.photos[photo];
        break;
      }
    }

    if (
      previousSibling != null &&
      (group === null || previousSibling.group !== group)
    ) {
      previousSibling = null;
    }

    return { previousSibling, nextSibling };
  }

  function RenderFullSize(props, e) {
    const siblings = GetSiblings(props, e.target);
    if (siblings.previousSibling !== null || siblings.nextSibling !== null) {
      const keyDown = function (e) {
        if (e.keyCode === 27) {
          window.removeEventListener("keydown", keyDown);
          CloseFullSize();
        } else if (e.keyCode === 37 && siblings.previousSibling !== null) {
          window.removeEventListener("keydown", keyDown);
          RenderFullSize(
            {
              ...siblings.previousSibling,
            },
            { target: siblings.previousSibling }
          );
        } else if (e.keyCode === 39 && siblings.nextSibling !== null) {
          window.removeEventListener("keydown", keyDown);
          RenderFullSize(
            {
              ...siblings.nextSibling,
            },
            { target: siblings.nextSibling }
          );
        }
      };

      let touchPosition = null;
      const touchStart = function (e) {
        touchPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      };

      const touchMove = function (e) {
        if (touchPosition === null) {
          return;
        }

        const movePosition = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY,
        };

        const movement = {
          x: touchPosition.x - movePosition.x,
          y: touchPosition.y - movePosition.y,
        };

        if (Math.abs(movement.x) > Math.abs(movement.y)) {
          if (movement.x < 0 && siblings.previousSibling !== null) {
            window.removeEventListener("touchmove", touchMove);
            window.removeEventListener("touchstart", touchStart);
            RenderFullSize(
              {
                ...siblings.previousSibling,
              },
              { target: siblings.previousSibling }
            );
          } else if (movement.x > 0 && siblings.nextSibling !== null) {
            window.removeEventListener("touchmove", touchMove);
            window.removeEventListener("touchstart", touchStart);
            RenderFullSize(
              {
                ...siblings.nextSibling,
              },
              { target: siblings.nextSibling }
            );
          }
        }
      };

      window.addEventListener("keydown", keyDown);
      window.addEventListener("touchstart", touchStart, false);
      window.addEventListener("touchmove", touchMove, false);
    }

    props.setPhoto({
      ...props,
      showFullSize: true,
    });

    if (e.type === "click") {
      props.setScrollPosition({
        x: window.scrollX,
        y: window.scrollY,
      });
    }
  }

  function CloseFullSize() {
    props.setPhoto(null);
  }

  if (props.showFullSize) {
    let fullSizeProps = {
      onClick: () => CloseFullSize(),
      style: {
        display: "grid",
        placeItems: "center",
      },
    };

    return <div {...fullSizeProps}>{RenderPhoto({ ...props })}</div>;
  }

  if (props.showCamera) {
    return RenderPhoto({
      ...props,
    });
  }

  return RenderPhoto({
    ...props,
    onClick: (e) => RenderFullSize(props, e),
  });
};

export default Photo;
