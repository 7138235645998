import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const TheWarningCave = (state) => {
  return (
    <>
      <Container>
        <Title>The Warning Cave - 16th Jun 2019</Title>
        <Text>
          I was honoured to be allowed to visit The Warning in their 'cave'
          which is their studio in the basement of their home.
        </Text>
        <Text>
          I didn't take too many photos while I was there as during the times we
          were talking it never crossed my mind and while they were playing I
          recorded more video than I took photos.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/05thewarningcave2019/2019061634791.jpg"
            largeSrc="/img/05thewarningcave2019/2019061634792.jpg"
            camera="The Warning Cave, Monterrey - 16th Jun 2019 - Canon EOS 200D 45mm f2.8 1/250s ISO 12800"
          >
            <Text>
              Dany preparing for the private show, she'd lost one of her in-ears
              the night before and so had to wear headphones, which did fall off
              a few times.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/05thewarningcave2019/2019061634821.jpg"
            largeSrc="/img/05thewarningcave2019/2019061634822.jpg"
            camera="The Warning Cave, Monterrey - 16th Jun 2019 - Canon EOS 200D 23mm f2.8 1/250s ISO 12800"
          >
            <Text>
              Ale tuning her bass before they start to play with Pau in the
              background checking her headset mic.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/05thewarningcave2019/2019061634891.jpg"
            largeSrc="/img/05thewarningcave2019/2019061634892.jpg"
            camera="The Warning Cave, Monterrey - 16th Jun 2019 - Canon EOS 200D 55mm f2.8 1/250s ISO 12800"
          >
            <Text>
              Pau sat behind her incredibly nice DW kit which I was fortunate
              enough to be allowed to play.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default TheWarningCave;
