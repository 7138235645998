import React from "react";
import styled from "styled-components";
import { BsFileText } from "react-icons/bs";

const SongContainer = styled.div`
  display: flex;

  ul {
    list-style-type: none;
  }

  li {
    display: flex;
    align-content: center;
  }
`;

const SongText = styled.div`
  display: flex;
  align-content: center;
  font-variant: small-caps;
  font-size: 1.5rem;
`;

const SongIcon = styled(BsFileText)`
  display: flex;
  align-content: center;
  font-size: 2rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    color: var(--highColor);
  }
`;

function Song(props) {
  function Show(e, name) {
    const url = GetUrl(name);
    var request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        RenderText(this.responseText);
      }
    };

    request.open("GET", url, true);
    request.send();
  }

  function GetUrl(name) {
    let album = document.location.pathname;
    const index = album.lastIndexOf("/");
    album = album.slice(index);

    const song = props.song
      ? props.song
      : name.replace(/[ ']/g, "").toLowerCase();

    return `/lyrics${album}/${song}.txt`;
  }

  function RenderText(text) {
    var container = document.getElementById("lyricsContainer");
    container.style.display = "block";
    container.style.left = container.getBoundingClientRect().x + "px";
    container.style.position = "fixed";

    var lyrics = document.getElementById("lyricsText");
    lyrics.innerText = text;
  }

  if (props.name) {
    const song = props.name;
    return (
      <>
        <SongContainer>
          <SongText>{song}</SongText>
          <SongIcon onClick={(e) => Show(e, song)} />
        </SongContainer>
      </>
    );
  } else if (props.names) {
    const items = props.names.split(",").map((song) => (
      <li key={song}>
        <SongText>{song}</SongText>
        <SongIcon onClick={(e) => Show(e, song)} />
      </li>
    ));
    return (
      <>
        <SongContainer>
          <ul>{items}</ul>
        </SongContainer>
      </>
    );
  }

  return null;
}

export default Song;
