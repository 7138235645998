import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const Troubadour24 = (state) => {
  return (
    <>
      <Container>
        <Title>Troubadour - 24th May 2022</Title>
        <Text>
          On the second night I managed to get three decent photos using my
          camera. I'm especially glad I managed to get one with Alessia Cara as
          when she joined them on stage it was a huge surprise.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/12troubadour2022/202205242156511.jpg"
            largeSrc="/img/12troubadour2022/202205242156512.jpg"
            camera="Troubadour - 23rd May 2022 - OnePlus 6T 4mm f1.7 1/35s ISO 1250"
          >
            <Text>Dany talking to the crowd.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/12troubadour2022/202205242209531.jpg"
            largeSrc="/img/12troubadour2022/202205242209532.jpg"
            camera="Troubadour - 23rd May 2022 - OnePlus 6T 4mm f1.7 1/35s ISO 1000"
          >
            <Text>Pau during the intro to Survive.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/12troubadour2022/202205242230091.jpg"
            largeSrc="/img/12troubadour2022/202205242230092.jpg"
            camera="Troubadour - 23rd May 2022 - OnePlus 6T 4mm f1.7 1/50s ISO 320"
          >
            <Text>
              For the keen-eyed among you, you'll spot Alessia Cara between Pau
              and Dany, she had joined them for their performance of Enter
              Sandman which they had recorded together for the Metallica
              Blacklist project.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default Troubadour24;
