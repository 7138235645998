import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../GlobalStyle";

const MenuContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const Menu = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: 16rem 16rem;
  grid-row: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
`;

const MenuItem = styled(Link)`
  --height: 8rem;
  height: var(--height);
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const MenuIcon = styled.img`
  height: var(--height);
  margin-left: auto;
`;

const MenuText = styled.div`
  color: var(--foreColor);
  font-size: 2.4rem;

  &:hover {
    color: var(--highColor);
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 400px) {
    font-size: 2rem;
  }
`;

const HomeMenu = (state) => {
  return (
    <>
      <MenuContainer>
        <Menu>
          <MenuItem
            to="/the-warning"
            onClick={() => state.setMenu("TheWarning")}
          >
            <MenuIcon
              src={
                state.darkMode
                  ? "/img/tw-logo-white.png"
                  : "/img/tw-logo-black.png"
              }
            />
          </MenuItem>
          <MenuItem
            to="/the-warning"
            onClick={() => state.setMenu("TheWarning")}
          >
            <MenuText>The Warning</MenuText>
          </MenuItem>
          <MenuItem to="/in-photos" onClick={() => state.setMenu("InPhotos")}>
            <MenuIcon src={"/img/01lunario2018/2018112508221.jpg"} />
          </MenuItem>
          <MenuItem to="/in-photos" onClick={() => state.setMenu("InPhotos")}>
            <MenuText>In Photos</MenuText>
          </MenuItem>
          <MenuItem to="/my-story" onClick={() => state.setMenu("MyStory")}>
            <MenuIcon src={"/img/mystory/2019/newyork/2019120343921.jpg"} />
          </MenuItem>
          <MenuItem to="/my-story" onClick={() => state.setMenu("MyStory")}>
            <MenuText>My Story</MenuText>
          </MenuItem>
        </Menu>
      </MenuContainer>
    </>
  );
};

export default HomeMenu;
