import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const TeatroMetropólitan29 = (state) => {
  return (
    <>
      <Container>
        <Title>Teatro Metropólitan - 26th Aug 2022</Title>
        <Text>
          The second show and this time I had my Canon for both the sound check
          and the show itself, and I think it shows in the quality of the
          photos.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082939351.jpg"
            largeSrc="/img/15metropolitan2022/2022082939352.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 200mm f2.8 1/100s ISO 519"
          >
            <Text>
              During the sound check I had my 70 - 200mm lens on full zoom as we
              were sat half way back in the venue to avoid getting in anyone's
              way, but fortunately it was good enough to get some decent photos.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082939581.jpg"
            largeSrc="/img/15metropolitan2022/2022082939582.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 200mm f2.8 1/100s ISO 519"
          >
            <Text>
              Pau wearing her sun glasses during the sound check, which is a
              nessesity when the lights are still being setup and you can't
              easily move out of the way if one shines directly in your face.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082941241.jpg"
            largeSrc="/img/15metropolitan2022/2022082941242.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 177mm f2.8 1/40s ISO 2468"
          >
            <Text>
              For REVENANT their friend Pliego Villarreal joined them to play
              the acoustic guitar.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082942701.jpg"
            largeSrc="/img/15metropolitan2022/2022082942702.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 200mm f2.8 1/100s ISO 617"
          >
            <Text>
              During the show itself I stuck with my 70 - 200mm lens as it
              enabled me to get some really nice close ups.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082943351.jpg"
            largeSrc="/img/15metropolitan2022/2022082943352.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 142mm f2.8 1/100s ISO 130"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082943891.jpg"
            largeSrc="/img/15metropolitan2022/2022082943892.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 182mm f2.8 1/100s ISO 1600"
          >
            <Text>Dany always has so much energy during a show.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082945631.jpg"
            largeSrc="/img/15metropolitan2022/2022082945632.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 104mm f2.8 1/200s ISO 400"
          >
            <Text>
              I decided to stick with my 70 - 200mm lens throughout the show as
              it wasn't practical to change, so I was very happy when I got an
              opportunity to get Dany, Pau and Ale all in the same shot.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082946311.jpg"
            largeSrc="/img/15metropolitan2022/2022082946312.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 50mm f2.8 1/250s ISO 3200"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082946411.jpg"
            largeSrc="/img/15metropolitan2022/2022082946412.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 111mm f2.8 1/250s ISO 259"
          >
            <Text>
              Half way through the show they made a quick outfit change, with
              all three of them switching from black to white outfits.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082947791.jpg"
            largeSrc="/img/15metropolitan2022/2022082947792.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 163mm f2.8 1/100s ISO 259"
          >
            <Text>
              Dany playing REVENANT with a Dr Simi plushi on her lap. Dr Simi is
              the mascot of a chain of Mexican pharmaceutical stores and it
              became a thing recently for people to throw them on stage at
              concerts. The Warning ended up with about 20 by the end of the
              night.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082947891.jpg"
            largeSrc="/img/15metropolitan2022/2022082947892.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 135mm f2.8 1/100s ISO 259"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082948721.jpg"
            largeSrc="/img/15metropolitan2022/2022082948722.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 70mm f2.8 1/100s ISO 154"
          >
            <Text>
              The highlight of this show was that they got Pau's drum teacher
              Beto Ramos to play on Narcisista so that Pau could take the front
              of the stage and just sing. Not only was it an incredible
              performance but it provided an opportunity to get some incredible
              photos.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082948731.jpg"
            largeSrc="/img/15metropolitan2022/2022082948732.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 70mm f2.8 1/100s ISO 100"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082949421.jpg"
            largeSrc="/img/15metropolitan2022/2022082949422.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 70mm f2.8 1/200s ISO 259"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082950461.jpg"
            largeSrc="/img/15metropolitan2022/2022082950462.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 81mm f2.8 1/200s ISO 308"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082950651.jpg"
            largeSrc="/img/15metropolitan2022/2022082950652.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 81mm f2.8 1/200s ISO 308"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082950761.jpg"
            largeSrc="/img/15metropolitan2022/2022082950762.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 79mm f2.8 1/200s ISO 308"
          >
            <Text>Pau "encouraging" Dany during her guitar solo.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082951641.jpg"
            largeSrc="/img/15metropolitan2022/2022082951642.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 115mm f2.8 1/200s ISO 259"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082951981.jpg"
            largeSrc="/img/15metropolitan2022/2022082951982.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 95mm f2.8 1/200s ISO 130"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082952201.jpg"
            largeSrc="/img/15metropolitan2022/2022082952202.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 75mm f2.8 1/200s ISO 130"
          >
            <Text>
              I love it when Dany's hair goes flying and it catches the light.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082952521.jpg"
            largeSrc="/img/15metropolitan2022/2022082952522.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 75mm f2.8 1/200s ISO 130"
          >
            <Text>This is one of my favourite photos of this show.</Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082953761.jpg"
            largeSrc="/img/15metropolitan2022/2022082953762.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 70mm f2.8 1/200s ISO 6400"
          >
            <Text>
              The end of the show and one more opportunity to get all three in
              the same shot, and you can tell from their faces how happy they
              are.
            </Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/15metropolitan2022/2022082955551.jpg"
            largeSrc="/img/15metropolitan2022/2022082955552.jpg"
            camera="Teatro Metropólitan, Mexico City - 29th Aug 2022 - Canon EOS R 70mm f2.8 1/200s ISO 800"
          >
            <Text>
              A beautiful moment when Pau was overcome with emotion and burst
              into tears of happiness, comforted by Ale and Rudy.
            </Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default TeatroMetropólitan29;
