import React from "react";
import {
  Container,
  Title,
  Text,
  PhotoContainer1,
  PhotoContainer2,
  Photo,
} from "../_Include";

const MercuryLounge03 = (state) => {
  return (
    <>
      <Container>
        <Title>Mercury Lounge - 3rd Dec 2019</Title>
        <Text>
          For the first night show, for the first time, I wasn't at the front of
          the stage. It was incredibly cold in New York and I wasn't in the mood
          for queuing for hours to guarantee a front-row spot. As a result,
          while I did take a few photos I took the opportunity to really enjoy
          the show.
        </Text>
        <Text>
          Being a patron though meant I still got access to the soundcheck and
          did take a few photos, although the lighting was particularly
          unfavourable for most of the soundcheck so these were taken after the
          soundcheck finished when they came to speak to us.
        </Text>
      </Container>
      <PhotoContainer1>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/06newyork2019/2019120343761.jpg"
            largeSrc="/img/06newyork2019/2019120343762.jpg"
            camera="Mercury Lounge, New York - 3rd Dec 2019 - Canon EOS 200D 50mm f1.8 1/250s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/06newyork2019/2019120343851.jpg"
            largeSrc="/img/06newyork2019/2019120343852.jpg"
            camera="Mercury Lounge, New York - 3rd Dec 2019 - Canon EOS 200D 50mm f1.8 1/250s ISO 6400"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
        <PhotoContainer2>
          <Photo
            {...state}
            smallSrc="/img/06newyork2019/2019120343891.jpg"
            largeSrc="/img/06newyork2019/2019120343892.jpg"
            camera="Mercury Lounge, New York - 3rd Dec 2019 - Canon EOS 200D 50mm f1.8 1/60s ISO 12800"
          >
            <Text></Text>
          </Photo>
        </PhotoContainer2>
      </PhotoContainer1>
    </>
  );
};

export default MercuryLounge03;
